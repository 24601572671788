import React from "react";
import spotify from "../../assets/images/home/spotify.webp";
import shopify from "../../assets/images/home/shopify.webp";
import samsung from "../../assets/images/home/samsung.webp";
import techno from "../../assets/images/home/techno.webp";
import deezer from "../../assets/images/home/deezer.webp";
import Marquee from "react-fast-marquee";

const MarqueSec = () => {
  return (
    <>
      <div className="marqueSection">
        <div className="inner">
          <p className="h-center">
            Join the 1,500+ companies using AI to improve their R&D
          </p>

          {/* <div className="logoWrapper">
            <img src={spotify} alt="" />
            <img src={shopify} alt="" />
            <img src={samsung} alt="" />
            <img src={techno} alt="" />
            <img src={deezer} alt="" />
          </div> */}

          <Marquee className="brandsContainer" speed={80}>
            <div className="logoWrapper22">
              <img src={spotify} alt="" />
              <img src={shopify} alt="" />
              <img src={samsung} alt="" />
              <img src={techno} alt="" />
              <img className="me-4" src={deezer} alt="" />
            </div>
          </Marquee>
        </div>
      </div>
    </>
  );
};

export default MarqueSec;
