import React, { useState } from "react";
import Sidebar from "../shared/sidebar";
import Dashnavbar from "../shared/dashnavbar";
import { Outlet } from "react-router-dom";

const Dashboard = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  return (
    <>
      <div className="Dashboard-main-container d-flex overflow-x-hidden">
        <div className="left-content">
          <Sidebar isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
        </div>
        <div className="right_content  md:p-0 w-full md:w-[87%] ">
          <Dashnavbar toggleDrawer={toggleDrawer} />
          <div className="outlet-div">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
