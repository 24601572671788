import React, { useContext, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AppContext } from "./Context/AppContext";
import { toast } from "react-toastify";

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const { userHasPlan } = useContext(AppContext);

  useEffect(() => {
    if (userHasPlan === "plan has been expired") {
      toast.error("Your plan has expired. Please renew your subscription.");
      navigate("/pricing");
    }
  }, [userHasPlan]);

  if (userHasPlan && userHasPlan.plan_status === "pending") {
    return <Navigate to="/pending-payment" />;
  }
  return userHasPlan == undefined ? (
    "Loading..."
  ) : userHasPlan !== undefined &&
    userHasPlan !== null &&
    Object.keys(userHasPlan).length > 0 ? (
    children
  ) : (
    <Navigate to="/pricing" />
  );
};

export default PrivateRoute;
