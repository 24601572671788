import React, { useContext, useState } from "react";
import { Modal } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { AppContext } from "../Context/AppContext";
import { toast } from "react-toastify";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
const CustomvalueModal = ({
  iscustomModalOpen,
  setiscustomModalOpen,
  topicDetails,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [inputValue, setInputValue] = useState("");
  const { personas, setPersonaItems, userHasPlan } = useContext(AppContext);
  const [error, setError] = useState("");
  const [isLoading , setIsLoading] = useState(false);
  const [wantsDiverse, setWantsDiverse] = useState(false);
  const userId = secureLocalStorage.getItem("userId");
  const topic = localStorage.getItem("topic");
  const handleOk = () => {
    setiscustomModalOpen(false);
  };

  const handleCancel = () => {
    setiscustomModalOpen(false);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value === "" || (value.length <= 2 && Number(value) <= 10)) {
      setInputValue(value);
      setError("");
    } else if (Number(value) > 10) {
      setError("You cannot create more than 10 Personas for a Discussion.");
    } else {
      setError("Max limit is 2 digits.");
    }
  };
  const isValidPersonaCount = (count) => {
    switch (userHasPlan.plan_name) {
      case "Text_Base_Intraction_Only":
        return count <= 3;
      case "Free_Trial":
        return count <= 2;
      case "Starter_Individual":
        return count <= 5;
      case "Starter_Enterprise":
      case "Standard_Individual":
      case "Plus_Enterprise":
        return count <= 10;
      default:
        return true;
    }
  };
  const handleContinueClick = async () => {
    if (!inputValue) {
      setError("Please enter a value.");
    } else if (Number(inputValue) < 1) {
      setError("Please select at least 1 persona.");
    } else if (!isValidPersonaCount(Number(inputValue))) {
      toast.error(
        `Your current plan allows up to ${userHasPlan.no_of_agent_allowed} personas.`
      );
    } else {
      try {
        setIsLoading(true)
        const payload = {
          userid: userId,
          topic: topic,
          number_of_persona: Number(inputValue),
          diversity: wantsDiverse,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/persona/continue_after_topic_selecting`,
          payload
        );
        const personasData = response.data.personas;
        setPersonaItems(personasData);
        navigate("/dashboard/edit-persona", {
          state: { personas, topicDetails },
        });
        handleOk();
      } catch (error) {
        setIsLoading(false)
        toast.error("There is an issue generating personas, Please try again");
      }
    }
  };

  return (
    <>
      <Modal
        open={iscustomModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="customvalue-modal"
        centered={true}
        width="38%"
        maskClosable={false}
      >  {isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "20vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loader"></div>
        </div>
      ) : (
        <div className="modal-content v-center h-center flex-column">
          <p className="text-large fw-medium">
            How Many Personas You Want to Add for Discussion?
          </p>
          <div>
            <input
              type="number"
              placeholder="Add Number of Personas for Discussion"
              value={inputValue}
              onChange={handleInputChange}
              className="modal-input"
            />
            {error && <p className="text-small text-red">{error}</p>}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              gap: "16px",
            }}
            className="diverse-checkbox"
          >
            <label htmlFor="diversePersonas" style={{ fontSize: "1rem" }}>
              Do you want diverse personas?
            </label>
            <input
              className=".w-auto"
              type="checkbox"
              id="diversePersonas"
              checked={wantsDiverse}
              onChange={(e) => setWantsDiverse(e.target.checked)}
            />
          </div>
          <button className="dash-pink-btn" disabled={isLoading} onClick={handleContinueClick}>
            Continue
          </button>
        </div> )}
      </Modal>
    </>
  );
};

export default CustomvalueModal;
