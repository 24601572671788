import React from 'react';
import { Navigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

const GuestRoute = ({ children }) => {
  const token = secureLocalStorage.getItem('authToken');

  if (token) {
    return <Navigate to="/dashboard" />;
  }

  return children;
};

export default GuestRoute;
