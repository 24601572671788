import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import ModelHistorycard from "../../components/cards/modelHistorycard";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Models = () => {
  const [expandedStates, setExpandedStates] = useState({});
  const [groupedTopics, setGroupedTopics] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const userId = secureLocalStorage.getItem("userId");
  const navigate = useNavigate();

  useEffect(() => {
    getAllTopics();
  }, []);

  const getAllTopics = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/topic/get_all_topic`, {
        userid: userId,
      })
      .then((res) => {
        setIsLoading(false);
        if (res.data.length === 0) {
          toast.success("You don't have any discussions, try creating one.");
        }
        let topics = res.data.Response;

        topics = topics.map((topic) => {
          const timeParts = topic.time.split(" ");
          if (
            timeParts.length === 3 &&
            timeParts[1] === "PM" &&
            timeParts[0].includes(":")
          ) {
            const [hour, minute, second] = timeParts[0].split(":");
            const hourInt = parseInt(hour);
            if (hourInt >= 12) {
              topic.time = `${("0" + (hourInt - 12)).slice(
                -2
              )}:${minute}:${second} PM ${timeParts[2]}`;
            }
          }
          return topic;
        });
        topics = topics.filter((topic) => {
          const date = new Date(topic.time);
          return !isNaN(date.getTime());
        });

        topics = topics.sort((a, b) => new Date(b.time) - new Date(a.time));

        const grouped = topics.reduce((acc, topic) => {
          const dateObj = new Date(topic.time);
          if (isNaN(dateObj.getTime())) {
            return acc;
          }
          const date = dateObj.toLocaleDateString();
          const period = dateObj
            .toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })
            .split(" ")[1];
          const key = `${date} ${period}`;
          (acc[key] = acc[key] || []).push(topic);
          return acc;
        }, {});

        setGroupedTopics(grouped);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const toggleExpand = (key) => {
    setExpandedStates((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "80vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div class="loader"></div>
        </div>
      ) : (
        <div className="models-main-container d-flex flex-column gap-20">
          <h3 className="fw-semibold">Model History</h3>
          <div className="modal-history-wrapper d-flex flex-column gap-10">
            {Object.keys(groupedTopics).length === 0 && (
              <>
                <div className="d-flex gap-20 text-lightgray text-large fw-normal">
                  No Discussions Found Want To Start New Discussion
                </div>
                <div className="p-4">
                  <button
                    onClick={() => navigate("/dashboard")}
                    className="dash-pink-btn px-2"
                  >
                    Go to Chat
                  </button>
                </div>
              </>
            )}
            {Object.entries(groupedTopics).map(([key, topics]) => (
              <div key={key}>
                <div className="d-flex space-between">
                  <span className="v-center text-lightgray text-large fw-normal">
                    {key}
                  </span>
                  <Icon
                    icon={`icon-park-outline:${
                      expandedStates[key] ? "up" : "down"
                    }`}
                    width={24}
                    height={24}
                    color="#959595"
                    className="cursor-pointer"
                    onClick={() => toggleExpand(key)}
                  />
                </div>
                {!expandedStates[key] && (
                  <div className="history-card-wrapper">
                    {topics.map((topic, index) => (
                      <ModelHistorycard
                        key={topic.topic_id}
                        topic={topic}
                        index={index}
                        getAllTopics={getAllTopics}
                      />
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Models;
