import Feedbackcard from "../../../components/cards/feedbackcard";
import { useNavigate , useLocation } from "react-router-dom";

const Feedback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { personas, sessionId } = location.state || {};
  return (
    <>
      <div className="h-center">
        <div className="feedback-main-container ">
          <h5 className="fw-medium">Discussion Feedback</h5>
          <p className="fw-normal text-small">
            We had a great experience Discussion with you, now kindly give
            review about us, so we can improve ourselves..
          </p>
          <div className="feedback-cards-wrapper d-flex flex-column">
          <Feedbackcard 
              persona={{ persona_name: "Chat Lead" , image: "../../assets/dashboardimages/images/userImg2.png"}} 
              sessionId={sessionId} 
            />
          {personas &&
            personas.map((persona, index) => (
              <Feedbackcard
                key={index}
                persona={persona}
                sessionId={sessionId}
              />
            ))}
            <div className="button-wrapper h-center">
              <button
                className="dash-pink-btn"
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feedback;
