import React, { useEffect } from "react";
import interGlow from "../../assets/images/home/interactGlow2.webp";
//import heroVideo from "../../assets/video/slaraVideoa.mp4";
import heroVideo from "../../assets/video/videoo2.mp4";
import playButton from "../../assets/images/home/icons8-play-button-60.webp";

import AOS from "aos";
import "aos/dist/aos.css";

const Interact = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="interact-main">
      <img className="interGlow" src={interGlow} alt="" />
      <div id="howitwork" className="interactionWrapper">
        <h2 className="fw-semibold text-white h-center">How Slara Works</h2>
        <div className="imWrapper">
          {/* <div className='playButton'> */}
          {/* <Icon icon="iconamoon:arrow-right-2-fill" width="128" height="128"  style={{color: black}} /> */}
          {/* <Iconicon="iconamoon:arrow-right-2-fill"  /> */}
          {/* <img  className='iconPlay' src={playButton} alt="" />
    </div> */}
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/P2IUtnKgqNc?si=GQwzpSD_N6VVibgz"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Interact;
