import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import axios from "axios";

const DeleteModal = ({ isdelModal, setisdelModal, handleDeleteTopic }) => {
  return (
    <>
      <Modal
        open={isdelModal}
        onCancel={() => setisdelModal(false)}
        className="del-modal"
        centered={true}
        width="38%"
        maskClosable={false}
      >
        <div className="modal-content v-center h-center flex-column">
          <h5 className=" fw-bold fw-medium">You sure you want to delete?</h5>
          <div className="d-flex gap-4">
            <button
              onClick={() => {
                setisdelModal(false);
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleDeleteTopic();
                // window.location.reload();
                setisdelModal(false);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteModal;
