import React, { useEffect } from "react";
import Navbar from "../shared/navbar";
import Footer from "../shared/footer";

import SlaraCards from "../components/home/slaraCards";

const MainBlogsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="blog-page-top ">
        {" "}
        <Navbar />
        <div className="banner ">
          <h2 className="">BLOGS</h2>
        </div>
      </div>

      <div className="slarCardSection ">
        <div className="slarCardsWrapper">
          <SlaraCards />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default MainBlogsPage;
