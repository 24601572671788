import React, { useContext } from "react";
import cardImg1 from "../../assets/images/home/featureCard1.webp";
import cardImg2 from "../../assets/images/home/featureCard2.webp";
import cardImg3 from "../../assets/images/home/featureCard3.webp";
import tick from "../../assets/images/home/featureTick.webp";
import { Link as ScrollLink } from "react-scroll";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const FeatureCard = () => {
  const navigate = useNavigate();
  const userId = secureLocalStorage.getItem("userId");
  const profileData = secureLocalStorage.getItem("profileData");
  const { setUserHasPlan, userHasPlan, ProfileData } = useContext(AppContext);
  const handleGetStarted = async (name, price) => {
    try {
      const payload = profileData?.email
        ? {
            userid: userId || "",
            amount: price,
            plan_name: name,
            email: profileData.email,
            base_url: "https://www.slara.ai",
          }
        : {
            userid: "",
            amount: 0.0,
            plan_name: name,
            email: "abc@gmail.com",
            base_url: "",
          };

      // Send request to activate the free trial
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/create-checkout-session`,
        payload
      );

      // Handle the response based on different cases
      if (response.data.save === "save plan") {
        setUserHasPlan("Free_Trial");
        toast.success("Free trial activated!");
        await ProfileData();
        navigate("/dashboard");
      } else if (response.data["Already subscribed"]) {
        toast.error(
          response.data["Already subscribed"] ||
            "You have already used your free trial!"
        );
      } else if (response.data.Response) {
        const { userid, token, plan, guest_id } = response.data.Response;

        // Save necessary data in secure storage
        secureLocalStorage.setItem("authToken", token);
        secureLocalStorage.setItem("userId", userid);
        secureLocalStorage.setItem("guestId", guest_id);
        secureLocalStorage.setItem("usertype", "guest");

        setUserHasPlan(plan);
        toast.success("Free Trial Activated!");
        await ProfileData();
        navigate("/dashboard");
      } else {
        toast.error("You already have a plan");
      }
    } catch (error) {
      console.error("Error activating free trial:", error);
      toast.error("Something went wrong. Please try again later.");
    }
  };

  const featureContent = [
    {
      id: 1,
      title: "Discuss any topic and any document.",
      details:
        "Slara allows you to have a whole range of different discussions with AI personas including.",
      content1: "Discuss uploaded text documents",
      content2: "Discuss uploaded images and designs",
      content3: "Discuss any topic you input",
      featureImg: cardImg1,
    },

    {
      id: 2,
      title: "Create any number of unique personas",
      details:
        "Slara allows you to include any number of custom personas, each one created to your specifications. ",
      content1: "Edit the name, description and image.",
      content2: "Edit how their voice sounds for the chat.",
      content3: "Load previously saved personas.",
      featureImg: cardImg2,
      cardSwapping: "cardSwap",
    },

    {
      id: 3,
      title: "Engage with your personas.",
      details:
        "Chat or talk to your personas and ask the questions that matter to you and your business.",
      content1: "Chat with them using voice or text.",
      content2: "Change the parameters anytime.",
      content3: "Review the stored chats whenever you need to.",
      featureImg: cardImg3,
    },
  ];
  return (
    <>
      {featureContent.map((index) => (
        <div className={`featureCard ${index.cardSwapping}`}>
          <div className="leftArea">
            <h2 className="heading">{index.title}</h2>
            <p>{index.details}</p>
            <ul>
              <li>
                <img src={tick} alt="" />
                <span>{index.content1}</span>
              </li>

              <li>
                <img src={tick} alt="" />
                <span>{index.content2}</span>
              </li>

              <li>
                <img src={tick} alt="" />
                <span>{index.content3}</span>
              </li>
            </ul>
            <button onClick={ ()=> handleGetStarted("Free_Trial" , "00.00")}>
              Start Free Trial
            </button>
          </div>
          <div className="rightArea">
            <img src={index.featureImg} alt="" />
          </div>
        </div>
      ))}
    </>
  );
};

export default FeatureCard;
