import React from "react";
import "../style/shared/footer.scss";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer d-flex justify-content-center align-items-center">
      <div className="part1 w-50 d-flex justify-content-end">
        <p className="text-medium fw-normal text-gray ">
        All Rights Reserved Ⓒ 2024 Slara 
        </p>
      </div>
      <div className="part2 d-flex  justify-content-end align-items-center gap-3 w-50">
        <p className="cursor-pointer" onClick={() => navigate("/privacy-policy")}>Privacy Policy</p>
        <p className="cursor-pointer" onClick={() => navigate("/terms-services")}>Terms and Condition</p>
      </div>
    </div>
  );
};

export default Footer;
