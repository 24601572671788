import React, { useEffect } from "react";
import Navbar from "../shared/navbar";
import Footer from "../shared/footer";
import BlogDetail from "../components/blogs/blogDetail";

const BlogPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="blog-page-top">
        {" "}
        <Navbar />
        <div className="banner">
          <h2 className="">BLOGS</h2>
        </div>
      </div>
      <BlogDetail />
      <Footer />
    </>
  );
};

export default BlogPage;
