import React from "react";
import { useNavigate } from "react-router-dom";

const ConversationHistorycard = () => {
  const navigate = useNavigate();
  return (
    <div className="convo-card d-flex flex-column">
      <span className="text-large fw-bold d-flex gap-2">
        Discussion Title:{" "}
        <span className="fw-normal text-lightgray">Health</span>
      </span>
      <span className="text-large fw-bold d-flex gap-2">
        Discussion Duration:{" "}
        <span className="fw-normal text-lightgray">1 Hour</span>
      </span>
      <div className="card-bottom v-center space-between">
        <div className="bottom-left v-center">
          <img
            src="../../assets/dashboardimages/images/feedbackimg.png"
            alt="checkmark"
          />
          <h6 className="fw-medium">Chat Lead</h6>
        </div>
        <button
          className="dash-pink-btn"
          onClick={() => {
            navigate("/conversations/conversation-discussion");
          }}
        >
          Play
        </button>
      </div>
    </div>
  );
};

export default ConversationHistorycard;
