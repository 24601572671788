import React from "react";
import Discussion from "../DashboardMain/Discussion/discussion";

const ConversationDiscussion = () => {
  return (
    <div>
      <Discussion display="d-flex" hide="d-none"/>
    </div>
  );
};

export default ConversationDiscussion;
