import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import animationData from "../../soundWaves.json";
import animation from "../../assets/Animation.png";

const Personacard = (props) => {
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setShowModal(true);
  };

  const handleMouseLeave = () => {
    setShowModal(false);
  };

  return (
    <div className="persona-card d-flex flex-column">
      <div className="card-top d-flex space-between">
        <img src={props.img} alt=""></img>
        {props.iconcheck === false && (
          <Icon
            icon="mage:edit"
            width={24}
            height={24}
            color="#FFFFFF"
            className={`cursor-pointer ${props.hide}`}
            onClick={() => {
              navigate("/dashboard/edit-persona", {
                state: { editBoolean: true },
              });
            }}
          />
        )}
      </div>
      <div className="d-flex align-items-center gap-4">
        <h6 className="fw-medium">{props.name}</h6>
        {props.isActive ? (
          <Lottie animationData={animationData} style={{ width: 80 }} />
        ) : (
          <img
            src={animation}
            style={{ width: 70, mixBlendMode: "multiply" }}
          />
        )}
      </div>
      <div
        className="card-bottom d-flex flex-column gap-2"
        style={{ position: "relative" }}
      >
        <p
          className="text-small fw-medium text-lightgray discussion-card-p"
          style={{
            width: "75%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {props.description}
        </p>
        {showModal && (
          <div
            style={{
              position: "absolute",
              top: "100%",
              left: "50%",
              transform: "translateX(-50%)",
              background: "rgba(255, 255, 255, 0.05)",
              border: "1px solid rgba(255, 255, 255, 0.1)",
              boxShadow: "0 3px 9px rgba(0, 0, 0, 0.5)",
              zIndex: 1050,
              width: "300px",
              padding: "10px",
              marginTop: "15px",
              borderRadius: "8px",
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <p
              className="text-small fw-medium text-lightgray"
              style={{ margin: 0 }}
            >
              {props.description}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Personacard;
