import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const Auth = ({ children }) => {
  const [loggedin, setLoggedin] = useState(false);
  const navigate = useNavigate();
  const isAuthenticated = () => {
    // Check if a valid token exists in local storage
    const token = secureLocalStorage.getItem("authToken");
    // console.log("token", token);
    // console.log("let's see ", children);
    if (token) {
      // // Decode the token to check its validity
      // const payload = token.split(".")[1];
      // const decodedPayload = JSON.parse(atob(payload));
      // // Check if the token is not expired
      // const expirationTime = decodedPayload.exp;
      // const currentTime = Date.now() / 1000;
      // console.log("Value", currentTime);
      // return currentTime < expirationTime;
      setLoggedin(true);
    } else {
      navigate("/signin");
    }
    return false;
  };
  useEffect(() => {
    isAuthenticated();
  });
  return <>{loggedin ? <div>{children}</div> : <>{"Loading"}</>}</>;
};
export default Auth;
