// import React, { useState } from "react";
// import teestiText from "../../assets/images/home/TestimonialsText.png";

// const TestimonialCard = ({ data, children }) => {
//   const text = children || '';
//   const [isReadMore, setIsReadMore] = useState(true);
//   const toggleReadMore = () => {
//     setIsReadMore(!isReadMore);
//   };
//   return (
//     <>
//       <div className="testimonialCard">
//         <img className="testiText" src={teestiText} alt="" />
//         <p className="text-extraLarge">
//           {" "}
//           {isReadMore ? text.slice(0, 100) : text}{" "}
//           <span
//             onClick={toggleReadMore}
//             className="read-or-hide"
//             style={{ color: "green" }}
//           >
//             {isReadMore ? "...read more" : " show less"}
//           </span>{" "}
//           {data.content1}
//         </p>
//         <p className="text-extraLarge">{data.content2}</p>

//         <p className="text-extraLarge">{data.content3}</p>
//         <p className="text-extraLarge">{data.content4}</p>
//         <h3>{data.name}</h3>
//       </div>
//     </>
//   );
// };

import React, { useState } from "react";
// import teestiText from "../../assets/images/home/TestimonialsText.png";

const TestimonialCard = ({ data, showReadMore }) => {
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const fullText = `${data.content1} ${data.content2}`.trim();
  const previewText = data.content1; // Initial part of the content to display

  return (
    <div className="testimonialCard">
      {/* <img className="testiText" src={teestiText} alt="Testimonial" /> */}
      <p className="text-extraLarge">
        {isReadMore ? previewText : fullText}
        {showReadMore && (
          <button
            onClick={toggleReadMore}
            className="read-or-hide"
          >
            {isReadMore ? "...read more" : " ...show less"}
          </button>
        )}
      </p>
      <h3>{data.name}</h3>
    </div>
  );
};

export default TestimonialCard;
