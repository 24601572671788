import React, { useContext } from "react";
import SlaraCards from "./slaraCards";
import withSlaraGlow from "../../assets/images/home/withSlaraGlow.webp";
import { Link as ScrollLink } from "react-scroll";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";

const WithSlara = () => {
  const navigate = useNavigate();
  const userId = secureLocalStorage.getItem("userId");
  const profileData = secureLocalStorage.getItem("profileData");
  const { setUserHasPlan, userHasPlan, ProfileData } = useContext(AppContext);
  const handleGetStarted = async (name, price) => {
    try {
      const payload = profileData?.email
        ? {
            userid: userId || "",
            amount: price,
            plan_name: name,
            email: profileData.email,
            base_url: "https://www.slara.ai",
          }
        : {
            userid: "",
            amount: 0.0,
            plan_name: name,
            email: "abc@gmail.com",
            base_url: "",
          };

      // Send request to activate the free trial
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/create-checkout-session`,
        payload
      );

      // Handle the response based on different cases
      if (response.data.save === "save plan") {
        setUserHasPlan("Free_Trial");
        toast.success("Free trial activated!");
        await ProfileData();
        navigate("/dashboard");
      } else if (response.data["Already subscribed"]) {
        toast.error(
          response.data["Already subscribed"] ||
            "You have already used your free trial!"
        );
      } else if (response.data.Response) {
        const { userid, token, plan, guest_id } = response.data.Response;

        // Save necessary data in secure storage
        secureLocalStorage.setItem("authToken", token);
        secureLocalStorage.setItem("userId", userid);
        secureLocalStorage.setItem("guestId", guest_id);
        secureLocalStorage.setItem("usertype", "guest");

        setUserHasPlan(plan);
        toast.success("Free Trial Activated!");
        await ProfileData();
        navigate("/dashboard");
      } else {
        toast.error("You already have a plan");
      }
    } catch (error) {
      console.error("Error activating free trial:", error);
      toast.error("Something went wrong. Please try again later.");
    }
  };
  return (
    <div id="blogs" className="slarCardSection ">
      <img className="withSlaraGlow" src={withSlaraGlow} alt="" />

      <button
         onClick={() => handleGetStarted("Free_Trial", "00.00")}
        className="joinWaitlist h-center mt-4"
      >
        Start Free Trial
      </button>
      <h2 className="text-white fw-semibold h-center">With Slara, you can</h2>
      <div className="slarCardsWrapper">
        <SlaraCards homecard />
      </div>
    </div>
  );
};

export default WithSlara;
