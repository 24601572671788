import React from "react";
import Discussion from "../DashboardMain/Discussion/discussion";

const ModelsDiscussion = () => {
  return (
    <div>
      <Discussion />
    </div>
  );
};

export default ModelsDiscussion;
