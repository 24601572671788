import React, { useEffect } from "react";
import Navbar from "../shared/navbar";
import Footer from "../shared/footer";
const TermsServices = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (

    <>
      <Navbar />
      <div className="privacyPolicyContainer">
        <div className="container">
          <h2>Terms of Service</h2>
          <p className="mt-4 pb-2 mx-auto para">
            Please read these Terms of Service ("Terms") carefully before using
            the Slara AI platform ("Service") operated by Slara AI Inc. ("us",
            "we", or "our"). Your access to and use of the Service is
            conditioned upon your acceptance of and compliance with these Terms.
            These Terms apply to all visitors, users, and others who wish to
            access or use the Service.
          </p>
          <p className="mb-5 pb-2 mx-auto para">
            By accessing or using the Service, you agree to be bound by these
            Terms. If you disagree with any part of the Terms, you do not have
            permission to access the Service.
          </p>
          <div className="inner">
            <div className="privacyContent mb-5">
              <ol>
                <h3 className="mb-3 mt-4">1. Accounts </h3>
                <p className="mb-2">
                  When you create an account with us, you guarantee that you are
                  above the age of 18, and that the information you provide is
                  accurate, complete, and current at all times. Inaccurate,
                  incomplete, or obsolete information may result in the
                  immediate termination of your account on the Service
                </p>
                <p>
                  You are responsible for maintaining the confidentiality of
                  your account and password, including but not limited to the
                  restriction of access to your computer and/or account. You
                  agree to accept responsibility for any and all activities or
                  actions that occur under your account and/or password, whether
                  your password is with our Service or a third-party service.
                  You must notify us immediately upon becoming aware of any
                  breach of security or unauthorized use of your account.
                </p>
              </ol>
            </div>

            <div className="privacyContent mb-5">
              <ol>
                <h3 className="mb-3 mt-4">2. Subscriptions </h3>
                <p className="mb-2">
                  Some parts of the Service may be billed on a subscription
                  basis ("Subscription(s)"). You will be billed in advance on a
                  recurring and periodic basis ("Billing Cycle"). Billing cycles
                  are set either on a monthly or annual basis, depending on the
                  type of subscription plan you select when purchasing a
                  Subscription.
                </p>
                <p>
                  At the end of each Billing Cycle, your Subscription will
                  automatically renew under the exact same conditions unless you
                  cancel it or Slara AI Inc. cancels it. You may cancel your
                  Subscription renewal either through your online account
                  management page or by contacting our customer support team.
                </p>
              </ol>
            </div>

            <div className="privacyContent mb-5">
              <ol>
                <h3 className="mb-3 mt-4">3. Fee Changes </h3>
                <p className="mb-2">
                  Slara AI Inc., in its sole discretion and at any time, may
                  modify the Subscription fees. Any Subscription fee change will
                  become effective at the end of the then-current Billing Cycle.
                </p>
                <p>
                  Slara AI Inc. will provide you with reasonable prior notice of
                  any change in Subscription fees to give you an opportunity to
                  terminate your Subscription before such change becomes
                  effective
                </p>
              </ol>
            </div>

            <div className="privacyContent mb-5">
              <ol>
                <h3 className="mb-3 mt-4">4. Refunds </h3>
                <p className="mb-2">
                  Certain refund requests for Subscriptions may be considered by
                  Slara AI Inc. on a case-by-case basis and granted at the sole
                  discretion of Slara AI Inc.
                </p>
              </ol>
            </div>

            <div className="privacyContent mb-5">
              <ol>
                <h3 className="mb-3 mt-4">5. Content </h3>
                <p className="mb-2">
                  Our Service allows you to post, link, store, share and
                  otherwise make available certain information, text, graphics,
                  videos, or other material ("Content"). You are responsible for
                  the Content that you post on or through the Service, including
                  its legality, reliability, and appropriateness.
                </p>
              </ol>
            </div>

            <div className="privacyContent mb-5">
              <ol>
                <h3 className="mb-3 mt-4">6. Prohibited Uses </h3>
                <p className="mb-2">
                You may use the Service only for lawful purposes and in accordance with these Terms. You agree not to use the Service:
                </p>

                <ul>
    <li>In any way that violates any applicable national or international law or regulation.</li>
    <li>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail," "chain letter," "spam," or any other similar solicitation.</li>
    <li>To impersonate or attempt to impersonate Slara AI Inc., a Slara AI Inc. employee, another user, or any other person or entity.</li>
    <li>In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</li>
</ul>
              </ol>
            </div>

            <div className="privacyContent mb-5">
              <ol>
                <h3 className="mb-3 mt-4">7. Intellectual Property </h3>
                <p className="mb-2">
                  The Service and its original content (excluding Content
                  provided by users), features, and functionality are and will
                  remain the exclusive property of Slara AI Inc. and its
                  licensors. The Service is protected by copyright, trademark,
                  and other laws of both the United States and foreign
                  countries. Our trademarks and trade dress may not be used in
                  connection with any product or service without the prior
                  written consent of Slara AI Inc.
                </p>
              </ol>
            </div>

            <div className="privacyContent mb-5">
              <ol>
                <h3 className="mb-3 mt-4">8. Termination </h3>
                <p className="mb-2">
                  We may terminate or suspend your account and bar access to the
                  Service immediately, without prior notice or liability, under
                  our sole discretion, for any reason whatsoever and without
                  limitation, including but not limited to a breach of the
                  Terms. If you wish to terminate your account, you may simply
                  discontinue using the Service.
                </p>
              </ol>
            </div>

            <div className="privacyContent mb-5">
              <ol>
                <h3 className="mb-3 mt-4">9. Limitation of Liability </h3>
                <p className="mb-3">
                  In no event shall Slara AI Inc., nor its directors, employees,
                  partners, agents, suppliers, or affiliates, be liable for any
                  indirect, incidental, special, consequential or punitive
                  damages, including without limitation, loss of profits, data,
                  use, goodwill, or other intangible losses, resulting from your
                  access to or use of or inability to access or use the Service.
                </p>
              </ol>
            </div>

            <div className="privacyContent mb-5">
              <ol>
                <h3 className="mb-3 mt-4">10. Disclaimer </h3>
                <p className="mb-3">
                  Your use of the Service is at your sole risk. The Service is
                  provided on an "AS IS" and "AS AVAILABLE" basis. The Service
                  is provided without warranties of any kind, whether express or
                  implied, including, but not limited to, implied warranties of
                  merchantability, fitness for a particular purpose,
                  non-infringement or course of performance.
                </p>
              </ol>
            </div>

            <div className="privacyContent mb-5">
              <ol>
                <h3 className="mb-3 mt-4">11.  Governing Law </h3>
                <p className="mb-3">
                These Terms shall be governed and construed in accordance with the laws of [Your State/Country], without regard to its conflict of law provisions.
                </p>
              </ol>
            </div>

            <div className="privacyContent mb-5">
              <ol>
                <h3 className="mb-3 mt-4">12. Changes </h3>
                <p className="mb-3">
                We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
                </p>
              </ol>
            </div>

            <div className="privacyContent mb-5">
              <ol>
                <h3 className="mb-3 mt-4">13. Contact Us </h3>
                <p className="mb-3">
                If you have any questions about these Terms, please contact us at [Your Contact Email].
By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service. info@slara.ai

                </p>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsServices;
