import React from 'react'
import FeatureCard from './featureCard';
import glow from "../../assets/images/home/interactGlow2.webp"

const Features = () => {
  return (
   <>
   <div id='features' className="features">
    <h2 className='h-center'>Features</h2>
    <img className='featGlow' src={glow} alt="" />
    <div className="innerSection">
        <FeatureCard/>
    </div>

   </div>
   </>
  )
}

export default Features;