import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Drawer } from "antd";
import { Icon } from "@iconify/react";
import { AppContext } from "../Context/AppContext";

const Sidebar = ({ isDrawerOpen, toggleDrawer }) => {
  const navigate = useNavigate();
  const { logout } = useContext(AppContext);
  return (
    <>
      <div className="sidebar-main-container">
        <div className="sidebar-logo-container h-center">
          <img
            src="../../dashlogo.png"
            alt="logo"
            className="logo cursor-pointer"
            onClick={() => {
              navigate("/dashboard");
            }}
          />
        </div>
        <div className="sidebar-options-container">
          <ul className="d-flex flex-column space-between">
            <div className="top-options">
              <li>
                <NavLink to="/dashboard" className="dashLinks v-center ">
                  <img
                    src="../../assets/dashboardimages/icons/chaticon.svg"
                    alt="option"
                    className="sidebar-img cursor-pointer"
                  />
                  <label className="text-large cursor-pointer">Chat</label>
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/conversations" className="dashLinks  v-center ">
                  <img
                    src="../../assets/dashboardimages/icons/convericon.svg"
                    alt="option"
                    className="sidebar-img cursor-pointer"
                  />
                  <label className="text-large cursor-pointer">
                    Conversations
                  </label>
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  to="/models"
                  className="dashLinks  v-center "
                  activeClassName="active"
                >
                  <img
                    src="../../assets/dashboardimages/icons/modelsicon.png"
                    alt="option"
                    className="sidebar-img cursor-pointer"
                  />
                  <label className="text-large cursor-pointer">Models</label>
                </NavLink>
              </li>
              <li>
                <NavLink to="/plans" className="dashLinks v-center ">
                  <img
                    src="../../assets/dashboardimages/icons/modelsicon.png"
                    alt="option"
                    className="sidebar-img cursor-pointer"
                  />
                  <label className="text-large cursor-pointer">
                    Plans Info
                  </label>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/settings"
                  className="dashLinks  v-center "
                  activeClassName="active"
                >
                  <img
                    src="../../assets/dashboardimages/icons/settingsicon.svg"
                    alt="option"
                    className="sidebar-img cursor-pointer"
                  />
                  <label className="text-large cursor-pointer">Settings</label>
                </NavLink>
              </li>
            </div>
            <div className="bottom-options">
              <NavLink to="/" className="dashLinks d-flex">
                <button
                  onClick={logout}
                  className="fw-semibold h-center v-center gap-2"
                >
                  {" "}
                  <Icon icon="material-symbols:logout" width={24} height={24} />
                  Log Out
                </button>
              </NavLink>
            </div>
          </ul>
        </div>
      </div>
      <div className="mobile-sidebar d-none">
        <Drawer
          placement="left"
          closable={false}
          onClose={toggleDrawer}
          open={isDrawerOpen}
          className="mobile-menu-drawer"
        >
          <div className="sidebar-container">
            <div className="sidebar-logo-container h-center">
              <img
                src="../../dashlogo.png"
                alt="logo"
                className="logo cursor-pointer"
                onClick={() => {
                  navigate("/dashboard");
                  toggleDrawer();
                }}
              />
              <img
                src="../../assets/dashboardimages/icons/backArrow.png"
                alt="logo"
                className="backarrow-Logo"
                onClick={toggleDrawer}
              />
            </div>
            <div className="sidebar-options-container">
              <ul className="d-flex flex-column space-between">
                <div className="top-options">
                  <li onClick={toggleDrawer}>
                    <NavLink to="/dashboard" className="dashLinks  v-center ">
                      <img
                        src="../../assets/dashboardimages/icons/chaticon.svg"
                        alt="option"
                        className="sidebar-img cursor-pointer"
                      />
                      <label className="text-large cursor-pointer">Chat</label>
                    </NavLink>
                  </li>
                  {/* <li onClick={toggleDrawer}>
                    <NavLink
                      to="/conversations"
                      className="dashLinks  v-center "
                    >
                      <img
                        src="../../assets/dashboardimages/icons/convericon.svg"
                        alt="option"
                        className="sidebar-img cursor-pointer"
                      />
                      <label className="text-large cursor-pointer">
                        Conversations
                      </label>
                    </NavLink>
                  </li> */}
                  <li onClick={toggleDrawer}>
                    <NavLink
                      to="/models"
                      className="dashLinks  v-center "
                      activeClassName="active"
                    >
                      <img
                        src="../../assets/dashboardimages/icons/modelsicon.png"
                        alt="option"
                        className="sidebar-img cursor-pointer"
                      />
                      <label className="text-large cursor-pointer">
                        Models
                      </label>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/plans" className="dashLinks v-center ">
                      <img
                        src="../../assets/dashboardimages/icons/modelsicon.png"
                        alt="option"
                        className="sidebar-img cursor-pointer"
                      />
                      <label className="text-large cursor-pointer">
                        Plans Info
                      </label>
                    </NavLink>
                  </li>
                  <li onClick={toggleDrawer}>
                    <NavLink
                      to="/settings"
                      className="dashLinks  v-center "
                      activeClassName="active"
                    >
                      <img
                        src="../../assets/dashboardimages/icons/settingsicon.svg"
                        alt="option"
                        className="sidebar-img cursor-pointer"
                      />
                      <label className="text-large cursor-pointer">
                        Settings
                      </label>
                    </NavLink>
                  </li>
                </div>
                <div className="bottom-options">
                  <NavLink to="/" className="dashLinks d-flex">
                    <button className="fw-semibold h-center v-center gap-2">
                      {" "}
                      <Icon
                        icon="material-symbols:logout"
                        width={20}
                        height={20}
                      />
                      Log Out
                    </button>
                  </NavLink>
                </div>
              </ul>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
};

export default Sidebar;
