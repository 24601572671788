import React, { useState } from "react";
import { Modal } from "antd";
import DropdownInputModal from "./dropdownInputModal";

const ProgressComModal = ({
  isprogressCModalopen,
  setisprogressCModalopen,
  title
}) => {
  const [isdropdownInputOpen, setisdropdownInputOpen] = useState(false);
  const handleOk = () => {
    setisprogressCModalopen(false);
  };
  const handleCancel = () => {
    setisprogressCModalopen(false);
  };
  const OpenDropmodal = () => {
    setisdropdownInputOpen(true);
    setisprogressCModalopen(false);
  };
  return (
    <>
      <Modal
        maskClosable={false}
        open={isprogressCModalopen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="progress-modal"
        centered={true}
        width="38%"
      >
        <div className="modal-content v-center h-center flex-column">
          <img
            src="../../assets/dashboardimages/images/tickMark.png"
            alt="checkmark"
          />
          <h5 className="fw-normal Arial-family ">
            Model Trained Successfully{" "}
          </h5>
          <button className="dash-pink-btn " onClick={OpenDropmodal}>
            Continue
          </button>
        </div>
      </Modal>
      <DropdownInputModal
        isdropdownInputOpen={isdropdownInputOpen}
        setisdropdownInputOpen={setisdropdownInputOpen}
        topicDetails={title}
      />
    </>
  );
};

export default ProgressComModal;
