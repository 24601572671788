import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Editpersonacard from "../../../components/cards/editpersonacard";
import DropdownInputModal from "../../../modals/dropdownInputModal";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { AppContext } from "../../../Context/AppContext";

const EditPersona = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { personas, setFetchedPersonas, setPersonas } = useContext(AppContext);
  const { topicDetails, editBoolean } = location.state || {};
  const [isdropdownInputOpen, setisdropdownInputOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [isChildLoading, setIsChildLoading] = useState(false)
  const userId = secureLocalStorage.getItem("userId");

  // useEffect(() => {
  //   console.log("personas", personas);
  // }, [personas]);

  useEffect(() => {
    handleGetAllPersonas();
  }, [userId]);

  const handleGetAllPersonas = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/persona/get_all_persona`, {
        userid: userId,
      })
      .then((res) => {
        // console.log(res.data);
        if(res.data.length > 0){
        const personas = res.data.map((persona) => ({
          name: persona.name,
          description: persona.persona_description,
          image: persona.image || "",
        }));
        setFetchedPersonas(personas);
      }})
      .catch((error) => {
        console.error("Error fetching personas:", error);
        toast.error("Issue Fetching existing personas");
      });
  };

  const onGobackClick = () => {
    navigate(-1, { state: { backPersona: true } });
  };

  const handleContinue = () => {
    const nonEmptyPersonaNames = personas.filter(
      (persona) => persona.name && persona.name.trim() !== ""
    );
    if (nonEmptyPersonaNames.length === 0) {
      toast.error("Please add at least one persona before continuing");
      return;
    }
    const personasName = personas.map((persona) => persona.name);
    // console.log(personasName, nonEmptyPersonaNames);
    setIsLoading(true)
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/persona/continue_after_saving_persona`, {
        userid: userId,
        personas: personasName,
      })
      .then((response) => {
        setIsLoading(false)
        if (response.data && response.data.length > 0) {
          toast.success("Personas continued successfully");
          setPersonas(nonEmptyPersonaNames);
          navigate("/dashboard/persona-created", {
            state: { continuedPersonas: response.data },
          });
        } else {
          toast.warn("No personas found.");
        }
      })
      .catch((error) => {
        console.error("Error continuing personas:", error);
        setIsLoading(false)
        toast.error("Issue continuing personas");
      });
  };

  return (
    <>
      <div className="h-center">
        <div className="edidpersona-main-container d-flex flex-column fit-content">
          <div className="editpersona-top-div d-flex overflow-y">
            {personas?.map((persona, index) => (
              <Editpersonacard
                topicDetail={topicDetails}
                id={index}
                personaId={persona.id}
                editBoolean={editBoolean}
                setIsChildLoading={setIsChildLoading}
              />
            ))}
          </div>
          <div className="editpersona-bottom-div d-flex gap-20 mb-3">
            <button className="trasnparent-btn" onClick={onGobackClick}>
              Go Back
            </button>
            <button className="dash-pink-btn" disabled={isLoading || isChildLoading} onClick={handleContinue}>
              Continue
            </button>
          </div>
        </div>
      </div>
      <DropdownInputModal
        isdropdownInputOpen={isdropdownInputOpen}
        setisdropdownInputOpen={setisdropdownInputOpen}
      />
    </>
  );
};

export default EditPersona;
