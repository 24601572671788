import React, { useState } from "react";
import { Icon } from "@iconify/react";
import ConversationHistorycard from "../../components/cards/conversationHistorycard";
import { useNavigate } from "react-router-dom";

const Conversations = () => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const customClass = "custom-dashboard-class";
  return (
    <>
      <div className="conv-main-container d-flex flex-column gap-20">
        <div className="con-top d-flex space-between">
          <h3 className="fw-semibold">Conversation History</h3>
          <button
            className="dash-pink-btn v-center h-center gap-10 "
            onClick={() => {
              navigate("/dashboard", {
                state: { fromConversations: true, customClass },
              });
            }}
          >
            <Icon
              icon="mingcute:add-fill"
              width={16}
              height={16}
              color="#FFFFFF"
            />
            Start New Discussion
          </button>
        </div>
        <div className="cov-cards d-flex flex-column gap-10">
          <div className="convo-history-wrapper d-flex flex-column gap-10 ">
            <div className="d-flex space-between">
              <span className="v-center  text-lightgray text-large fw-normal">
                2024/05/13 <p></p> <span>12:00pm</span>
              </span>
              <Icon
                icon={`icon-park-outline:${isExpanded ? "up" : "down"}`}
                width={24}
                height={24}
                color="#959595"
                className="cursor-pointer"
                onClick={toggleExpand}
              />
            </div>

            <div className="convo-card-wrapper ">
              <ConversationHistorycard />
              <ConversationHistorycard />
              <ConversationHistorycard />
              {isExpanded && (
                <>
                  <ConversationHistorycard />
                  <ConversationHistorycard />
                  <ConversationHistorycard />
                </>
              )}
            </div>
          </div>
          <div className="convo-history-wrapper d-flex flex-column gap-10 ">
            <span className="v-center  text-lightgray text-large fw-normal">
              2024/05/13 <p></p> <span>12:00pm</span>
            </span>
            <div className="convo-card-wrapper ">
              <ConversationHistorycard />
              <ConversationHistorycard />
              <ConversationHistorycard />
            </div>
          </div>
          <div className="convo-history-wrapper d-flex flex-column gap-10 ">
            <span className="v-center  text-lightgray text-large fw-normal">
              2024/05/13 <p></p> <span>12:00pm</span>
            </span>
            <div className="convo-card-wrapper ">
              <ConversationHistorycard />
              <ConversationHistorycard />
              <ConversationHistorycard />
            </div>
          </div>
          <div className="convo-history-wrapper d-flex flex-column gap-10 ">
            <span className="v-center  text-lightgray text-large fw-normal">
              2024/05/13 <p></p> <span>12:00pm</span>
            </span>
            <div className="convo-card-wrapper ">
              <ConversationHistorycard />
              <ConversationHistorycard />
              <ConversationHistorycard />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Conversations;
