import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { toast } from "react-toastify";
import { AppContext } from "../Context/AppContext";

const UpgradePlan = ({
  isUpgradeModal,
  setIsUpgradeModal,
  upgradePlan,
  name,
  price,
}) => {
  const navigate = useNavigate();
  const userId = secureLocalStorage.getItem("userId");
  const { setUserHasPlan } = useContext(AppContext);
  const handleOk = () => {
    upgradePlan(name, price);
  };
  const handleCancel = () => {
    setIsUpgradeModal(false);
  };

  return (
    <>
      <Modal
        open={isUpgradeModal}
        onOk={handleOk}
        onCancel={handleCancel}
        className="del-modal"
        centered={true}
        width="38%"
        maskClosable={false}
      >
        <div className="modal-content v-center h-center flex-column">
          <h5 className=" text-center fw-medium">
            Are you sure you want to upgrade your plan? if you proceed Your current plan will
            be canceled.
          </h5>
          <div className="d-flex gap-4">
            <button
              onClick={() => {
                handleOk();
              }}
            >
              Yes
            </button>
            <button
              onClick={() => {
                handleCancel();
              }}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UpgradePlan;
