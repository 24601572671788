import React, { useContext, useState } from "react";
import logo from "../assets/images/signIn/logoS.png";
import { Icon } from "@iconify/react";
import googleBtn from "../assets/images/signIn/google.png";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { AppContext } from "../Context/AppContext";

const SignIn = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { ProfileData , setTime} = useContext(AppContext);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const togglePasswordVisibility = () => {
    setFormData({ ...formData, showPassword: !formData.showPassword });
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = formData;

    if (!email && !password) {
      toast.error("Please fill the required fields");
      return;
    } else if (!email) {
      toast.error("Invalid email address");
      return;
    }

    if (!password) {
      toast.error("Password is required");
      return;
    }
    setTime({ minute: 0, second: 0 });        
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
        email: formData.email,
        password: formData.password,
      })
      .then((res) => {
        secureLocalStorage.setItem("usertype" , "registered")
        secureLocalStorage.setItem("authToken", res?.data?.token);
        secureLocalStorage.setItem("userId", res?.data?.userid);
        ProfileData();
        if (res?.data?.plan){
        navigate("/dashboard");
        toast.success("Logged in Successfully");
      }else{
        navigate("/pricing")
        toast.success("Welcome! Please choose a subscription plan to continue.");
      }
        setFormData({
          name: "",
          password: "",
        });
      })
      .catch((err) => {
 
        toast.error(err?.response?.data?.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  return (
    <>
      <div className="signInwrapper">
        <div className="nav">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <NavLink to="/signup">
            <button>SIGN UP</button>
          </NavLink>
        </div>
        <div className="centerDiv">
          <h5>Sign In</h5>
          <div className="userData">
            <form onSubmit={handleSubmit}>
              <div className="emailInput">
                <label className="mb-2" htmlFor="email">
                  EMAIL
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter Your Email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="passwordInput">
                <label className="mb-2" htmlFor="password">
                  ENTER PASSWORD
                </label>
                <div className="pswd-div ">
                  <input
                    type={formData.showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Enter Password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <Icon
                    className="iconEye"
                    onClick={togglePasswordVisibility}
                    icon={
                      formData.showPassword
                        ? "fa-solid:eye"
                        : "fa6-solid:eye-slash"
                    }
                  />
                </div>
              </div>

              <button className="signInn" type="submit" disabled={loading}>
                {loading ? "Signing In..." : "Sign In"}
              </button>
              <div className="forgot d-flex justify-content-end w-100">
                <NavLink to="/forgetpassword">
                  <button>Forgot Password?</button>
                </NavLink>
              </div>
              <span className="or h-center my-2 text-black">OR</span>

              <div className="googleBtn">
                <button type="button">
                  <img src={googleBtn} alt="" />
                  <span>Continue with Google</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;

// import React, { useState } from "react";
// import logo from "../assets/images/signIn/logoS.png";
// import { Icon } from "@iconify/react";
// import googleBtn from "../assets/images/signIn/google.png";
// import { NavLink, useNavigate } from "react-router-dom";
// import { toast, ToastContainer } from "react-toastify";
// import axios from "axios";
// import secureLocalStorage from "react-secure-storage";

// const SignIn = () => {
//   const navigate = useNavigate();
//   const [showPassword, setShowPassword] = useState(true);
//   const [loading, setLoading] = useState(false);
//   const [lastError, setLastError] = useState("");

//   function tooglepasswordvisibility() {
//     setShowPassword(!showPassword);
//   }

//   const [formData, setFormData] = useState({
//     email: "",
//     password: "",
//     showPassword: false,
//   });

//   const validateEmail = (email) => {
//     const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
//     return re.test(String(email).toLowerCase());
//   };

//   const validatePassword = (password) => {
//     const re = /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;
//     return re.test(password);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     setLoading(true);
//     const { email, password } = formData;

//     if (!email && !password) {
//       showError("Please fill the required fields");
//       setLoading(false);
//       return;
//     } else if (!validateEmail(email)) {
//       showError("Invalid email address");
//       setLoading(false);
//       return;
//     }

//     if (!password) {
//       showError("Password is required");
//       setLoading(false);
//       return;
//     } else if (!validatePassword(password)) {
//       showError(
//         "Password must be at least 8 characters long and contain a mix of letters, numbers, and symbols."
//       );
//       setLoading(false);
//       return;
//     }

//     axios
//       .post(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
//         email: formData.email,
//         password: formData.password,
//       })
//       .then((res) => {
//         secureLocalStorage.setItem("authToken", res?.data?.token);
//         secureLocalStorage.setItem("userId", res?.data?.userid);
//         navigate("/dashboard");
//         toast.success("Logged in Successfully");

//         setFormData({
//           email: "",
//           password: "",
//         });
//         setLastError("");
//       })
//       .catch((err) => {
//         showError(err?.response?.data?.detail);
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   };

//   const showError = (message) => {
//     if (message !== lastError) {
//       toast.error(message);
//       setLastError(message);
//     }
//   };

//   const handleGoogleSignIn = (e) => {
//     e.preventDefault();
//     // Your logic for Google Sign-In
//   };

//   return (
//     <>
//       <ToastContainer />
//       <div className="signInwrapper">
//         <div className="nav">
//           <div className="logo">
//             <img src={logo} alt="" />
//           </div>
//           <NavLink to="/signup">
//             <button>SIGN UP</button>
//           </NavLink>
//         </div>
//         <div className="centerDiv">
//           <h5>Sign In</h5>
//           <div className="userData">
//             <form onSubmit={handleSubmit}>
//               <div className="emailInput">
//                 <label className="mb-2" htmlFor="email">
//                   EMAIL
//                 </label>
//                 <input
//                   type="email"
//                   name="email"
//                   placeholder="Enter Your Email"
//                   value={formData.email}
//                   onChange={handleChange}
//                 />
//               </div>
//               <div className="passwordInput">
//                 <label className="mb-2" htmlFor="password">
//                   ENTER PASSWORD
//                 </label>
//                 <div className="pswd-div">
//                   <input
//                     type={showPassword ? "text" : "password"}
//                     name="password"
//                     placeholder="Enter Your Password"
//                     value={formData.password}
//                     onChange={handleChange}
//                   />
//                   <Icon
//                     className="iconEye"
//                     onClick={tooglepasswordvisibility}
//                     icon={
//                       showPassword
//                         ? "fa-solid:eye"
//                         : "fa6-solid:eye-slash"
//                     }
//                   />
//                 </div>
//               </div>

//               <button className="signInn" type="submit" disabled={loading}>
//                 {loading ? "Signing In..." : "Sign In"}
//               </button>
//               <div className="forgot d-flex justify-content-end w-100">
//                 <NavLink to="/forgetpassword">
//                   <button>Forgot Password?</button>
//                 </NavLink>
//               </div>
//               <span className="or h-center my-2 text-black">OR</span>

//               <div className="googleBtn">
//                 <button>
//                   <img src={googleBtn} alt="" />
//                   <span>Continue with Google</span>
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default SignIn;
