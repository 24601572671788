import React, { useRef } from "react";
import Slider from "react-slick";
import GuideCard from "./guideCard";
import arrowLeft from "../../assets/images/home/leftAr.png";
import glow from "../../assets/images/home/interactGlow2.webp"

const Guide = () => {

  const guiderRef = useRef(null);
  const handleNext = () => {
    guiderRef.current.slickNext();
  };
  const handlePrev = () => {
    guiderRef.current.slickPrev();
  };
  var settings = {
    dots: false,
    infinite: true,
   speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
   //centerPadding: "70px",
    autoplay: true,
  autoplaySpeed: 2000,
   // centerMode: true,
  
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
         // centerPadding: "70px",
        },
      },
      {
        breakpoint: 787,
        settings: {
          slidesToShow: 1,
        },
      },

      {
        breakpoint: 1090,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1330,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: 1,
        },
      },

      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 1,
        },
      }
    ],
  };
  return (
    <>
      <div className="guideWrapper">

        <h2 className="h-center">Latest Guides & Stories</h2>
        <img className="guideGlow" src={glow} alt="" />

        <div className="guidenStoriesContainer">
          <Slider {...settings}  ref={ guiderRef}>
            {guideData.map((data, index) => (
              <GuideCard key={index} {...data} />
            ))}
          </Slider>
        </div>


       
          <button className="prev-btn" onClick={handlePrev}>
            <img src={arrowLeft} alt="leftarrow" />
          </button>
          <button className="next-btn" onClick={handleNext}>
            <img src={arrowLeft} alt="leftarrow" />
          </button>
       
      </div>
    </>
  );
};

export default Guide;

const guideData = [
  {
    id: 1,
    mainTitle: "Creating the perfect persona",
    details:
      "Here we will go through a detailed guide on how to plan, research and create the perfect persona for anything you might need to research.",
  },

  {
    id: 2,
    mainTitle: "Designing the perfect persona",
    details:
    "Here we will go through a detailed guide on how to plan, research and create the perfect persona for anything you might need to research.",
  },
];
