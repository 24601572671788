import React, { useContext, useState, useEffect } from "react";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import CustomvalueModal from "./customvalueModal";
import { AppContext } from "../Context/AppContext";
import { toast } from "react-toastify";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const DropdownInputModal = ({
  isdropdownInputOpen,
  setisdropdownInputOpen,
  topicDetails,
}) => {
  // console.log(topicDetails);
  const navigate = useNavigate();
  const [iscustomModalOpen, setiscustomModalOpen] = useState(false);
  const { personas, setPersonaItems, userHasPlan } = useContext(AppContext);
  const [active, setActive] = useState(null);
  const [wantsDiverse, setWantsDiverse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userId = secureLocalStorage.getItem("userId");
  const handleOk = () => {
    setisdropdownInputOpen(false);
  };

  const handlechangepersonas = (value) => {
    if (!isValidPersonaCount(value)) {
      toast.error("Upgrade your plan to select more personas!");
      return;
    }
    const generateUniqueId = () => {
      return `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
    };

    const newPersonas = Array.from({ length: value }, () => ({
      id: generateUniqueId(),
    }));

    setPersonaItems(newPersonas);
    setActive(value);
  };

  const isValidPersonaCount = (count) => {
    switch (userHasPlan.plan_name) {
      case "Text_Base_Intraction_Only":
        return count <= 3;
      case "Free_Trial":
        return count <= 2;
      case "Starter_Individual":
        return count <= 5;
      case "Starter_Enterprise":
      case "Standard_Individual":
      case "Plus_Enterprise":
        return count <= 10;
      default:
        return true;
    }
  };
  const handleCancel = () => {
    setisdropdownInputOpen(false);
  };
  const onCustomClick = () => {
    setiscustomModalOpen(true);
    setisdropdownInputOpen(false);
  };
  const handleSelectChange = async () => {
    if (active === null) {
      toast.error("Please select the number of personas before continuing.");
      return;
    }
    const payload = {
      userid: userId,
      topic: topicDetails,
      number_of_persona: active,
      diversity: wantsDiverse,
    };

    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/persona/continue_after_topic_selecting`,
        payload
      );
      const personasData = response.data.personas;

      setPersonaItems(personasData);
      navigate("/dashboard/edit-persona", {
        state: { personas: personasData, topicDetails },
      });

      setisdropdownInputOpen(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching personas:", error);
      toast.error("There is an issue generating personas, Please try again.");
    }
  };

  return (
    <>
      <Modal
        maskClosable={false}
        open={isdropdownInputOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="dropdownInput-modal"
        centered={true}
        width="38%"
      >
        {" "}
        {isLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "20vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="loader"></div>
          </div>
        ) : (
          <div className="modal-content d-flex  flex-column ">
            <p className="fw-medium text-large text-center">
              How Many Persona’s You Want to Add for Discussion?
            </p>
            <div className="v-center h-center flex-column gap-2">
              <button className="custom-button" onClick={onCustomClick}>
                Custom
              </button>
              {[1, 2, 4, 6].map((value) => (
                <button
                  key={value}
                  className={`custom-button ${
                    active === value ? "selected" : ""
                  }`}
                  onClick={() => handlechangepersonas(value)}
                >
                  {value}
                </button>
              ))}
              <div
                className="checkbox-diverse"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <label
                  htmlFor="diversePersonas"
                  style={{ flex: 1, marginRight: "10px", fontSize: "1rem" }}
                >
                  Do you want diverse personas?
                </label>
                <input
                  type="checkbox"
                  id="diversePersonas"
                  checked={wantsDiverse}
                  onChange={(e) => setWantsDiverse(e.target.checked)}
                />
              </div>
              {personas !== null && (
                <button
                  className="dash-pink-btn mt-2"
                  onClick={handleSelectChange}
                  disabled={isLoading}
                >
                  Continue
                </button>
              )}
              {/* <Space wrap>
              <Select
                placeholder="Persona’s You Want to Add"
                style={{ width: "295px" }}
                options={[
                  // { value: "Custom", label: "Custom" },
                  { value: "1", label: "1" },
                  { value: "2", label: "2" },
                  { value: "4", label: "4" },
                  { value: "6", label: "6" },
                ]}
                onChange={handleSelectChange}
              />
            </Space> */}
            </div>
          </div>
        )}
      </Modal>
      <CustomvalueModal
        iscustomModalOpen={iscustomModalOpen}
        setiscustomModalOpen={setiscustomModalOpen}
        topicDetails={topicDetails}
      />
    </>
  );
};

export default DropdownInputModal;
