import React, { useContext, useEffect } from "react";
import Typewriter from "typewriter-effect";
import topGlow from "../../assets/images/home/trGlow.webp";
import leftGlow from "../../assets/images/home/leftGlow.webp";
// import grid2 from "../../assets/images/home/g.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link as ScrollLink } from "react-scroll";
import { Carousel } from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Interact from "./interact";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";
const contentStyle = {
  height: "60px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

const Hero = () => {
  const navigate = useNavigate();
  const userId = secureLocalStorage.getItem("userId");
  const profileData = secureLocalStorage.getItem("profileData");
  const { setUserHasPlan, userHasPlan, ProfileData } =
    useContext(AppContext);
    const handleGetStarted = async (name, price) => {
      try {
        const payload = profileData?.email
          ? {
              userid: userId || "",
              amount: price,
              plan_name: name,
              email: profileData.email,
              base_url: "https://www.slara.ai",
            }
          : {
              userid: "",
              amount: 0.0,
              plan_name: name,
              email: "abc@gmail.com",
              base_url: "",
            };
  
        // Send request to activate the free trial
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/create-checkout-session`,
          payload
        );
  
        // Handle the response based on different cases
        if (response.data.save === "save plan") {
          setUserHasPlan("Free_Trial");
          toast.success("Free trial activated!");
          await ProfileData();
          navigate("/dashboard");
        } else if (response.data["Already subscribed"]) {
          toast.error(
            response.data["Already subscribed"] ||
              "You have already used your free trial!"
          );
        } else if (response.data.Response) {
          const { userid, token, plan, guest_id } = response.data.Response;
  
          // Save necessary data in secure storage
          secureLocalStorage.setItem("authToken", token);
          secureLocalStorage.setItem("userId", userid);
          secureLocalStorage.setItem("guestId", guest_id);
          secureLocalStorage.setItem("usertype", "guest");
  
          setUserHasPlan(plan);
          toast.success("Free Trial Activated!");
          await ProfileData();
          navigate("/dashboard");
        } else {
          toast.error("You already have a plan");
        }
      } catch (error) {
        console.error("Error activating free trial:", error);
        toast.error("Something went wrong. Please try again later.");
      }
    };
  const strings = [
    "Audience Research",
    "Group Discussion",
    "Brainstorming",
    "Content Creation",
    "Teams",
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {/* <div id="hero" className="heroSection">
        <img className="topGlow" src={topGlow} alt="" />
        <img className="leftGlow" src={leftGlow} alt="" />
        <img className="grid" src={grid} alt="" />
    
        <div className="midBox">
          <h1 className="fw-semibold d-flex align-items-center">
            Welcome to&nbsp;
            <span style={{ paddingBottom: "-20px" }}>
              <Typewriter
                options={{
                  strings: [" Slara!"],
                  autoStart: true,
                  loop: true,
                }}
              />
            </span>
          </h1>
          <div className="human-aiDiv">
            <div className="leftBox">
              <h5>Human + AI Conversation</h5>
            </div>
            <div
       
              className="rightBox"
            >
              <Icon
                className="iconRight"
                icon="bi:arrow-right"
                width="100"
                height="100"
                style={{ color: "white" }}
              />
              <div className="text-box-wrapper">
                <div className="textBox text-cream">
                  Imagine This: You're curious about a complex topic or diving
                  into a new book. Slara fosters in-depth exploration through
                  engaging discussions with a virtual teams specifically
                  tailored to your interests.
                </div>
              </div>
            </div>
          </div>
          <p className="fw-normal text-cream  text-extraLarge">
            At Slara, we harness the power of llms and machine learning
            algorithms to revolutionize the way you learn. Our platform creates
            virtual discussion groups providing you with the opportunity to
            engage in meaningful conversations with AI-generated members with
            diverse viewpoints and expertise.
          </p>
          <p className="fw-normal text-cream text-extraLarge mt-1">
            Explore different perspectives, share insights, and gain valuable
            knowledge as you interact with our AI-generated group members. With
            Slara, you can unlock your full potential, enhance your
            understanding of complex topics, and thrive in a collaborative and
            interactive learning environment.
          </p>
          <a className="mt-8" href="#interact">
            <div className="scroll">
              <img src={scroll} alt="" />
            </div>
          </a>

    
        </div>
      </div> */}

      <div id="hero" className="heroSection">
        <img className="topGlow" src={topGlow} alt="" />
        <img className="leftGlow" src={leftGlow} alt="" />
        {/* <img className="grid" src={grid2} alt="" /> */}
        <div className="midBox">
          <div className="leftArea">
            <h2>
              AI Powered <br />
              {/* <Typewriter
                options={{
                  strings: [
                    "Audience Research",
                    "Group Discussion",
                    "Brainstorming",
                    "Content Creation",
                    "Teams",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              /> */}
              <div
                className="sliderDivv"
                style={{ width: "600px", height: "60px" }}
              >
                <Slider {...settings}>
                  {strings.map((string, index) => (
                    <div style={{ width: "100px", height: "80px" }} key={index}>
                      <h2>{string}</h2>
                    </div>
                  ))}
                </Slider>
              </div>
              {/* <Carousel autoplay>
              <div>
                <h2>Audience Research</h2>
              </div>
              <div>
                <h2>Group Discussion</h2>
              </div>
              <div>
                <h2>Brainstorming</h2>
              </div>
              <div>
                <h2>Content Creation</h2>
              </div>
              <div>
                <h2>Teams</h2>
              </div>
            </Carousel> */}
            </h2>
            <p>
              Slara enables conversations with multiple AI personas
              simultaneously offering diverse perspectives and insights on a
              wide range of topics. Simulates real-life conversational
              scenarios, helping you with anything from brainstorming to
              audience analysis.{" "}
            </p>
            <div className="btnDiv d-flex gap-3">
              <button
                onClick={() => handleGetStarted("Free_Trial", "00.00")}
                className="joinList"
              >
                Start Free Trial
              </button>
              <button className="watchDemo">
                <ScrollLink
                  to="howitwork"
                  spy={true}
                  smooth={true}
                  duration={400}
                  offset={-200}
                >
                  WATCH THE DEMO{" "}
                </ScrollLink>
              </button>
            </div>
          </div>
          <div className="rightArea w-50 ">
            {/* <iframe
          className="rightVideo"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/P2IUtnKgqNc?si=GQwzpSD_N6VVibgz"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
