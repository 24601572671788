// import React, { useRef } from "react";
// import TestimonialCard from "./testimonialCard";
// //import "slick-carousel/slick/slick.css";
// //import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
// import testiGlow from "../../assets/images/home/testiGlow.webp";
// import arrowLeft from "../../assets/images/home/leftAr.png";

// const Testimonial = () => {
//   const testiRef = useRef(null);
//   const handleNext = () => {
//     testiRef.current.slickNext();
//   };
//   const handlePrev = () => {
//     testiRef.current.slickPrev();
//   };
//   var settings = {
//     dots: false,
//     infinite: false,
//     speed: 1000,
//     slidesToShow: 1.3,
//     slidesToScroll: 1,
//     //centerPadding: "0px",
//     autoplay: true,
//     autoplaySpeed: 2000,
//     responsive: [
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//       {
//         breakpoint: 787,
//         settings: {
//           slidesToShow: 1,
//         },
//       },

//       {
//         breakpoint: 1090,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//       {
//         breakpoint: 1330,
//         settings: {
//           slidesToShow: 1.4,
//         },
//       },
//       {
//         breakpoint: 1550,
//         settings: {
//           slidesToShow: 1.3,
//         },
//       },
//       // {
//       //   breakpoint: 1550,
//       //   settings: {
//       //     slidesToShow: 1,
//       //   },
//       // },
//       // {
//       //   breakpoint: 1600,
//       //   settings: {
//       //     slidesToShow: 1,
//       //   },
//       // },
//     ],
//   };

//   return (
//     <div id="testimonial" className="testimonial">
//       <img className="testiGlow" src={testiGlow} alt="" />

//       <h2 className="fw-semibold text-white h-center">Testimonials</h2>
//       <div className="cardsContainer ">
//         <Slider {...settings} ref={testiRef}>
//           {sliderData.map((data, index) => (
//            <TestimonialCard key={index} data={data}>
//            {data.content1 + data.content2 + data.content3 + data.content4}
//          </TestimonialCard>
//           ))}
//         </Slider>
//       </div>

//       <div className=" gap-3 mt-8 arrow-slider-btns">
//         <button className="testi-prev-btn" onClick={handlePrev}>
//           <img src={arrowLeft} alt="leftarrow" />
//         </button>
//         <button className="testi-next-btn" onClick={handleNext}>
//           <img src={arrowLeft} alt="leftarrow" />
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Testimonial;

// const sliderData = [
//   {
//     id: 1,
//     content1:
//       "Slara has been a game-changer for my learning! I used to struggle finding good discussion groups – everyone seemed to be on the same page, or the discussions were just plain boring. Slara fixes that completely.",
//     content2:
//       "The first time I used it, I wanted to delve deeper into a philosophy text I was reading. Slara created a virtual group with members who had completely different interpretations – it was amazing! One member focused on the historical context, another on the author's biography, and a third offered a totally new perspective that blew my mind.",
//     content3:
//       "The discussions on Slara are always lively and engaging. The AI members are incredibly well-informed and articulate, and they can keep up with your arguments in real-time. Plus, they're constantly learning and evolving, so every discussion feels fresh and insightful.",
//     content4:
//       "Honestly, Slara has made learning so much more enjoyable. It's like having a team of personal tutors at your fingertips, ready to discuss anything you're interested in. If you're looking to expand your knowledge and challenge your thinking, Slara is the perfect platform.",
//     name: "- Sarah H., lifelong learner",
//   },

//   {
//     id: 2,
//     content1:
//       "As a user of Slara, I must say I'm thoroughly impressed with the platform. The personalized learning experience and dynamic discussions have truly enhanced my understanding of various topics. The AI-generated team members are incredibly insightful and provide valuable perspectives that I wouldn't have considered otherwise. Plus, the real-time interaction feature makes the discussions feel engaging and interactive. Slara has become my go-to platform for expanding my knowledge and engaging in meaningful conversations. I highly recommend it to anyone looking to dive deep into their areas of interest and connect with like-minded individuals.",
//     name: "-Jared M. Student",
//   },

//   {
//     id: 3,
//     content1:
//       "As a high school history teacher, I was constantly looking for ways to make my lessons more engaging and interactive. Traditional lectures just weren't cutting it anymore. Then I discovered Slara, and it's been a revelation! Slara allows me to create topic-specific groups for my students, fostering discussions that go beyond the textbook. For example, when we studied the Civil War, Slara generated a group with members representing different historical viewpoints – a Union soldier, a Confederate plantation owner, and even an abolitionist. The students were absolutely enthralled! They actively participated in debates, asking insightful questions and forming their own well-reasoned arguments",
//     name: "Crystal K. Teacher",
//   },
//   {
//     id: 4,
//     content1:
//       "As a student preparing for my college entrance exams, I found myself struggling to grasp certain concepts in physics. I decided to give Slara a try after hearing about its AI-powered discussion groups. I joined a discussion on quantum mechanics and was blown away by the depth of insights shared by the AI-generated team members. Not only did they provide clear explanations of complex topics, but they also engaged in meaningful debates that helped solidify my understanding. Thanks to Slara, I not only aced my physics exam but also developed a newfound appreciation for the subject. I'm grateful for the role Slara played in my academic success.",
//     name: "Paul L. Student",
//   },
// ];

import React, { useRef } from "react";
import TestimonialCard from "../../components/home/testimonialCard";
import Slider from "react-slick";
import arrowLeft from "../../assets/images/home/leftAr.png";

const Testimonial = () => {
  const testiRef = useRef(null);
  const handleNext = () => {
    testiRef.current.slickNext();
  };
  const handlePrev = () => {
    testiRef.current.slickPrev();
  };
  const settings = {
    dots: false,
     infinite: true,
    speed: 1000,
    slidesToShow: 1.7,
    slidesToScroll: 1,
     autoplay: true,
    centerMode: true,
    centerPadding: "0px",
    autoplaySpeed: 2000,
    responsive: [
      { breakpoint: 600, settings: { slidesToShow: 1 }, centerMode: false ,    centerPadding: "0px",},
      { breakpoint: 787, settings: { slidesToShow: 1 } },
      { breakpoint: 1090, settings: { slidesToShow: 1 } },
      { breakpoint: 1330, settings: { slidesToShow: 1 } },
      { breakpoint: 1550, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <div id="testimonial" className="testimonial">
      {/* <img className="testiGlow" src={testiGlow} alt="" /> */}
      <h2 className="fw-semibold text-white h-center">Testimonials</h2>
      <div className="cardsContainer">
        <Slider {...settings} ref={testiRef}>
          {sliderData.map((data, index) => (
            <TestimonialCard
              key={index}
              data={data}
              showReadMore={index === 0}
            />
          ))}
        </Slider>
      </div>
      <div className="gap-3 mt-8 arrow-slider-btns">
        <button className="testi-prev-btn" onClick={handlePrev}>
          <img src={arrowLeft} alt="leftarrow" />
        </button>
        <button className="testi-next-btn" onClick={handleNext}>
          <img src={arrowLeft} alt="leftarrow" />
        </button>
      </div>
    </div>
  );
};

export default Testimonial;

const sliderData = [
  {
    id: 1,
    content1:
      "Slara has been a game-changer for my learning! I used to struggle finding good discussion groups – everyone seemed to be on the same page, or the discussions were just plain boring. Slara fixes that completely.  The first time I used it, I wanted to delve deeper into a philosophy text I was reading. Slara created a virtual group with members who had completely different interpretations – it was amazing! One member focused on the historical context, another on the author's biography, and a third offered a totally new perspective that blew my mind.",
    content2:
      "The discussions on Slara are always lively and engaging. The AI members are incredibly well-informed and articulate, and they can keep up with your arguments in real-time. Plus, they're constantly learning and evolving, so every discussion feels fresh and insightful.",
    content3:
      "Honestly, Slara has made learning so much more enjoyable. It's like having a team of personal tutors at your fingertips, ready to discuss anything you're interested in. If you're looking to expand your knowledge and challenge your thinking, Slara is the perfect platform.",

    name: "- Sarah H., lifelong learner",
  },
  {
    id: 2,
    content1:
      "As a user of Slara, I must say I'm thoroughly impressed with the platform. The personalized learning experience and dynamic discussions have truly enhanced my understanding of various topics. The AI-generated team members are incredibly insightful and provide valuable perspectives that I wouldn't have considered otherwise. Plus, the real-time interaction feature makes the discussions feel engaging and interactive. Slara has become my go-to platform for expanding my knowledge and engaging in meaningful conversations. I highly recommend it to anyone looking to dive deep into their areas of interest and connect with like-minded individuals.",
    name: "- Jared M. Student",
  },
  {
    id: 3,
    content1:
      "As a high school history teacher, I was constantly looking for ways to make my lessons more engaging and interactive. Traditional lectures just weren't cutting it anymore. Then I discovered Slara, and it's been a revelation! Slara allows me to create topic-specific groups for my students, fostering discussions that go beyond the textbook. For example, when we studied the Civil War, Slara generated a group with members representing different historical viewpoints – a Union soldier, a Confederate plantation owner, and even an abolitionist. The students were absolutely enthralled! They actively participated in debates, asking insightful questions and forming their own well-reasoned arguments",
    name: "Crystal K. Teacher",
  },
  {
    id: 4,
    content1:
      "As a student preparing for my college entrance exams, I found myself struggling to grasp certain concepts in physics. I decided to give Slara a try after hearing about its AI-powered discussion groups. I joined a discussion on quantum mechanics and was blown away by the depth of insights shared by the AI-generated team members. Not only did they provide clear explanations of complex topics, but they also engaged in meaningful debates that helped solidify my understanding. Thanks to Slara, I not only aced my physics exam but also developed a newfound appreciation for the subject. I'm grateful for the role Slara played in my academic success.",
    name: "Paul L. Student",
  },
];
