import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";

const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = secureLocalStorage.getItem('userId')
  const [formState, setFormState] = useState({
    verificationCode: "",
    errors: {},
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value.length <= 6) {
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    if (!formState.verificationCode) {
      errors.verificationCode = "Verification code is required";
    } 
    // else if (!/^\d{6}$/.test(formState.verificationCode)) {
    //   errors.verificationCode = "Verification code must be a 6-digit number";
    // }

    if (Object.keys(errors).length > 0) {
      setFormState({
        ...formState,
        errors,
      });
    } else {
      axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/verify_user_email_to_update`, {
        email: location?.state?.formState?.email,
        code: formState.verificationCode,
        userid : userId
      })
      .then((res) => {
        toast.success(res.data || "Email updated successfully")
        
        navigate("/settings");
      })
      .catch((err) => {
        toast.error(err || "Issue updating email , Try again")
      });
      
    }
  };
  const handleResendCode = () => {
    setFormState({
      ...formState,
      verificationCode: "",
      errors: {},
    });
  };

  return (
    <>
      <div className="settings-main-conatainer h-center">
        <Icon
          icon="mingcute:left-line"
          width={24}
          height={24}
          color="#FFFFFF"
          className="cursor-pointer back-icon"
          onClick={() => {
            navigate("/settings/update-Email");
          }}
        />
        <div className="right-container v-center flex-column w-100">
          <h4 className="fw-bold">Verify Email</h4>

          <form className="d-flex flex-column" onSubmit={handleSubmit}>
            <div className="d-flex flex-column gap-2">
              <p className="text-small fw-normal">Verification Code</p>
              <input
                type="number"
                name="verificationCode"
                placeholder="Enter the verification code"
                value={formState.verificationCode}
                onChange={handleInputChange}
                className="modal-input"
              />
              {formState.errors.verificationCode && (
                <p className="text-small text-red">
                  {formState.errors.verificationCode}
                </p>
              )}
            </div>
            <div className="w-100 d-flex flex-column gap-1">
              <button type="submit" className="dash-pink-btn fw-bold">
                Verify
              </button>
              <p className="d-flex space-between text-small text-lightgray fw-medium">
                Verification code sent, kindly check your email{" "}
                <span
                  className="text-purple cursor-pointer"
                  onClick={() => {
                    handleResendCode();
                  }}
                >
                  Resend Code?
                </span>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Settings;
