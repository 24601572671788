import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import Loader from "../components/loader";
import ProgressComModal from "./ProgressCompletedModal";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";

const ProgressModal = ({
  isprogressModalopen,
  setisprogressModalopen,
  Data,
}) => {
  const [isprogressCModalopen, setisprogressCModalopen] = useState(false);
  const title = localStorage.getItem("topic");
  const userId = secureLocalStorage.getItem("userId");
  const handleOk = () => {
    setisprogressModalopen(false);
  };
  const handleCancel = () => {
    setisprogressModalopen(false);
  };

  useEffect(() => {
    if (isprogressModalopen) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/kb/train`, {
          userid: userId,
          sessionid: Data.sessionid,
          topic: title,
          files: Data.files,
        })
        .then(async (firstResponse) => {
          const { untrained, trained } = firstResponse.data;
          if (untrained && untrained.length > 0) {
            const failedFiles = untrained
              .map((item) => item.File_name.split(/[/\\]/).pop())
              .join(", ");
            toast.error(
              `Model training failed for the following files: ${failedFiles}`
            );
          }
          const sessionPath = (() => {
            if (Data.files.length > 0) {
              const firstFile = Data.files[0];
              const parts = firstFile.split(/[/\\]/);
              parts.pop();
              return parts.join("/");
            }
            return null;
          })();

          try {
            return await axios.post(
              `${process.env.REACT_APP_BASE_URL}/kb/remove_file`,
              {
                folder_name: sessionPath,
              }
            );
          } catch (error) {
            console.error("Error in second API (remove_file):", error);
          }
        })
        .then((secondResponse) => {
          setisprogressModalopen(false);
          setisprogressCModalopen(true);
        })
        .catch((error) => {
          setisprogressModalopen(false);
          toast.error("Error in training model. Please try again.");
          // console.error("Error during the API calls:", error);
        });
    }
  }, [isprogressModalopen]);
  return (
    <>
      <Modal
        maskClosable={false}
        open={isprogressModalopen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="progress-modal"
        centered={true}
        width="38%"
      >
        <div className="modal-content v-center h-center flex-column">
          <Loader />
          <h5 className="fw-normal Arial-family ">Model Training in Process</h5>
        </div>
      </Modal>
      <ProgressComModal
        isprogressCModalopen={isprogressCModalopen}
        setisprogressCModalopen={setisprogressCModalopen}
        title={title}
      />
    </>
  );
};

export default ProgressModal;
