import React, { useRef, useEffect, useState, useContext } from "react";
import Personacard from "../../../components/cards/personacard";
import { Icon } from "@iconify/react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import CallEndModal from "../../../modals/callendModal";
import axios from "axios";
import { toast } from "react-toastify";
import { AppContext } from "../../../Context/AppContext";
import secureLocalStorage from "react-secure-storage";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import animationData from "../../../spinner.json";
import Lottie from "lottie-react";
import FreeTrialEnded from "../../../modals/freeTrialended";

const Discussion = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sessionId } = useParams();
  const { continuedPersonas } = location.state || [];

  const [isendModal, setisendModal] = useState(false);
  const [isGuestModal, setisGuestModal] = useState(false);
  const [isSpeakerMuted, setIsSpeakerMuted] = useState(false);
  const [chatInput, setChatInput] = useState("");
  const [tempMessage, setTempMessage] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [activeSpeaker, setActiveSpeaker] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const [recognizer, setRecognizer] = useState(null);
  const [streamText, setStreamText] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    check,
    setCheck,
    profileData,
    userHasPlan,
    setReminderCount,
    time,
    setTime,
    formattedTime,
    setFormattedTime,
    setTest,
    reminderCount,
    chatType,
    setUserHasPlan,
  } = useContext(AppContext);

  const audioRef = useRef(null);
  const fileInputRef = useRef(null);
  const connection = useRef(null);
  const isSpeakerMutedRef = useRef(false);
  const chatContainerRef = useRef(null);
  const tempMessageRef = useRef([]);
  const streamTextRef = useRef(streamText);

  let variable = "";
  let audioQueue = [];
  let meetingEndTime;
  let warningTime;

  const hasTextBasedPlan =
    userHasPlan.plan_name === "Text_Base_Intraction_Only";
  const userId = secureLocalStorage.getItem("userId");
  const topic = localStorage.getItem("topic");

  const formatTimeTotal = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const remainingTimeInSeconds = userHasPlan.time_total - userHasPlan.time_used;
  const formattedRemainingTime = formatTimeTotal(remainingTimeInSeconds);
  useEffect(() => {
    if (formattedTime === formattedRemainingTime) {
      setTest(true);
      toast.error("You have used all your time. Please renew your plan.")
      if (profileData?.username === "Guest") {
        secureLocalStorage.setItem("time" , formattedTime)
        setTime({ minute: 0, second: 0 });        
        setisGuestModal(true);
      } else {
        navigate("/dashboard/feedback", {
          state: { personas: continuedPersonas, sessionId },
        });
      }
    }
  }, [formattedTime, formattedRemainingTime]);
  useEffect(() => {
    setFormattedTime(
      `${String(time.minute).padStart(2, "0")}:${String(time.second).padStart(
        2,
        "0"
      )}`
    );
  }, [time]);
 
  useEffect(() => {
    if (reminderCount > 3) {
      navigate("/dashboard/feedback", {
        state: { personas: continuedPersonas, sessionId },
      });
    }
  }, [reminderCount])
  
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory, tempMessage, streamText]);

  useEffect(() => {
    const timer = setTimeout(() => {
      // console.log("10 seconds has passed but no response");
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/conversation/meeting_reminder`,
          {
            userid: userId,
            sessionid: sessionId,
            plan: userHasPlan.plan_name,
          }
        )
        .then((res) => {
          // console.log(res.data);
          // setReminderCount((prevCount) => prevCount + 1);
        })
        .catch((err) => {
          console.error("Error sending message:", err);
        });
    }, 90000);
    return () => clearTimeout(timer);
  }, [chatHistory]);

  useEffect(() => {
    if (!hasTextBasedPlan) {
      toast.info(
        "Kindly unmute your mic and speaker to participate in the meeting."
      );
    }
    setCheck(true);

    const interval = setInterval(() => {
      setTime((prevTime) => {
        const { minute, second } = prevTime;
        if (second === 59) {
          return { minute: minute + 1, second: 0 };
        } else {
          return { ...prevTime, second: second + 1 };
        }
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  function startListening() {
    const speechConfig = sdk.SpeechConfig.fromSubscription(
      process.env.REACT_APP_MICROSOFT_AZURE,
      "eastus"
    );
    speechConfig.speechRecognitionLanguage = "en-US";
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
        const newRecognizer = new sdk.SpeechRecognizer(
          speechConfig,
          audioConfig
        );

        newRecognizer.recognizing = (s, e) => {
          // console.log(`RECOGNIZING: Text=${e.result.text}`);
          audioRef.current.muted = true;
          audioQueue = [];
        };

        newRecognizer.recognized = (s, e) => {
          if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
            // console.log(`RECOGNIZED: Text=${e.result.text}`);
            const newMessage = {
              sender: "You",
              text: e.result.text,
              time: new Date().toLocaleTimeString(),
            };
            const currentStreamText = streamTextRef.current;
            setChatHistory((prev) => [...prev, ...currentStreamText]);
            streamTextRef.current = [];
            setChatHistory((prev) => [...prev, newMessage]);

            const sendMessage = {
              query: e.result.text,
              plan_name: userHasPlan.plan_name,
              voice: null,
              chat_type: chatType,
            };
            const jsonString = JSON.stringify(sendMessage);
            connection.current.send(jsonString);
            // console.log("sent Message", sendMessage);
          }
        };

        newRecognizer.canceled = (s, e) => {
          // console.log(`CANCELED: Reason=${e.reason}`);
          if (e.reason === sdk.CancellationReason.Error) {
            console.log(`CANCELED: ErrorDetails=${e.errorDetails}`);
          }
          stopListening();
        };

        newRecognizer.sessionStopped = (s, e) => {
          // console.log("\nSession stopped event.");
          stopListening();
        };
        newRecognizer.startContinuousRecognitionAsync();
        setRecognizer(newRecognizer);
        setIsListening(true);
      })
      .catch((error) => {
        console.log("Error accessing media devices:", error);
      });
  }

  const stopListening = () => {
    if (recognizer) {
      recognizer.stopContinuousRecognitionAsync(() => {
        recognizer.close();
        setRecognizer(null);
        setIsListening(false);
      });
    }
  };

  useEffect(() => {
    return () => {
      if (recognizer) {
        stopListening();
      }
    };
  }, []);

  useEffect(() => {
    if (isSpeakerMuted) {
      setTimeout(mutePage, 0);
    } else {
      unmutePage();
    }
  }, [isSpeakerMuted]);

  const mutePage = () => {
    if (audioRef.current) {
      audioRef.current.muted = true;
    }
  };

  const unmutePage = () => {
    if (audioRef.current) {
      audioRef.current.muted = false;
    }
  };

  if (userHasPlan.plan_name === "Free_Trial") {
    meetingEndTime = "15:00";
    warningTime = "14:00";
  } else {
    meetingEndTime = "30:00";
    warningTime = "29:00";
  }

  if (formattedTime === meetingEndTime) {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/conversation/meeting_end`, {
        userid: userId,
        sessionid: sessionId,
        plan: userHasPlan.plan_name,
      })
      .then((response) => {
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      });
  }

  if (formattedTime === warningTime) {
    toast.warn("Discussion Time is about to End...");
  }

  if (formattedTime === meetingEndTime) {
    setTest(true);
    navigate("/dashboard/feedback", {
      state: { personas: continuedPersonas, sessionId },
    });
  }

  useEffect(() => {
    const socket = new WebSocket(
      `wss://slara.onrender.com/ws/${userId}/${sessionId}/${userHasPlan.plan_name}`
    );

    let isPlaying = false;
    let currentResponseId;

    const playAudioQueue = () => {
      if (audioQueue.length > 0 && !isPlaying) {
        isPlaying = true;
        const { audioUrl, responseId } = audioQueue.shift();

        if (responseId !== currentResponseId) {
          isPlaying = false;
          playAudioQueue();
          return;
        }
        const audio = new Audio(audioUrl);
        audioRef.current = audio;

        if (isSpeakerMutedRef.current) {
          audioRef.current.muted = true;
          audio.muted = true;
        }
        audio.onended = () => {
          isPlaying = false;
          if (audioQueue.length === 0) {
            setActiveSpeaker(null);
          }
          playAudioQueue();
        };

        audio.play().catch((e) => {
          console.log("Error playing audio", e);
          isPlaying = false;
          if (audioQueue.length === 0) {
            setActiveSpeaker(null);
          }
          playAudioQueue();
        });
      }
    };

    socket.addEventListener("open", (event) => {
      console.log("Socket Connected");
    });

    socket.addEventListener("message", async (event) => {
      // console.log("socket data from server", event.data);

      if (event.data instanceof Blob) {
        try {
          // console.log("Received Blob:", event.data);

          const audioUrl = URL.createObjectURL(event.data);
          // download(audioUrl)
          audioQueue.push({ audioUrl, responseId: currentResponseId });

          if (!isPlaying) {
            playAudioQueue();
          }
        } catch (error) {
          console.error("Error handling Blob data:", error);
        }
      } else {
        let data;
        try {
          data = JSON.parse(event.data);
          if (data.event === "meeting_reminder") {
            // console.log("Meeting Reminder");
            setReminderCount((prevReminderCount) => prevReminderCount + 1);
            // if (reminderCount > 3) {
            //   navigate("/dashboard/feedback", {
            //     state: { personas: continuedPersonas, sessionId },
            //   });
            // }
          } else if (data.event === "token_used") {
            toast.error("You have used all your time. Please renew your plan.");
            navigate("/dashboard/feedback", {
              state: { personas: continuedPersonas, sessionId },
            });
          } else if (data.event === "meeting_reminder_streaming_end") {
            // console.log("Meeting Reminder Streaming End");
          } else {
            // console.log("Other event then Meeting reminder");
            setReminderCount(0);
          }
          if (data.event === "task_cancel") {
            audioQueue = [];
            variable = "";
            return;
          }
          if (data.response_id) {
            // console.log("Current Response ID:", currentResponseId);
            currentResponseId = data.response_id;
          }
          if (data.respond_by) {
            setActiveSpeaker(data.respond_by);
          }
          if (data.text) {
            const responseText = data.text;
            variable += responseText;
          }
          const getImageUrl = () => {
            switch (data.respond_by) {
              case "Chat Lead":
                return "../../assets/dashboardimages/images/userImg2.png";
          
              default:
                const persona = continuedPersonas.find(
                  (persona) => persona.persona_name === data.respond_by
                );
          
                if (persona) {
                  return persona.image === defaultStaticImg
                    ? "../" + defaultStaticImg 
                    : persona.image;           
                }
                return "../../assets/dashboardimages/images/animoji.png";
            }
          };
          const botResponse = {
            sender: data.respond_by,
            text: variable,
            time: new Date().toLocaleTimeString(),
            image: getImageUrl()
          };
          // console.log("Marked Text", botResponse.text);
          setStreamText((prevStreamText) => {
            const updatedStreamText = [botResponse];
            // console.log(updatedStreamText);
            streamTextRef.current = updatedStreamText;
            if (!data.text) {
              if(hasTextBasedPlan){
                setActiveSpeaker(null);
              }
              // setActiveSpeaker(null);
              const botText = updatedStreamText.map((text) => text);
              setChatHistory((chatHistoryPrev) => [
                ...chatHistoryPrev,
                ...botText,
              ]);
              variable = "";
              return [];
            }

            return updatedStreamText;
          });
          // console.log(tempMessage);
          setChatHistory((prev) => [...prev, ...tempMessageRef.current]);
          setTempMessage([]);
          if (data.voice) {
            // console.log("Received voice data:", data.voice);
            try {
              const base64Audio = data.voice;
              const binaryString = atob(base64Audio);
              const len = binaryString.length;
              const bytes = new Uint8Array(len);
              for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
              }
              const blob = new Blob([bytes], { type: "audio/wav" });
              const audioUrl = URL.createObjectURL(blob);

              audioQueue.push({ audioUrl, responseId: currentResponseId });
              playAudioQueue();
            } catch (error) {
              console.error("Error handling audio chunk:", error);
            }
          }
        } catch (error) {
          console.error("Error parsing message data:", error);
        }
      }
    });

    connection.current = socket;

    return () => {
      audioQueue = [];
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        audioRef.current = null;
      }
      socket.close();
      setTest(true);
    };
  }, []);
  const uploadFile = async (uploadedFile) => {
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("files", uploadedFile);
    setIsLoading(true);
    try {
      const uploadResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/kb/real_time_upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { documents, images } = uploadResponse.data;
      const files = [...documents, ...images];

      await axios.post(`${process.env.REACT_APP_BASE_URL}/kb/real_time_train`, {
        userid: userId,
        sessionid: sessionId,
        files: files,
        topic_details : topic
      });
      setIsLoading(false);
      return true;
    } catch (error) {
      console.error("Error in file upload or training:", error);
      toast.error(
        error?.response?.data?.Error ||
          "Error occurred during file upload or processing."
      );
      setIsLoading(false);
      return false;
    }
  };

  const sendTextMessage = (chatInput) => {
    const sendMessage = {
      query: chatInput,
      plan_name: userHasPlan.plan_name,
      voice: null,
      chat_type: chatType,
    };
    const jsonString = JSON.stringify(sendMessage);
    connection.current.send(jsonString);
  };

  const createMessageObject = (text, file) => {
    const newMessage = {
      sender: "You",
      time: new Date().toLocaleTimeString(),
      text: text || null,
      file: file
        ? {
            name: file.name,
            type: file.type,
            url: file.type.startsWith("image/")
              ? URL.createObjectURL(file)
              : null,
          }
        : null,
    };
    return newMessage;
  };

  const handleSendMessage = async () => {
    if (isLoading) return;

    if (chatInput.trim() === "" && !uploadedFile) return;

    const newMessage = createMessageObject(chatInput, uploadedFile);
    setIsLoading(true);

    try {
      if (chatInput.trim() !== "" && uploadedFile) {
        const uploadSuccess = await uploadFile(uploadedFile);
        if (uploadSuccess) {
          sendTextMessage(chatInput);
          setChatHistory((prevHistory) => [...prevHistory, newMessage]);
          setChatInput("");
          setUploadedFile(null);
        }
      } else if (chatInput.trim() !== "") {
        sendTextMessage(chatInput);
        setChatHistory((prevHistory) => [...prevHistory, newMessage]);
        setChatInput("");
      } else if (uploadedFile) {
        const uploadSuccess = await uploadFile(uploadedFile);
        if (uploadSuccess) {
          setChatHistory((prevHistory) => [...prevHistory, newMessage]);
          setUploadedFile(null);
        }
      }
      setStreamText([]);
      const historyTemp = tempMessage.map((message) => message);
      setChatHistory((prev) => [...prev, ...historyTemp]);
      setTempMessage([]);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };
  const onCallEndClick = () => {
    setisendModal(true);
  };
  const handleFileChange = (event) => {
    if(userHasPlan?.plan_name === "Free_Trial"){
      toast.error("Upgrade your plan to upload !");
      return;
    }
    const file = event.target.files[0];
    const remainingUploads =
      userHasPlan.no_of_document_allowed - userHasPlan.no_of_document_uploaded;
    if (file?.length > remainingUploads) {
      toast.error(
        `Your ${userHasPlan.no_of_document_allowed} documents limit is over, kindly upgrade for more`
      );
      return;
    }
    if (file) {
      setUploadedFile(file);
    }
  };
  const handleCancelUpload = () => {
    setUploadedFile(null);
  };
  const renderFilePreview = () => {
    if (!uploadedFile) return null;

    return (
      <div
        className="uploaded-file-preview v-center gap-2"
        style={{ marginBottom: "10px" }}
      >
        {isLoading ? (
          <Lottie animationData={animationData} style={{ width: 80 }} />
        ) : uploadedFile.type.startsWith("image/") ? (
          <div className="preview-image v-center">
            <img
              src={URL.createObjectURL(uploadedFile)}
              alt={uploadedFile.name}
              style={{
                maxWidth: "80px",
                maxHeight: "80px",
                objectFit: "contain",
              }}
            />
          </div>
        ) : uploadedFile.type === "application/pdf" ? (
          <div className="preview-pdf v-center gap-2">
            <Icon icon="mdi:file-pdf" width={24} height={24} />
            <span style={{ fontSize: "12px" }}>{uploadedFile.name}</span>
          </div>
        ) : (
          <div className="preview-default v-center gap-2">
            <span style={{ fontSize: "12px" }}>{uploadedFile.name}</span>
          </div>
        )}
        {!isLoading && (
          <Icon
            icon="mdi:close-circle"
            width={16}
            height={16}
            className="cursor-pointer"
            onClick={handleCancelUpload}
          />
        )}
      </div>
    );
  };

  const defaultStaticImg = "../assets/dashboardimages/images/userImg1.png";
  return (
    <>
      <div className="h-center">
        <div className="discussion-main-container v-center flex-column ">
          <div className="dis-top v-center space-between w-100">
            <h5 className={`fw-medium ${props.hide} `}>Discussion Started</h5>
            <div className={`v-center gap-1 d-none ${props.display} `}>
              <Icon
                icon="ph:record-fill"
                width={24}
                height={24}
                color="#F5382F
              "
              />
              <h5 className="fw-medium">Recorded Discussion</h5>
            </div>
            <p className="text-large fw-normal">
              {formattedTime}/{meetingEndTime}
            </p>
          </div>
          <div className="dic-inner-main d-flex space-between ">
            <div className="disc-left d-flex flex-column gap-40">
              <div className="disc-card-top v-center gap-5">
                <Personacard
                  name="Chat Lead"
                  img="../../assets/dashboardimages/images/userImg2.png"
                  isActive={activeSpeaker === "Chat Lead"}
                  iconcheck={check}
                  description="GOAT"
                />
                {continuedPersonas?.map((persona, index) => (
                  <Personacard
                    key={index}
                    name={persona.persona_name}
                    description={persona.description}
                    isActive={activeSpeaker === persona.persona_name}
                    img={
                      persona.image === defaultStaticImg
                        ? "../" + persona.image
                        : persona.image
                    }
                    iconcheck={check}
                  />
                ))}
              </div>

              <img
                src="../../assets/dashboardimages/images/separator.png"
                alt=""
                className="part-img"
              ></img>
              <div className="user-card">
                <div className="user-card-top v-center gap-3">
                  {profileData?.picture && (
                    <img
                      src={profileData?.picture}
                      alt="profile"
                      style={{
                        height: "60px",
                        width: "60px",
                        borderRadius: 100,
                      }}
                    />
                  )}

                  <h6 className="fw-medium">You</h6>
                </div>
                <div className="user-card-bottom">
                  <span className=" d-flex gap-1 text-small fw-semibold text-lightwhite">
                    Discussion Topic:
                    <span className="fw-medium text-lightgray ">{topic}</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="disc-right d-flex flex-column space-between">
              <div className="transcript-div w-100 v-center h-center">
                <p className="text-medium fw-semibold">Transcript</p>
              </div>
              <div
                className="chat-div d-flex flex-column"
                ref={chatContainerRef}
              >
                {chatHistory
                  ?.reduce((unique, message) => {
                    if (
                      !unique.some(
                        (msg) =>
                          msg.text === message.text && msg.time === message.time
                      )
                    ) {
                      unique.push(message);
                    }
                    return unique;
                  }, [])
                  .map((message, index) => {
                    if (!message?.sender) {
                      return null;
                    }

                    const isSenderYou = message?.sender === "You";

                    return (
                      <div
                        key={index}
                        className={isSenderYou ? "right-div" : "left-div"}
                      >
                        <div className="top d-flex gap-1">
                          {isSenderYou ? (
                            <>
                              <div className="text-medium fw-normal">
                                {/* If both text and file exist, render them together */}
                                {message?.file && (
                                  <>
                                    {message.file.type.startsWith("image/") ? (
                                      <img
                                        src={message.file.url}
                                        alt="uploaded"
                                        style={{
                                          width: "180px",
                                          height: "120px",
                                          marginLeft: "10px",
                                          borderRadius: "10px",
                                        }}
                                      />
                                    ) : (
                                      <a
                                        href={message.file.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          display: "block",
                                          marginTop: "10px",
                                          textDecoration: "underline",
                                          color: "#0000FF",
                                        }}
                                      >
                                        {message.file.name}
                                      </a>
                                    )}
                                  </>
                                )}
                                {message?.text && (
                                  <div
                                    className="px-3 py-1 "
                                    dangerouslySetInnerHTML={{
                                      __html: message?.text,
                                    }}
                                  />
                                )}
                              </div>
                              <img
                                style={{ borderRadius: 100 }}
                                src={
                                  profileData?.picture ||
                                  "../../assets/dashboardimages/images/animoji.png"
                                }
                                alt=""
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={
                                  message.image ||
                                  "../../assets/dashboardimages/images/animoji.png"
                                }
                                alt=""
                                style={{ borderRadius: 100 }}
                              />
                              <div className="text-medium fw-normal">
                                {message?.file && (
                                  <>
                                    {message.file.type.startsWith("image/") ? (
                                      <img
                                        src={message.file.url}
                                        alt="uploaded"
                                        style={{
                                          maxWidth: "200px",
                                          borderRadius: "10px",
                                        }}
                                      />
                                    ) : (
                                      <a
                                        href={message.file.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          display: "block",
                                          marginTop: "10px",
                                          textDecoration: "underline",
                                          color: "#ffffff",
                                        }}
                                      >
                                        <Icon
                                          icon="mdi:file-pdf"
                                          width={14}
                                          height={14}
                                        />
                                        {message.file.name}
                                      </a>
                                    )}
                                  </>
                                )}
                                {message?.text && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: message?.text,
                                    }}
                                  />
                                )}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="bottom d-flex">
                          <span className="d-flex gap-2 fw-normal">
                            {message?.sender}{" "}
                            <span className="fw-normal">{message?.time}</span>
                          </span>
                        </div>
                      </div>
                    );
                  })}

                {streamText?.map((message, index) => {
                  if (!message?.sender) {
                    return null;
                  }

                  const isSenderYou = message?.sender === "You";

                  return (
                    <div
                      key={index}
                      className={isSenderYou ? "right-div" : "left-div"}
                    >
                      <div className="top d-flex gap-1">
                        {isSenderYou ? (
                          <>
                            <div
                              className="text-medium fw-normal"
                              dangerouslySetInnerHTML={{
                                __html: message?.text,
                              }}
                            />
                            <img
                              style={{ borderRadius: 100 }}
                              src={
                                profileData?.picture ||
                                "../../assets/dashboardimages/images/animoji.png"
                              }
                              alt=""
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={message.image || "../../assets/dashboardimages/images/animoji.png"}
                              alt=""
                            />
                            <div
                              className="text-medium fw-normal"
                              dangerouslySetInnerHTML={{
                                __html: message?.text,
                              }}
                            />
                          </>
                        )}
                      </div>
                      <div className="bottom d-flex">
                        <span className="d-flex gap-2 fw-normal">
                          {message?.sender}{" "}
                          <span className="fw-normal">{message?.time}</span>
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="w-100 v-center h-center flex-column mt-2 b-main">
            <div className={`buttons h-center v-center gap-20 ${props.hide} `}>
              <img
                src={
                  !isSpeakerMuted
                    ? "../../assets/dashboardimages/images/unmute.png"
                    : "../../assets/dashboardimages/images/speaker.png"
                }
                alt={isSpeakerMuted ? "Muted" : "Unmuted"}
                className="cursor-pointer"
                onClick={
                  hasTextBasedPlan
                    ? () => toast.error("Upgrade your plan to have voice chat!")
                    : () => {
                        isSpeakerMutedRef.current = !isSpeakerMutedRef.current;
                        setIsSpeakerMuted(!isSpeakerMuted);
                      }
                }
              />
              {isListening ? (
                <img
                  className="cursor-pointer"
                  src="../../assets/dashboardimages/images/micro-unmute.png"
                  alt="muted"
                  onClick={stopListening}
                />
              ) : (
                <img
                  src="../../assets/dashboardimages/images/mute.png"
                  alt="Unmuted"
                  className="cursor-pointer"
                  onClick={
                    hasTextBasedPlan
                      ? () =>
                          toast.error("Upgrade your plan to have voice chat!")
                      : startListening
                  }
                />
              )}

              <img
                src="../../assets/dashboardimages/images/callend.png"
                alt=""
                className="cursor-pointer"
                onClick={() => {
                  onCallEndClick();
                }}
              ></img>
            </div>
            <div
              className={`button-div h-center v-center d-none ${props.display} `}
            >
              <button
                onClick={() => {
                  navigate("/conversations");
                }}
              >
                Close Discussion
              </button>
            </div>
            <div className="disc-bottom v-center space-between gap-20">
              <div className="w-100">
                {renderFilePreview()}
                <input
                  className="message-input"
                  type="text"
                  placeholder="Message Slara"
                  value={chatInput}
                  onChange={(e) => setChatInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSendMessage();
                    }
                  }}
                />
              </div>

              <div className="bottom-right v-center">
                <Icon
                  icon="ion:attach"
                  width={24}
                  height={24}
                  color="#FFFFFF"
                  className="cursor-pointer"
                  onClick={handleIconClick}
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />

                <img
                  onClick={handleSendMessage}
                  src="../../assets/dashboardimages/images/arrow-right.png"
                  alt=""
                  className="flag-icon next-arrow cursor-pointer"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CallEndModal
        personas={continuedPersonas}
        sessionId={sessionId}
        isendModal={isendModal}
        setisendModal={setisendModal}
        setCheck={setCheck}
      ></CallEndModal>
      < FreeTrialEnded
      isGuestModal={isGuestModal}
      setisGuestModal={setisGuestModal}
      ></FreeTrialEnded>
    </>
  );
};

export default Discussion;
