import React, { useContext, useEffect, useState } from "react";
import "../style/components/pricingPlan.css";
import PricingPlan from "../components/home/pricingPlan";
import { AppContext } from "../Context/AppContext";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import PaymentCancel from "../modals/paymentCancel";
import UpgradePlan from "../modals/upgradePlan";

const Plans = () => {
  const [isendModal, setisendModal] = useState(false);
  const [isUpgradeModal, setIsUpgradeModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("activePlan");
  const [selectedPlan1, setSelectedPlan1] = useState("individual");
  const [isPlanActive, setIsPlanActive] = useState(true);
  const [name, setName] = useState();
  const [price, setPrice] = useState();
  const [updatedIndividualPlans, setUpdatedIndividualPlans] = useState([]);
  const [updatedEnterprisePlans, setUpdatedEnterprisePlans] = useState([]);
  const [updatedTextBasedPlans, setUpdatedTextBasedPlans] = useState([]);
  const [removedPlans, setRemovedPlans] = useState([]);
  const userId = secureLocalStorage.getItem("userId");
  const profileData = secureLocalStorage.getItem("profileData");
  const navigate = useNavigate();
  const location = useLocation();

  const {
    userHasPlan,
    setUserHasPlan,
    individualPlans,
    enterprisePlans,
    textBasedPlans,
    ProfileData,
  } = useContext(AppContext);
  const handleGetStarted = async (name, price) => {
    if (name === "Free_Trial") {
      try {
        const payload = profileData?.email
          ? {
              userid: userId || "",
              amount: price,
              plan_name: name,
              email: profileData.email,
              base_url: "https://www.slara.ai",
            }
          : {
              userid: "",
              amount: 0.0,
              plan_name: name,
              email: "abc@gmail.com",
              base_url: "",
            };
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/create-checkout-session`,
          payload
        );
        if (response.data.save === "save plan") {
          setUserHasPlan("Free_Trial");
          toast.success("Free trial activated!");
          await ProfileData();
          navigate("/dashboard");
        } else if (response.data["Already subscribed"]) {
          toast.error(
            response.data["Already subscribed"] ||
              "You have already used your free trial!"
          );
        } else if (response.data.Response) {
          const { userid, token, plan, guest_id } = response.data.Response;

          secureLocalStorage.setItem("authToken", token);
          secureLocalStorage.setItem("userId", userid);
          secureLocalStorage.setItem("guestId", guest_id);
          secureLocalStorage.setItem("usertype", "guest");
          setUserHasPlan(plan);
          toast.success("Free Trial Activated! ");
          navigate("/dashboard");
          await ProfileData();
        } else {
          toast.error("You already have a plan");
        }
      } catch (error) {
        console.error("Error activating free trial:", error);
        toast.error("Something went wrong. Please try again later.");
      }
    }  else {
      if (location.pathname === "/pricing" || location.pathname === "/plans") {
        if (userHasPlan?.plan_name) {
          setIsUpgradeModal(true);
          setName(name);
          setPrice(price);
        } else {
          makePayment(name, price);
        }
      } else {
        navigate("/signup");
      }
    }
  };
  const upgradePlan = async (name, price) => {
    try {
      // window.dataLayer.push({
      //   event: 'plan_selected',
      //   planName: name,
      //   planPrice: parseFloat(price),
      // });
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/upgrade_plan`,
        {
          userid: userId,
          amount: price,
          plan_name: name,
          email: profileData?.email,
          base_url: "https://www.slara.ai",
        }
      );

      const session = response.data.sessionId;
      if (response.data === "You have already a plan") {
        toast.info("You already have an active plan.");
        return;
      }

      const result = stripe.redirectToCheckout({
        sessionId: session,
      });
      if (result.error) {
        // Handle error here
      }
    } catch (e) {
      console.log("Error upgrading plan:", e);
    }
  };
  const makePayment = async (name, price) => {
    try {
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/create-checkout-session`,
        {
          userid: userId,
          amount: price,
          plan_name: name,
          email: profileData?.email,
          base_url: "https://www.slara.ai",
        }
      );

      const session = response.data.sessionId;
      if (response.data === "You have already a plan") {
        toast.info("You already have an active plan.");
        return;
      }

      const result = stripe.redirectToCheckout({
        sessionId: session,
      });
      if (result.error) {
        // Handle error here
      }
    } catch (e) {
      console.log("e", e);
    }
  };

  const removePlan = (plansArray, planName) => {
    let removedPlan = null;
    const updatedPlans = plansArray.filter((plan) => {
      if (plan.id === planName) {
        removedPlan = plan;
      }
      return plan.id !== planName;
    });

    return { updatedPlans, removedPlan };
  };

  useEffect(() => {
    if (userHasPlan) {
      const {
        updatedPlans: individualUpdated,
        removedPlan: removedIndividualPlan,
      } = removePlan(individualPlans, userHasPlan.plan_name);
      const {
        updatedPlans: enterpriseUpdated,
        removedPlan: removedEnterprisePlan,
      } = removePlan(enterprisePlans, userHasPlan.plan_name);
      const {
        updatedPlans: textBasedUpdated,
        removedPlan: removedTextBasedPlan,
      } = removePlan(textBasedPlans, userHasPlan.plan_name);

      setUpdatedIndividualPlans(individualUpdated);
      setUpdatedEnterprisePlans(enterpriseUpdated);
      setUpdatedTextBasedPlans(textBasedUpdated);

      setRemovedPlans(
        [
          removedIndividualPlan,
          removedEnterprisePlan,
          removedTextBasedPlan,
        ].filter(Boolean)
      );
    }
  }, [userHasPlan]);

  const cancelPlan = () => {
    setisendModal(true);
  };

  return (
    <>
      <div className="tabMainParent">
        <div
          style={{ width: "50%" }}
          className="bg-Set-Card d-flex rounded-pill justify-content-between m-auto mtSet border p-1 px-2"
        >
          <button
            className={`rounded-pill shadow w-50 ${
              selectedPlan === "activePlan" ? "bg-9b28a8" : "bg-transparent"
            } px-3 py-2`}
            onClick={() => setSelectedPlan("activePlan")}
          >
            Active Plan
          </button>
          <button
            className={`rounded-pill shadow w-50 ${
              selectedPlan === "otherPlans" ? "bg-9b28a8" : "bg-transparent"
            } px-3 py-2`}
            onClick={() => setSelectedPlan("otherPlans")}
          >
            Other Plans
          </button>
        </div>

        {selectedPlan === "otherPlans" && (
          <div className="mt-3">
            <div className="pricingSingleCard" id="pricing">
              <div className="container">
                <h2 className="fw-semibold text-white h-center">
                  Pricing Plan
                </h2>
                <p className="max-width-964px text-center mt-4">
                  Slara.AI offers flexible subscription plans tailored to your
                  discussion needs. Whether you're a casual user, a regular
                  learner, or a power user, we have the right plan for you.
                </p>

                <div className="tabMainParent">
                  <div
                    style={{ width: "50%" }}
                    className="bg-Set-Card d-flex rounded-pill justify-content-between m-auto mtSet border p-1 px-2"
                  >
                    <button
                      className={`rounded-pill shadow w-50 ${
                        selectedPlan1 === "individual"
                          ? "bg-9b28a8"
                          : "bg-transparent"
                      } px-3 py-2 `}
                      onClick={() => setSelectedPlan1("individual")}
                    >
                      Individual Plan
                    </button>
                    <button
                      className={`rounded-pill shadow w-50 ${
                        selectedPlan1 === "enterprise"
                          ? "bg-9b28a8"
                          : "bg-transparent"
                      } px-3 py-2`}
                      onClick={() => setSelectedPlan1("enterprise")}
                    >
                      Enterprise Plan
                    </button>
                    {updatedTextBasedPlans &&
                      updatedTextBasedPlans.length > 0 && (
                        <button
                          className={`rounded-pill shadow w-50 ${
                            selectedPlan1 === "textBased"
                              ? "bg-9b28a8"
                              : "bg-transparent"
                          } px-3 py-2`}
                          onClick={() => setSelectedPlan1("textBased")}
                        >
                          Text-Based Interactions
                        </button>
                      )}
                  </div>
                </div>

                <div className="container d-flex mt-5 justify-content-center SetForSmall">
                  {selectedPlan1 === "individual" &&
                    updatedIndividualPlans
                      .filter((plan) =>
                        userHasPlan?.plan_name ? plan.id !== "Free_Trial" : true
                      )
                      .map((plan) => (
                        <div
                          key={plan.id}
                          data-plan-name={plan.id}
                          data-plan-price={plan.price}
                          style={{
                            minHeight: "400px",
                            height: "100%",
                          }}
                          className="col-lg-4 col-md-12 col-sm-12 col-12 bg-Set-Card rounded-4 py-3 setHeight border m-10 "
                        >
                          <div className="container m-auto">
                            <h3 className="text-center">{plan.title}</h3>
                            <p className="text-center mt-3">${plan.price}</p>
                            <p className="text-center mt-3">/Monthly</p>
                            <button
                              className="dash-pink-btn w-100 mt-3"
                              onClick={() =>
                                handleGetStarted(plan.id, plan.price)
                              }
                            >
                              {userHasPlan?.plan_name
                                ? "Upgrade Now"
                                : plan.buttonText}
                            </button>
                          </div>
                          {plan.features.map((feature, index) => (
                            <div
                              key={index}
                              className="container d-flex align-items-center mt-3 w-100"
                            >
                              <div className="w-25 text-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="31"
                                  height="31"
                                  viewBox="0 0 31 31"
                                  fill="none"
                                >
                                  <circle
                                    cx="15.3289"
                                    cy="15.1858"
                                    r="15.1858"
                                    fill="#9208F3"
                                    fill-opacity="0.2"
                                  />
                                  <path
                                    d="M8.31982 15.6017L12.2378 19.5196C12.6464 19.9282 13.3152 19.9065 13.6964 19.4722L22.1431 9.84937"
                                    stroke="#9B28A8"
                                    stroke-width="2.5"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </div>
                              <div className="w-75">
                                <p className="">{feature}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}

                  {selectedPlan1 === "enterprise" &&
                    updatedEnterprisePlans.map((plan) => (
                      <div
                        key={plan.id}
                        data-plan-name={plan.id}
                        data-plan-price={plan.price}
                        className="col-lg-4 col-md-12 col-sm-12 col-12 bg-Set-Card rounded-4 py-3 setHeight border m-10 "
                      >
                        <div className="container m-auto">
                          <h3 className="text-center">{plan.title}</h3>
                          <p className="text-center mt-3">${plan.price}</p>
                          <p className="text-center mt-3">/Monthly</p>
                          <button
                            className="dash-pink-btn w-100 mt-3"
                            onClick={() =>
                              handleGetStarted(plan.id, plan.price)
                            }
                          >
                            {userHasPlan?.plan_name
                              ? "Upgrade Now"
                              : plan.buttonText}
                          </button>
                        </div>
                        {plan.features.map((feature, index) => (
                          <div
                            key={index}
                            className="container d-flex align-items-center mt-3 w-100"
                          >
                            <div className="w-25 text-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="31"
                                height="31"
                                viewBox="0 0 31 31"
                                fill="none"
                              >
                                <circle
                                  cx="15.3289"
                                  cy="15.1858"
                                  r="15.1858"
                                  fill="#9208F3"
                                  fill-opacity="0.2"
                                />
                                <path
                                  d="M8.31982 15.6017L12.2378 19.5196C12.6464 19.9282 13.3152 19.9065 13.6964 19.4722L22.1431 9.84937"
                                  stroke="#9B28A8"
                                  stroke-width="2.5"
                                  stroke-linecap="round"
                                />
                              </svg>
                            </div>
                            <div className="w-75">
                              <p className="">{feature}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}

                  {selectedPlan1 === "textBased" &&
                    updatedTextBasedPlans.map((plan) => (
                      <div
                        key={plan.id}
                        data-plan-name={plan.id}
                        data-plan-price={plan.price}
                        className="col-lg-4 col-md-12 col-sm-12 col-12 bg-Set-Card rounded-4 py-3 setHeight border m-10 "
                      >
                        <div className="container m-auto">
                          <h3 className="text-center">{plan.title}</h3>
                          <p className="text-center mt-3">${plan.price}</p>
                          <p className="text-center mt-3">/Monthly</p>
                          <button
                            className="dash-pink-btn w-100 mt-3"
                            onClick={() =>
                              handleGetStarted(plan.id, plan.price)
                            }
                          >
                            {userHasPlan ? "Upgrade Now" : plan.buttonText}
                          </button>
                        </div>
                        {plan.features.map((feature, index) => (
                          <div
                            key={index}
                            className="container d-flex align-items-center mt-3 w-100"
                          >
                            <div className="w-25 text-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="31"
                                height="31"
                                viewBox="0 0 31 31"
                                fill="none"
                              >
                                <circle
                                  cx="15.3289"
                                  cy="15.1858"
                                  r="15.1858"
                                  fill="#9208F3"
                                  fill-opacity="0.2"
                                />
                                <path
                                  d="M8.31982 15.6017L12.2378 19.5196C12.6464 19.9282 13.3152 19.9065 13.6964 19.4722L22.1431 9.84937"
                                  stroke="#9B28A8"
                                  stroke-width="2.5"
                                  stroke-linecap="round"
                                />
                              </svg>
                            </div>
                            <div className="w-75">
                              <p className="">{feature}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-center">
          {selectedPlan === "activePlan" &&
            removedPlans.map((plan) => (
              <div
                key={plan.id}
                style={{
                  minHeight: "400px",
                  height: "100%",
                }}
                className=" col-lg-4 col-md-12 col-sm-12 col-12 bg-Set-Card rounded-4 py-3 setHeight border m-10 "
              >
                <div className="container m-auto">
                  <h3 className="text-center">{plan.title}</h3>
                  <p className="text-center mt-3">${plan.price}</p>
                  <p className="text-center mt-3">/Monthly</p>

                  <div className="text-center mt-3">
                    {isPlanActive ? (
                      <span className="badge bg-success">Active</span> // Show active status
                    ) : (
                      <span className="badge bg-danger">Plan Canceled</span> // Show canceled status
                    )}
                  </div>
                  <button
                    className="dash-pink-btn w-100 mt-3"
                    onClick={() => {
                      {
                        isPlanActive && cancelPlan();
                      }
                    }}
                  >
                    {isPlanActive ? "Cancel Plan" : plan.buttonText}
                  </button>
                </div>

                {plan.features.map((feature, index) => (
                  <div
                    key={index}
                    className="container d-flex align-items-center mt-3 w-100"
                  >
                    <div className="w-25 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="31"
                        height="31"
                        viewBox="0 0 31 31"
                        fill="none"
                      >
                        <circle
                          cx="15.3289"
                          cy="15.1858"
                          r="15.1858"
                          fill="#9208F3"
                          fillOpacity="0.2"
                        />
                        <path
                          d="M8.31982 15.6017L12.2378 19.5196C12.6464 19.9282 13.3152 19.9065 13.6964 19.4722L22.1431 9.84937"
                          stroke="#9B28A8"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div className="w-75">
                      <p>{feature}</p>
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
      <PaymentCancel
        isendModal={isendModal}
        setisendModal={setisendModal}
        setIsPlanActive={setIsPlanActive}
        isPlanActive={isPlanActive}
      ></PaymentCancel>
      <UpgradePlan
        isUpgradeModal={isUpgradeModal}
        setIsUpgradeModal={setIsUpgradeModal}
        name={name}
        price={price}
        upgradePlan={upgradePlan}
      ></UpgradePlan>
    </>
  );
};

export default Plans;
