import React, { useState, useRef, useEffect, useContext } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { AppContext } from "../../Context/AppContext";

const Settings = () => {
  const navigate = useNavigate();
  const { profileData, setProfileData, isLoading } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  // const storedProfileData = secureLocalStorage.getItem(`profileData`);
  // const userId = secureLocalStorage.getItem("userId");
  // const profileData = storedProfileData ? JSON.parse(storedProfileData) : null;
  const fileInputRef = useRef(null);
  // console.log(storedProfileData);

  useEffect(() => {
    if (isLoading === false) {
      setLoading(false);
    }
  }, [isLoading]);

  // const handleDivClick = () => {
  //   fileInputRef.current.click();
  // };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setProfileImage(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "80vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div class="loader"></div>
        </div>
      ) : (
        <div className="settings-main-conatainer h-center">
          <div className="right-container v-center flex-column w-100">
            <h4 className=" fw-bold">Account Details</h4>
            <div className="profile-img ">
              <img
                src={
                  profileData?.picture ||
                  "../../assets/dashboardimages/images/userDp.png"
                }
                alt="profile"
              />
              {/* <div className="change-pic-div v-center flex-column h-center">
              <Icon
                icon="f7:camera-fill"
                width={24}
                height={24}
                color="#FFFFFF"
              />
              <p className="text-small fw-normal text-center">
                Click to change photo
              </p>
            </div> */}
              {/* <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleFileChange}
            /> */}
            </div>
            <div className="details-div w-100 v-center flex-column">
              <button
                className="v-center space-between"
                onClick={() => {
                  navigate("/settings/edit-profile");
                }}
              >
                Update Profile
                <Icon
                  icon="mingcute:right-line"
                  width={24}
                  height={24}
                  color="#FFFFFF"
                />
              </button>
              <button
                className="v-center space-between"
                onClick={() => {
                  navigate("/settings/update-Email");
                }}
              >
                Update Email
                <Icon
                  icon="mingcute:right-line"
                  width={24}
                  height={24}
                  color="#FFFFFF"
                />
              </button>
              <button
                className="v-center space-between"
                onClick={() => {
                  navigate("/settings/update-password");
                }}
              >
                Change Password
                <Icon
                  icon="mingcute:right-line"
                  width={24}
                  height={24}
                  color="#FFFFFF"
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Settings;
