import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { toast } from "react-toastify";
import { AppContext } from "../Context/AppContext";

const PaymentCancel = ({ isendModal, setisendModal , setIsPlanActive , isPlanActive }) => {
  const navigate = useNavigate();
  const userId = secureLocalStorage.getItem("userId");
  const {setUserHasPlan} = useContext(AppContext);
  const handleOk = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/cancel_plan`, {
        userid: userId,
      })
      .then(() => {
        setIsPlanActive(!isPlanActive);
        setUserHasPlan();
        toast.success("Your plan has been successfully canceled!");
        navigate("/pricing");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.Message);
      });
  };
  const handleCancel = () => {
    setisendModal(false);
  };

  return (
    <>
      <Modal
        open={isendModal}
        onOk={handleOk}
        onCancel={handleCancel}
        className="del-modal"
        centered={true}
        width="38%"
        maskClosable={false}
      >
        <div className="modal-content v-center h-center flex-column">
          <h5 className=" fw-bold fw-medium">
            Are you sure you want to cancel your plan?
          </h5>
          <div className="d-flex gap-4">
            <button
              onClick={() => {
                handleOk();
              }}
            >
              Yes
            </button>
            <button
              onClick={() => {
                handleCancel();
              }}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PaymentCancel;
