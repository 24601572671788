import React from "react";
import Navbar from "../shared/navbar";
import PricingPlan from "../components/home/pricingPlan";


const PricingPage = () => {
  return (
    <>
   <Navbar/>
   <div className="pricingWrapper">
<div className="mainDiv">
  {/* <h2 className="text-white h-center">Pricing</h2> */}
  <div className="pricingCardContainer">
    <PricingPlan/>
  </div>
</div>
   </div>
    </>
  )
}

export default PricingPage;
