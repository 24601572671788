import axios from "axios";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { AppContext } from "../Context/AppContext";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const PaymentPending = () => {
  const navigate = useNavigate();
  const { userHasPlan, setUserHasPlan } = useContext(AppContext); 
  const userId = secureLocalStorage.getItem("userId");
//   console.log(userHasPlan);

  
  const cancelPlan = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/cancel_plan`, {
        userid: userId,
      })
      .then(() => {
        setUserHasPlan(null); 
        toast.success("Your plan has been successfully canceled!");
        navigate("/pricing");
      })
      .catch((error) => {
        toast.error(error.response.data.Message || "Failed to cancel the plan");
      });
  };

  return (
    <div 
      className="d-flex justify-content-center align-items-center vh-100" 
      style={{ background: "linear-gradient(95deg, #371541, #2a174f)" }} 
    >
      <div 
        className="text-center p-4" 
        style={{ 
          maxWidth: "600px", 
          background: "linear-gradient(95deg, #371541, #2a174f)", 
          borderRadius: "10px", 
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          height: "auto"
        }}
      >
        <h1 className="mb-4 text-white" style={{ fontSize: "2.5rem" }}>Payment Pending</h1>
        <p className="text-white">
        There was an issue processing your payment. Please cancel the current plan and select a new plan.
        </p>
        <button 
                className="btn w-50 mt-4" 
                onClick={() => navigate("/")}
                style={{ 
                  background: "var(--2-color-grad, linear-gradient(133deg, #9208f3 2.91%, #9b28a8 100%))", 
                  color: "#fff",
                  border: "none",
                }}
              >
                Back to home
              </button>
         {userHasPlan && (
          <div className=" bg-transparent text-white p-3 my-4">
            <div className="mt-4">
             
              <h3 className="card-title">{userHasPlan.plan_name}</h3>
              <p className="card-text">${userHasPlan.amount} / Monthly</p>

              <button 
                className="btn w-50 mt-4" 
                onClick={cancelPlan}
                style={{ 
                  background: "var(--2-color-grad, linear-gradient(133deg, #9208f3 2.91%, #9b28a8 100%))", 
                  color: "#fff",
                  border: "none",
                }}
              >
                Cancel Plan
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentPending;
