import React, { useEffect } from "react";
import Navbar from "../shared/navbar";
import Footer from "../shared/footer";

const PrivacyPolicy = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <div className="privacyPolicyContainer">
        <div className="container">
        <h2>Privacy Policy</h2>
        <p className="mt-4 pb-4 mx-auto para">
          At Slara AI, your privacy is a priority. This Privacy Policy explains
          how we collect, use, and protect your personal data when you use our
          platform, and it outlines your rights regarding that information.
        </p>
        <div className="inner">
            <div className="privacyContent mb-5">
            <ol>
            <h3 className="mb-3 mt-4">1. Information We Collect </h3>
            <p className="mb-3">We may collect the following types of information:</p>
<ul>
    <li><strong>Personal Information:</strong> When you sign up for Slara AI, we may collect personal details such as your name, email address, and phone number.</li>
    <li><strong>Usage Data:</strong> We collect data on how you interact with Slara AI, including usage patterns, preferences, and activity within the platform.</li>
    <li><strong>Technical Data:</strong> This includes your device type, IP address, browser type, operating system, and other relevant technical information.</li>
    <li><strong>Communications:</strong> Any data shared in communications with our team or through the Slara AI platform may be stored to improve your experience</li>
</ul>
          </ol>
            </div>
      



            <div className="privacyContent mb-5">
            <ol>
            <h3 className="mb-3 mt-4">2. How We Use Your Information </h3>
            <p className="mb-3">We use your information to:</p>
<ul>
    <li><strong>Enhance Your Experience:</strong> Personalize your interactions with the platform based on your preferences and past usage..</li>
    <li><strong>Improve the Platform:</strong> Analyze data and trends to enhance Slara AI’s performance and add new features.</li>
    <li><strong>Communicate:</strong> Send you updates, notifications, and other important information related to your use of Slara AI.</li>
    <li><strong>Security:</strong> Monitor for potential security breaches or suspicious activity to safeguard your data.</li>
    <li><strong>Compliance:</strong> Ensure we comply with applicable laws and regulations.</li>
</ul>
          </ol>
            </div>



            
            <div className="privacyContent mb-5">
            <ol>
            <h3 className="mb-3 mt-4">3. Sharing Your Information </h3>
            <p className="mb-3">We may share your data with third parties in the following scenarios:</p>
<ul>
    <li> <strong>Service Providers:</strong> We use third-party services for hosting, data storage, analytics, and more to operate Slara AI.</li>
    <li> <strong>Legal Obligations:</strong> We may disclose your data if required by law, regulation, or government request.</li>
    <li> <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred to a new entity.</li>

</ul>
          </ol>
            </div>





            <div className="privacyContent mb-5">
            <ol>
            <h3 className="mb-3 mt-4">4. Your Rights </h3>
            <p className="mb-3">You have the following rights regarding your personal information:</p>
<ul>
    <li> <strong>Access:</strong> You may request access to the personal data we hold about you.</li>
    <li><strong>Correction:</strong> If your data is incorrect, you have the right to ask for it to be corrected.</li>
    <li><strong>Deletion:</strong> You may request that we delete your personal information, subject to legal and contractual obligations.</li>
    <li><strong>Data Portability:</strong> Request a copy of your data in a machine-readable format.</li>

</ul>
          </ol>
            </div>



            <div className="privacyContent mb-5">
            <ol>
            <h3 className="mb-3 mt-4">5. Security </h3>
            <p className="mb-3">We employ industry-standard security measures, including encryption and access controls, to protect your data from unauthorized access, disclosure, or loss. However, no system is completely secure, and we cannot guarantee the absolute security of your information.</p>

          </ol>
            </div>


            <div className="privacyContent mb-5">
            <ol>
            <h3 className="mb-3 mt-4">6. Data Retention </h3>
            <p className="mb-3">We retain your data for as long as necessary to provide our services, comply with legal obligations, resolve disputes, and enforce agreements</p>

          </ol>
            </div>

            <div className="privacyContent mb-5">
            <ol>
            <h3 className="mb-3 mt-4">7. Third-Party Links </h3>
            <p className="mb-3">Slara AI may contain links to third-party websites. We are not responsible for the privacy practices or content of these sites. Please review their privacy policies before interacting with them.</p>

          </ol>
            </div>

            <div className="privacyContent mb-5">
            <ol>
            <h3 className="mb-3 mt-4">8.  Changes to This Policy </h3>
            <p className="mb-3">We may update this Privacy Policy periodically. When changes occur, we will notify you by updating the date at the top of this policy and, in some cases, providing additional notice (such as through the platform or email).</p>

          </ol>
            </div>
            

            <div className="privacyContent mb-5">
            <ol>
            <h3 className="mb-3 mt-4">9.  Contact Us </h3>
            <p className="mb-3">If you have any questions or concerns about this Privacy Policy or your personal data, please contact us at:</p>
            <p><strong>Email:</strong> info@slara.ai</p>

          </ol>
            </div>
        </div>
        </div>
     
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
