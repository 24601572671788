import React, { useState } from "react";
import { Icon } from "@iconify/react";

const Feedbackcard = ({ persona, sessionId }) => {
  const [activeThumb, setActiveThumb] = useState(null);
  const [rating, setRating] = useState(0);
  const handleStarClick = (index) => {
    setRating(index + 1);
  };
  const handleThumbClick = (thumb) => {
    if (activeThumb === thumb) {
      setActiveThumb(null);
    } else {
      setActiveThumb(thumb);
    }
  };
  return (
    <div className="feedback-card d-flex">
      <div className="card-left v-center gap-10 fit-content">
        <img
          src={persona.image}
          alt="checkmark"
        />
        <h6 className="fw-medium">{persona.persona_name}</h6>
      </div>
      <div className="card-right h-center flex-column">
        <div className="stars-div d-flex">
          {[...Array(5)].map((_, index) => (
            <Icon
              key={index}
              icon="tdesign:star-filled"
              width={30}
              height={30}
              color={index < rating ? "#DFB300" : "#626262"}
              className="cursor-pointer"
              onClick={() => handleStarClick(index)}
            />
          ))}
        </div>
        <div className="thumbs-div d-flex">
          <Icon
            icon="octicon:thumbsdown-24"
            width={24}
            height={24}
            color={activeThumb === "down" ? "#FFFFFF" : "#626262"}
            className="thumbs-down cursor-pointer"
            onClick={() => handleThumbClick("down")}
          />
          <Icon
            icon="octicon:thumbsup-24"
            width={24}
            height={24}
            color={activeThumb === "up" ? "#FFFFFF" : "#626262"}
            className=" cursor-pointer thumbs-up"
            onClick={() => handleThumbClick("up")}
          />
        </div>
      </div>
    </div>
  );
};

export default Feedbackcard;
