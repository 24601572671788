import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";

const DeleteModal = ({ isendModal, setisendModal, setCheck , personas , sessionId , time }) => {
  const navigate = useNavigate();
  const userId = secureLocalStorage.getItem("userId")
  const handleOk = () => {
    setisendModal(false);
  };
  const handleCancel = () => {
    setisendModal(false);
  };
  
  return (
    <>
      <Modal
        open={isendModal}
        onOk={handleOk}
        onCancel={handleCancel}
        className="del-modal"
        centered={true}
        width="38%"
        maskClosable={false}
      >
        <div className="modal-content v-center h-center flex-column">
          <h5 className=" fw-bold fw-medium">
            You sure you want to end this call?
          </h5>
          <div className="d-flex gap-4">
            <button
              onClick={() => {
                setCheck(false);
                navigate("/dashboard/feedback" , {
                  state: { personas , sessionId },
                });
                // window.location.reload();
              }}
            >
              Yes
            </button>
            <button
              onClick={() => {
                handleCancel();
              }}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteModal;
