import React, { useContext, useState } from "react";
import logo from "../assets/images/signIn/logoS.png";
import { Icon } from "@iconify/react";
import googleBtn from "../assets/images/signIn/google.png";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { AppContext } from "../Context/AppContext";

const SignUp = () => {
  const navigate = useNavigate();
  const { formattedTime } = useContext(AppContext);
  const [showPassword, setshowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  function tooglepasswordvisibility() {
    setshowPassword(!showPassword);
  }

  const [showPassword2, setshowPassword2] = useState(false);
  function tooglepasswordvisibility2() {
    setshowPassword2(!showPassword2);
  }

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    rePassword: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,14}$/;

    if (values.name == "") {
      errors.name = "Name is required";
    } else if (values.name.length > 50) {
      errors.name =
        "Username is too long. Please enter a username with fewer than 50 characters";
    } else if (!/^[a-zA-Z\s]+$/.test(values.name)) {
      errors.name = "Invalid characters detected. Please remove symbols.";
    }

    if (values.email == "") {
      errors.email = "Email is required";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid Email Format";
    }
    if (values.password == "") {
      errors.password = "Password is required";
    }
    //   else if (values.password.length < 8 || values.password.length > 20) {
    //   errors.password = "Password length must be between 8 and 20 characters";
    // }
    else if (!passwordRegex.test(values.password)) {
      errors.password =
        "Password must be at least 8 characters long and contain a mix of letters, numbers, and symbols";
    }

    if (values.rePassword === "") {
      errors.rePassword = "Re-enter Password is required";
    } else if (values.password !== values.rePassword) {
      errors.rePassword = "Passwords do not match. Please try again.";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(formData);
    setFormErrors(errors);

    // Check if all required fields are empty
    const allFieldsEmpty = Object.values(formData).every(
      (value) => value.trim() === ""
    );
    if (allFieldsEmpty) {
      toast.error("Please fill in all the required fields");
      return;
    }

    if (Object.keys(errors).length > 0) {
      // Display error toasts and update state for highlighting
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key]);
      });
      setFormErrors(errors);
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/signup`,
        {
          username: formData.name,
          email: formData.email,
          password: formData.password,
          confirm_password: formData.rePassword,
          guest_id: secureLocalStorage.getItem("guestId"),
          time: secureLocalStorage.getItem("time"),
        }
      );

      // console.log(response.data);
      toast.success(response?.data?.Response);
      navigate("/verify-code", { state: { fromRoute1: true, formData } });
    } catch (error) {
      // console.error('There was a problem with the API call:', error.response.data.detail);
      toast.error(error?.response?.data?.detail);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = (e) => {
    e.preventDefault();
    // Your logic for Google Sign-In
  };

  // const handel1 = () => {
  //   console.log("clicked");
  // };
  return (
    <>
      <div className="signInwrapper">
        <div className="nav">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <NavLink to="/signin">
            {" "}
            <button>SIGN IN</button>
          </NavLink>
        </div>
        <div className="centerDiv signupCenterDiv">
          <h5>Sign Up</h5>
          <div className="userData signupUserData">
            <form onSubmit={handleSubmit} action="">
              <div className="emailInput">
                <label className="mb-2" for="name">
                  Full Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter Full Name"
                />
                {/* {formErrors.name && <span className="error">{formErrors.name}</span>} */}
              </div>

              <div className="emailInput">
                <label className="mb-2" for="email">
                  EMAIL
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter Email"
                />
              </div>

              <div className="passwordInput">
                <label className="mb-2" for="password">
                  ENTER PASSWORD
                </label>
                <div className="pswd-div">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Enter Password"
                  />
                  <Icon
                    className="iconEye"
                    onClick={tooglepasswordvisibility}
                    icon={showPassword ? "fa-solid:eye" : "fa6-solid:eye-slash"}
                  />
                </div>
              </div>

              <div className="passwordInput">
                <label className="mb-2" for="repassword">
                  RE-ENTER PASSWORD
                </label>
                <div className="pswd-div">
                  <input
                    type={showPassword2 ? "text" : "password"}
                    name="rePassword"
                    value={formData.rePassword}
                    onChange={handleChange}
                    placeholder="Re-Enter Password"
                  />
                  <Icon
                    className="iconEye"
                    onClick={tooglepasswordvisibility2}
                    icon={
                      showPassword2 ? "fa-solid:eye" : "fa6-solid:eye-slash"
                    }
                  />
                </div>
              </div>
              <button type="submit" className="signInn" disabled={loading}>
                {loading ? "Signing Up..." : "Sign Up"}
              </button>
              {/* <div className="forgot d-flex justify-content-end w-100">
              <button>Forgot Password</button>
            </div> */}
              <span className="or h-center my-2 text-black">OR</span>

              <div className="googleBtn">
                <button onClick={handleGoogleSignIn}>
                  <img src={googleBtn} alt="" />
                  <span>Continue with Google</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
