import React, { useState, useEffect, useContext, useRef } from "react";
import { Space, Slider, Modal, Select } from "antd";
import { Icon } from "@iconify/react";
import DeletePersonaModal from "../../modals/deletePersonaModal";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { AppContext } from "../../Context/AppContext";
import { MdPause, MdPlayArrow } from "react-icons/md";
import { Option } from "antd/es/mentions";

const Editpersonacard = ({ setIsChildLoading , id, editBoolean }) => {
  const {
    removePersonaCard,
    setPersonas,
    personas,
    fetchedPersonas,
    setFetchedPersonas,
    userHasPlan
  } = useContext(AppContext);

  // console.log(personas, id);
  const [errors, setErrors] = useState({ name: "", description: "" });
  const [voiceChecks, setVoiceChecks] = useState({});
  const [isPersonaDropdownOpen, setIsPersonaDropdownOpen] = useState(false);
  const [isVoiceOptionsCollapsed, setIsVoiceOptionsCollapsed] = useState(false);
  const [isVoicePreview, setIsVoicePreview] = useState(false);
  const [selectedGender, setSelectedGender] = useState();
  const [isSpeakerBoostCollapsed, setIsSpeakerBoostCollapsed] = useState(true);
  const [selectedfetchpersona, setSelectedfetchpersona] = useState();
  const [previousPersonaName, setPreviousPersonaName] = useState();
  const [loading, setLoading] = useState(false);
  const [existingPersona, setExistingPersona] = useState(false);
  const [isdelModal, setisdelModal] = useState(false);
  const [voicePreviewData, setVoicePreviewData] = useState([]);
  const [playingVoiceId, setPlayingVoiceId] = useState(null);
  const [audioInstance, setAudioInstance] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const userId = secureLocalStorage.getItem("userId");
  const usertype = secureLocalStorage.getItem("usertype")
  const topic = localStorage.getItem("topic");
  const [showModal, setShowModal] = useState(false);
  const option = [{ name: "Male" }, { name: "Female" }];
  const showVoiceSettings = userHasPlan?.plan_name !== "Text_Base_Intraction_Only";
  useEffect(() => {
    setPreviousPersonaName(personas[id].name);
  }, []);

  // useEffect(() => {
  //   console.log("voiceChecks", voiceChecks);
  // }, [voiceChecks]);

  const selectOption = (option) => {
    setPersonas((prev) => {
      const updatedPersonas = [...prev];
      updatedPersonas[id] = { ...updatedPersonas[id], selectedOption: option };
      return updatedPersonas;
    });
    setIsSpeakerBoostCollapsed(true);
  };

  const handlePlayPause = (voice) => {
    try {
      if (playingVoiceId === voice && audioInstance) {
        audioInstance.pause();
        setIsPlaying(false);
        setPlayingVoiceId(null);
        return;
      }
      if (audioInstance) {
        audioInstance.pause();
        setIsPlaying(false);
      }

      const audio = new Audio(voice);
      audio.play();
      setIsPlaying(true);

      setAudioInstance(audio);
      setPlayingVoiceId(voice);

      audio.onended = () => {
        setIsPlaying(false);
        setPlayingVoiceId(null);
      };
    } catch (error) {
      toast.error(`An error occurred: ${error.message}`);
      console.error("Error in handlePlayPause:", error);
    }
  };

  const maleVoiceData = [
    {
      voice: "/Journey_male.mp3",
      name: "John Smith",
      voiceId: "en-US-Journey-D",
    },
    {
      voice: "/Neural2_male.mp3",
      name: "David Lee",
      voiceId: "en-US-Neural2-D",
    },
    {
      voice: "/News_male.mp3",
      name: "Mark Johnson",
      voiceId: "en-GB-News-J",
    },
    {
      voice: "/Standard_male.mp3",
      name: "Robert Brown",
      voiceId: "en-US-Standard-B",
    },
    {
      voice: "/Studio_male.mp3",
      name: "William Wilson",
      voiceId: "en-US-Studio-Q",
    },
    {
      voice: "/Wavenet_male.mp3",
      name: "Michael Taylor",
      voiceId: "en-US-Wavenet-B",
    },
  ];

  const femaleVoiceData = [
    {
      voice: "/Journey_female.mp3",
      name: "Alice Johnson",
      voiceId: "en-US-Journey-F",
    },
    {
      voice: "/Neural2_female.mp3",
      name: "Emily Davis",
      voiceId: "en-US-Neural2-E",
    },
    {
      voice: "/News_female.mp3",
      name: "Sarah Miller",
      voiceId: "en-GB-News-H",
    },
    {
      voice: "/Standard_female.mp3",
      name: "Jennifer Wilson",
      voiceId: "en-US-Standard-C",
    },
    {
      voice: "/Studio_female.mp3",
      name: "Olivia Moore",
      voiceId: "en-US-Studio-O",
    },
    {
      voice: "/Wavenet_female.mp3",
      name: "Sophia Taylor",
      voiceId: "en-US-Wavenet-C",
    },
  ];

  const handleVoicePreview = (gender) => {
    setSelectedGender(gender);
    if (gender === "male") {
      setVoicePreviewData(maleVoiceData);
    } else {
      setVoicePreviewData(femaleVoiceData);
    }
  };

  const handlePersonaSelect = (persona) => {
    // console.log("persona", persona);
    setExistingPersona(true);
    setIsPersonaDropdownOpen(false);
    if (persona) {
      setPersonas((prev) => {
        const updatedPersonas = [...prev];
        updatedPersonas[id] = {
          name: persona.name,
          description: persona.description,
          selectOption: persona.selectOption,
          image:
            persona.image || "../assets/dashboardimages/images/userImg1.png",
        };
        return updatedPersonas;
      });
      setErrors("");
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result;
        setPersonas((prev) => {
          const updatedPersonas = [...prev];
          updatedPersonas[id] = { ...updatedPersonas[id], image: base64Image };
          return updatedPersonas;
        });
        localStorage.setItem(`userImage_${id}`, base64Image);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = () => {
    document.getElementById(`imageUploadInput_${id}`).click();
  };

  const onChangeStability = (value) => {
    if (isNaN(value)) {
      return;
    }
    setPersonas((prev) => {
      const updatedPersonas = [...prev];
      updatedPersonas[id] = { ...updatedPersonas[id], stability: value };
      return updatedPersonas;
    });
  };

  const onChangeSim = (value) => {
    if (isNaN(value)) {
      return;
    }
    setPersonas((prev) => {
      const updatedPersonas = [...prev];
      updatedPersonas[id] = { ...updatedPersonas[id], sim: value };
      return updatedPersonas;
    });
  };

  const onChangeStyle = (value) => {
    if (isNaN(value)) {
      return;
    }
    setPersonas((prev) => {
      const updatedPersonas = [...prev];
      updatedPersonas[id] = { ...updatedPersonas[id], style: value };
      return updatedPersonas;
    });
  };
  const handleInputChange = (e) => {
    setExistingPersona(false);
    const { name, value } = e.target;

    setPersonas((prev) => {
      const updatedPersonas = [...prev];
      updatedPersonas[id] = { ...updatedPersonas[id], [name]: value };
      return updatedPersonas;
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() ? "" : prevErrors[name],
    }));
  };

  const handleSave = () => {
    let newErrors = { name: "", description: "" };
    const nameRegex = /^[a-zA-Z\s]+$/;
    if (!personas[id].name) {
      newErrors.name = "Name is required.";
    } else if (!nameRegex.test(personas[id].name)) {
      newErrors.name = "Name cannot contain special characters or numbers.";
    }
    if (!personas[id].description) {
      newErrors.description = "Description is required.";
    } else if (personas[id].description.length < 10) {
      newErrors.description =
        "Description must be at least 10 characters long.";
    }
    if (newErrors.name || newErrors.description) {
      setErrors(newErrors);
      return;
    }
    const payload = {
      userid: userId,
      usertype : usertype,
      persona_name: personas[id].name,
      topic_detail: topic,
      persona_description:
        personas[id].description || "Detailed description of the persona",
      image:
        personas[id].image || "../assets/dashboardimages/images/userImg1.png",
      voice_settings: {
        voice_name: personas[id].selectedOption,
        speaking_rate: personas[id].stability,
        pitch: personas[id].sim,
      },
    };
    setLoading(true);
    setIsChildLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/persona/generate_persona`,
        payload
      )
      .then((response) => {
        toast.success("Persona saved successfully");
      })
      .catch((error) => {
        toast.error(error?.response?.data || "Error Saving Persona");
      })
      .finally(() => {
        setLoading(false);
        setIsChildLoading(false);
      });
  };

  const handleUpdate = () => {
    let newErrors = { name: "", description: "" };
    const nameRegex = /^[a-zA-Z\s]+$/;

    if (!personas[id].name) {
      newErrors.name = "Name is required.";
    } else if (!nameRegex.test(personas[id].name)) {
      newErrors.name = "Name cannot contain special characters or numbers.";
    }

    if (!personas[id].description) {
      newErrors.description = "Description is required.";
    } else if (personas[id].description.length < 10) {
      newErrors.description =
        "Description must be at least 10 characters long.";
    }

    if (newErrors.name || newErrors.description) {
      setErrors(newErrors);
      return;
    }
    setLoading(true);
    setIsChildLoading(true);
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/persona/update_persona/${userId}/${previousPersonaName}`,
        {
          new_persona_name: personas[id].name,
          new_image:
            personas[id].image ||
            "../assets/dashboardimages/images/userImg1.png",
          new_description: personas[id].description,
          new_voice_setting: {
            voice_name: personas[id].selectedOption,
            speaking_rate: personas[id].stability,
            pitch: personas[id].sim,
          },
        }
      )
      .then((response) => {
        toast.success("Persona updated successfully");
        // console.log(response.data);
      })
      .catch((error) => {
        // console.log(error.response.data.detail);
        toast.error(error.response.data.detail || "Issue Updating Persona");
      })
      .finally(() => {
        setLoading(false);
        setIsChildLoading(false);
      });
  };

  const handleDeletePersona = (id, name) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/persona/delete_persona`, {
        userid: userId,
        persona_name: name,
      })
      .then((response) => {
        toast.success(`Persona ${name} deleted successfully`);
      })
      .catch((error) => {
        toast.error("Issue deleting persona");
        // console.log(error);
      });
    setFetchedPersonas((prev) => prev.filter((_, index) => index !== id));
  };

  const handleVoiceCheckToggle = (personaName) => {
    setVoiceChecks((prevChecks) => ({
      ...prevChecks,
      [personaName]: !prevChecks[personaName],
    }));
  };

  const handleMouseEnter = () => {
    setShowModal(true);
  };

  const handleMouseLeave = () => {
    setShowModal(false);
  };

  // useEffect(() => {
  //   console.log(showModal);
  // }, [showModal]);
  return (
    <>
      <div className="edit-persona-card d-flex flex-column gap-20">
        <div className="upload-img-div v-center">
          <img
            src={
              personas[id].image
                ? personas[id].image
                : "../assets/dashboardimages/images/userImg1.png"
            }
            alt="userImg"
          />
          <button className="dash-pink-btn" onClick={triggerFileInput}>
            Upload Image
          </button>
          <input
            type="file"
            id={`imageUploadInput_${id}`}
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImageUpload}
          />
        </div>
        <div className="d-flex flex-column gap-1">
          <input
            type="text"
            name="name"
            placeholder="Enter Name of the Persona"
            className="modal-input"
            value={personas[id].name || personas[id].persona_name }
            onChange={handleInputChange}
          />
          {errors.name && <p className="text-small text-red ">{errors.name}</p>}
        </div>

        <div className="desc-box d-flex flex-column gap-20">
          <p className="text-small fw-medium text-lightgray">Description Box</p>
          <div className="d-flex flex-column gap-1">
            <textarea
              placeholder="35 years old Teacher, married with 2 kids, education: MBA, enjoys traveling and hiking"
              className="modal-input"
              name="description"
              value={personas[id].description}
              onChange={handleInputChange}
            ></textarea>
            {errors.description && (
              <p className="text-small text-red ">{errors.description}</p>
            )}
          </div>
        </div>
        <div className="adv-setting-div d-flex flex-column gap-20 ">
          <div id="accordionPersona" className="myaccordion">
            <div className="card">
              <div className="card-header" id="headingPersona">
                <h2 className="mb-0 w-100">
                  <button
                    className="d-flex align-items-center justify-content-between btn btn-link collapsed"
                    onClick={() =>
                      setIsPersonaDropdownOpen(!isPersonaDropdownOpen)
                    }
                    aria-expanded={isPersonaDropdownOpen}
                    aria-controls="collapsePersona"
                  >
                    {selectedfetchpersona || "Choose Existing Persona"}
                    <Icon
                      icon={
                        isPersonaDropdownOpen
                          ? "iconamoon:arrow-down-2-thin"
                          : "iconamoon:arrow-up-2-thin"
                      }
                      width={24}
                      height={24}
                      color="#959595"
                      className={`adv-icon `}
                    />
                  </button>
                </h2>
              </div>
              <div
                id="collapsePersona"
                className={`collapse ${isPersonaDropdownOpen ? "show" : ""}`}
                aria-labelledby="headingPersona"
                data-parent="#accordionPersona"
              >
                <div className="card-body d-flex flex-column gap-2">
                  {fetchedPersonas.map((persona) => {
                    // console.log(persona);
                    return (
                      <div
                        key={persona.name}
                        className="custom-drop-inner d-flex space-between"
                      >
                        <div
                          className="w-100"
                          onClick={() => {
                            handlePersonaSelect(persona);
                            setSelectedfetchpersona(persona.name);
                          }}
                        >
                          <p className="fw-medium text-lightgray cursor-pointer">
                            {persona.name}
                          </p>
                        </div>
                        <Icon
                          onClick={() =>
                            handleDeletePersona(
                              fetchedPersonas.indexOf(persona),
                              persona.name
                            )
                          }
                          icon={"fluent:delete-28-regular"}
                          width={22}
                          height={22}
                          color="red"
                          className="cursor-pointer"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

      { showVoiceSettings && <div className="adv-setting-div d-flex flex-column gap-20 ">
          <div id="accordionPersona" className="myaccordion">
            <div className="card">
              {showModal && !personas[id].persona_name && !personas[id].name && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "50%",
                    transform: "translateX(-50%)",
                    background: "#121212",
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                    boxShadow: "0 3px 9px rgba(0, 0, 0, 0.5)",
                    zIndex: 1050,
                    width: "275px",
                    padding: "10px",
                    marginTop: "15px",
                    borderRadius: "8px",
                  }}
                >
                  <p
                    className="text-small fw-medium text-lightgray"
                    style={{ margin: 0 }}
                  >
                    Persona Name Required
                  </p>
                </div>
              )}
              <div
                className="card-header"
                id="headingPersona"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <h2 className="mb-0 w-100">
                  <button
                    className={`d-flex align-items-center justify-content-between btn btn-link collapsed `}
                    onClick={() => setIsVoicePreview(!isVoicePreview)}
                    disabled={!personas[id].name}
                    aria-expanded={isVoicePreview}
                    aria-controls="collapsePersona"
                  >
                    {selectedGender ? selectedGender : "Voice Preview"}
                    <Icon
                      icon={
                        isVoicePreview
                          ? "iconamoon:arrow-down-2-thin"
                          : "iconamoon:arrow-up-2-thin"
                      }
                      width={24}
                      height={24}
                      color="#959595"
                      className={`adv-icon `}
                    />
                  </button>
                </h2>
              </div>
              <div
                id="collapsePersona"
                className={`collapse ${isVoicePreview ? "show" : ""}`}
                aria-labelledby="headingPersona"
                data-parent="#accordionPersona"
              >
                <div className="card-body d-flex flex-column gap-2">
                  {personas[id].name &&
                    option.map((persona) => {
                      // console.log(persona);
                      return (
                        <div
                          key={persona.name}
                          className="custom-drop-inner d-flex space-between"
                        >
                          <div
                            className="w-100"
                            onClick={() => {
                              setSelectedGender(persona.name);
                              setIsVoicePreview(false);
                              handleVoicePreview(persona.name.toLowerCase());
                            }}
                          >
                            <p className="fw-medium text-lightgray cursor-pointer">
                              {persona.name}
                            </p>
                          </div>

                          {/* {voiceChecks[persona.name] ? (
                          <MdPause
                            size={25}
                            color="grey"
                            className="cursor-pointer"
                            onClick={() => handleVoiceCheckToggle(persona.name)}
                          />
                        ) : (
                          <MdPlayArrow
                            size={25}
                            color="grey"
                            className="cursor-pointer"
                            onClick={() => handleVoiceCheckToggle(persona.name)}
                          />
                        )} */}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>}
        {selectedGender && voicePreviewData && voicePreviewData.length > 0 && (
          <div className="adv-setting-div d-flex flex-column gap-20">
            <p className="text-small fw-medium text-lightgray pl-4">
              Select Voice
            </p>

            <Select
              style={{
                width: "100%",
                padding: "8px 12px",
                display: "flex",
                alignItems: "center",
              }}
              defaultValue="Select Voice"
              onChange={(value) => selectOption(value)}
            >
              <Option value="Select Voice" disabled>
                Select Voice
              </Option>
              {voicePreviewData?.map((voice) => (
                <Option
                  key={voice.voiceId}
                  value={voice.voiceId}
                  onClick={() => selectOption(voice.voiceId)}
                >
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePlayPause(voice.voice);
                    }}
                    style={{ cursor: "pointer", marginRight: "8px" }}
                  >
                    {isPlaying && playingVoiceId === voice.voice ? (
                      <MdPause />
                    ) : (
                      <MdPlayArrow />
                    )}
                  </span>
                  {voice.name}
                </Option>
              ))}
            </Select>
            <p className="text-small fw-medium text-lightgray">
              Advanced Settings
            </p>
            <div id="accordionVoice" className="myaccordion">
              <div className="card">
                <div className="card-header" id="headingVoice">
                  <h2 className="mb-0 w-100">
                    <button
                      className="d-flex align-items-center justify-content-between btn btn-link collapsed"
                      onClick={() =>
                        setIsVoiceOptionsCollapsed(!isVoiceOptionsCollapsed)
                      }
                      aria-expanded={!isVoiceOptionsCollapsed}
                      aria-controls="collapseVoice"
                    >
                      Voice Options
                      <Icon
                        icon={
                          isVoiceOptionsCollapsed
                            ? "iconamoon:arrow-up-2-thin"
                            : "iconamoon:arrow-down-2-thin"
                        }
                        width={24}
                        height={24}
                        color="#959595"
                        className={`adv-icon ${
                          !isVoiceOptionsCollapsed ? "rotate" : ""
                        }`}
                      />
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseVoice"
                  className={`collapse ${
                    isVoiceOptionsCollapsed ? "" : "show"
                  }`}
                  aria-labelledby="headingVoice"
                  data-parent="#accordionVoice"
                >
                  <div className="card-body d-flex flex-column gap-2">
                    <div className="stability-div">
                      <p className="fw-medium text-lightgray">speaking rate</p>
                      <Space style={{ width: "100%" }} direction="vertical">
                        <div className="v-center space-between mt-2">
                          <p className="fw-medium text-lightgray">0</p>
                          <Slider
                            min={0.25}
                            max={4.0}
                            onChange={(e) => onChangeStability(e)}
                            value={
                              typeof personas[id].stability === "number"
                                ? personas[id].stability
                                : 0
                            }
                            step={0.01}
                          />
                          <p className="fw-medium text-lightgray">1</p>
                        </div>
                      </Space>
                    </div>
                    <div className="stability-div">
                      <p className="fw-medium text-lightgray">pitch</p>
                      <Space style={{ width: "100%" }} direction="vertical">
                        <div className="v-center space-between mt-2">
                          <p className="fw-medium text-lightgray">0</p>
                          <Slider
                            min={-20}
                            max={20}
                            onChange={(e) => onChangeSim(e)}
                            value={
                              typeof personas[id].sim === "number"
                                ? personas[id].sim
                                : 0
                            }
                            step={0.01}
                          />
                          <p className="fw-medium text-lightgray">1</p>
                        </div>
                      </Space>
                    </div>
                    {/* <div className="stability-div">
                      <p className="fw-medium text-lightgray">Style</p>
                      <Space style={{ width: "100%" }} direction="vertical">
                        <div className="v-center space-between mt-2">
                          <p className="fw-medium text-lightgray">0</p>
                          <Slider
                            min={0}
                            max={1}
                            onChange={(e) => onChangeStyle(e)}
                            value={
                              typeof personas[id].style === "number"
                                ? personas[id].style
                                : 0
                            }
                            step={0.01}
                          />
                          <p className="fw-medium text-lightgray">1</p>
                        </div>
                      </Space>
                    </div> */}
                    <div className="adv-setting-div d-flex flex-column gap-20">
                      <div id="accordionSpeaker" className="myaccordion">
                        <div className="card">
                          <div
                            id="collapseSpeaker"
                            className={`collapse ${
                              isSpeakerBoostCollapsed ? "" : "show"
                            }`}
                            aria-labelledby="headingSpeaker"
                            data-parent="#accordionSpeaker"
                          >
                            <div className="card-body d-flex flex-column gap-2">
                              <div className="custom-drop-inner">
                                <p
                                  className="fw-medium text-lightgray cursor-pointer"
                                  onClick={() => selectOption("True")}
                                >
                                  True
                                </p>
                              </div>
                              <div className="custom-drop-inner">
                                <p
                                  className="fw-medium text-lightgray cursor-pointer"
                                  onClick={() => selectOption("False")}
                                >
                                  False
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="buttons d-flex space-between">
          <button
            className="trasnparent-btn"
            onClick={() => setisdelModal(true)}
          >
            Remove
          </button>

          {editBoolean || existingPersona ? (
            <button
              onClick={handleUpdate}
              disabled={loading}
              className="dash-pink-btn"
            >
              {loading ? "updating..." : "update"}
            </button>
          ) : (
            <button
              onClick={handleSave}
              disabled={loading}
              className="dash-pink-btn"
            >
              {loading ? "saving..." : "save"}
            </button>
          )}
        </div>
      </div>
      <>
        <DeletePersonaModal
          isdelModal={isdelModal}
          setisdelModal={setisdelModal}
          removePersonaCard={() => removePersonaCard(id, personas[id].name)}
        />
      </>
    </>
  );
};

export default Editpersonacard;
