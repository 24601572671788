import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal, Progress, Tooltip } from "antd";
import ProgressModal from "./progressModal";
import { Icon } from "@iconify/react";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { toast } from "react-toastify";
import { AppContext } from "../Context/AppContext";
import animationData from "../../src/spinner.json";
import Lottie from "lottie-react";
const UploaddocModal = ({ IsuploadModalOpen, setIsuploadModalOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isprogressModalopen, setisprogressModalopen] = useState(false);
  const [showProgressDiv, setShowProgressDiv] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [files, setFiles] = useState([]);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [data, setData] = useState("");
  const { setChatType, userHasPlan } = useContext(AppContext);
  const fileInputRef = useRef(null);
  const uploadIntervalRefs = useRef({});

  const userId = secureLocalStorage.getItem("userId");
  // useEffect(() => {
  // localStorage.setItem("topic", title);
  // }, [title]);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    if (newFiles.length > 0) {
      const newFileObjects = newFiles.map((file) => ({ file, progress: 0 }));
      setFiles((prevFiles) => [...prevFiles, ...newFileObjects]);
      setShowProgressDiv(true);
      newFileObjects.forEach((fileObject) => {
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [fileObject.file.name]: 0,
        }));
        simulateUploadProgress(fileObject.file.name);
      });

      event.target.value = null;
    }
  };

  const simulateUploadProgress = (fileName) => {
    let progress = 0;
    uploadIntervalRefs.current[fileName] = setInterval(() => {
      progress += 15;
      if (progress >= 100) {
        progress = 100;
        clearInterval(uploadIntervalRefs.current[fileName]);
      }
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        [fileName]: progress,
      }));
    }, 500);
  };

  const handleOk = () => {
    setIsuploadModalOpen(false);
    resetUpload();
  };

  const handleCancel = () => {
    setIsuploadModalOpen(false);
    resetUpload();
  };

  const handleContinueClick = async () => {
    if (
      files.length === 0 ||
      !Object.values(uploadProgress).every((progress) => progress === 100)
    ) {
      toast.error("Please upload and complete file uploads before continuing.");
      return;
    }
    const remainingUploads =
      userHasPlan.no_of_document_allowed - userHasPlan.no_of_document_uploaded;
    if (files.length > remainingUploads) {
      toast.error(`Your ${userHasPlan.no_of_document_allowed} documents limit is over, kindly upgrade for more`);
      return;
    }
    if (title.trim() === "") {
      setTitleError(true);
    } else {
      setTitleError(false);
      if (Object.values(uploadProgress).every((progress) => progress === 100)) {
        try {
          setIsLoading(true);
          const formData = new FormData();
          formData.append("user_id", userId);
          formData.append("topic_title", title);
          files.forEach(({ file }) => {
            formData.append("files", file);
          });

          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/kb/upload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                const percentage = Math.floor((loaded / total) * 100);
                setUploadProgress((prevProgress) => ({
                  ...prevProgress,
                  [files[0].file.name]: percentage,
                }));
              },
            }
          );

          toast.success(response.data.message);
          setData(response.data);
          localStorage.setItem("topic", title);
          setChatType("kb");
          setisprogressModalopen(true);
          setIsuploadModalOpen(false);
          resetUpload();
        } catch (error) {
          // console.log(error);
          setIsLoading(false);
          toast.error(error.response.data || "Error uploading , Try again ! ");
        }
      }
    }
  };

  const isContinueDisabled =
    files.length === 0 ||
    !Object.values(uploadProgress).every((progress) => progress === 100);

  const handleCancelUpload = (fileName) => {
    clearInterval(uploadIntervalRefs.current[fileName]);

    setFiles((prevFiles) =>
      prevFiles.filter((fileObj) => fileObj.file.name !== fileName)
    );

    setUploadProgress((prevProgress) => {
      const { [fileName]: _, ...rest } = prevProgress;
      return rest;
    });

    if (files.length === 1) {
      setShowProgressDiv(false);
    }
  };

  const resetUpload = () => {
    setShowProgressDiv(false);
    setUploadProgress({});
    setFiles([]);
    fileInputRef.current.value = null;
    setTitle("");
    setTitleError(false); // Clear the title error when resetting
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    setTitleError(false); // Clear the title error when user types
  };

  return (
    <>
      <Modal
        open={IsuploadModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="uplaodDoc-modal"
        centered={true}
        width="38%"
        maskClosable={false}
      >
        <div className="modal-content v-center h-center flex-column">
          <div className="v-center flex-column">
            <h3 className="fw-bold">Upload Your Document</h3>
            <p className="text-small mt-2">Upload the Document in PDF Format</p>
          </div>
          <div>
            <input
              type="text"
              placeholder="Enter Discussion Title …"
              className={`modal-input ${titleError ? "error" : ""}`}
              value={title}
              onChange={handleTitleChange} // Update the onChange handler
            ></input>
            {titleError && (
              <p className="error-message mt-1">Please enter a title</p>
            )}
          </div>

          <div>
            <Tooltip title="Upload only Documents relevant to the Discussion.">
              <button
                className="dash-pink-btn v-center h-center"
                onClick={handleButtonClick}
              >
                <img
                  src="../../assets/dashboardimages/icons/pdfIcon.svg"
                  alt="pdficon"
                  className="cursor-pointer"
                />
                Upload Document
              </button>
            </Tooltip>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept=".pdf, .doc, .docx, .txt"
              multiple
              onChange={handleFileChange}
            />
          </div>
          {showProgressDiv && (
            <div className="bottom-progress-div">
              <div className="partition-line"></div>
              {files.map(({ file }) => (
                <div className="progress-main" key={file.name}>
                  <div className="upload-progress d-flex">
                    <img
                      src="../../assets/dashboardimages/icons/pdfIcon.svg"
                      alt="pdficon"
                      className="cursor-pointer"
                    />
                    <div className="right-div">
                      <span className="d-flex space-between text-small fw-normal fileName">
                        {file.name}
                        <span onClick={() => handleCancelUpload(file.name)}>
                          <Icon
                            icon="radix-icons:cross-2"
                            width={16}
                            height={16}
                            className="cursor-pointer"
                          />
                        </span>
                      </span>
                      <Progress percent={uploadProgress[file.name] || 0} />
                    </div>
                  </div>
                  <span className="text-small fw-normal percent">
                    {uploadProgress[file.name] || 0}%
                  </span>
                </div>
              ))}
              <button
                className={`dash-pink-btn v-center h-center ${
                  Object.values(uploadProgress).some(
                    (progress) => progress < 100
                  )
                    ? "btn-disabled"
                    : ""
                }`}
                onClick={handleContinueClick}
                disabled={isContinueDisabled || isLoading}
              >
                {isLoading ? (
                  <Lottie animationData={animationData} style={{ width: 100 }} />
                ) : (
                  "Continue"
                )}
              </button>
            </div>
          )}
        </div>
      </Modal>
      <ProgressModal
        isprogressModalopen={isprogressModalopen}
        setisprogressModalopen={setisprogressModalopen}
        Data={data}
        // title={title}
      />
    </>
  );
};

export default UploaddocModal;
