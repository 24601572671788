import React from "react";
import { useNavigate } from "react-router-dom";
// import cardImg1 from "../../assets/images/home/slaracard1.png";
// import cardImg2 from "../../assets/images/home/slaracard2.png";
// import cardImg3 from "../../assets/images/home/slaracard3.png";
//import cardAnimDiv from "../../assets/images/home/cardAnimDiv.png";
// import cardAnimDiv from "../../assets/images/home/vector.png";

const SlaraCards = ({homecard}) => {
  const navigate=useNavigate();

  
  const slaraContent = homecard ? 
  [
    {
      id: 1,

      title: "Entrepreneurs and Professionals:",
      description:
        "Validate Business Ideas and Strategies Need to weigh different options or test market ideas? Slara helps you rapidly conduct market research by simulating AI-driven focus groups tailored to specific demographics. Explore diverse viewpoints to inform strategic planning and pivot decisions.",
        
    },

    {
      id: 2,

      title: "Personal Use:",
      description:
        "Whether you’re brainstorming a personal project, planning a major life decision, or seeking self-reflection, Slara’s AI personas can provide a sounding board, judgment-free environment. Explore new hobbies, interests or build a meaningful friendship with an AI being that possesses emotional intelligence and  creativity.",
        
    },

    {
      id: 3,

      title: "Content Creators:",
      description:
        "Brainstorm New Ideas on Demand Generate fresh, out-of-the-box ideas for your next blog post, video script, or social media campaign. Use Slara to workshop titles, explore angles, or validate your concepts with AI personas that think like your audience. ",
       
    },

    {
      id: 4,

      title: "Students and Researchers:",
      description:
        " Study and Research with Personalized AI Discussions Turn complex subjects into conversations. From analyzing historical events to exploring scientific theories, Slara helps you break down challenging topics into digestible, engaging discussions. You can even practice for interviews or group presentations by simulating realistic group dynamics.",
        
    },

    {
      id: 5,

      title: "Marketing Firms:",
      description:
        "Understand Audience Behavior Run virtual focus groups with specific personas reflecting your target demographics. Whether launching a product or refining your messaging, Slara’s AI helps you analyze how different audience segments react to your campaign ideas.",
        
    },

    {
      id: 6,

      title: "Mental Wellness Seekers:",
      description:
        " Find Supportive Conversations for Mental Well-Being Feeling overwhelmed? Engage in therapeutic conversations with empathetic AI personas to help work through stress, anxiety, or emotional challenges. Slara’s AI creates a safe, supportive space for personal well-being. ",
        
    }, 
    

    
   
  ] : [
    {
      id: 1,

      title: "Entrepreneurs and Professionals:",
      description:
        "Validate Business Ideas and Strategies Need to weigh different options or test market ideas? Slara helps you rapidly conduct market research by simulating AI-driven focus groups tailored to specific demographics. Explore diverse viewpoints to inform strategic planning and pivot decisions.",
        readmore: "Read More",
    },

    {
      id: 2,

      title: "Personal Use:",
      description:
        "Whether you’re brainstorming a personal project, planning a major life decision, or seeking self-reflection, Slara’s AI personas can provide a sounding board, judgment-free environment. Explore new hobbies, interests or build a meaningful friendship with an AI being that possesses emotional intelligence and  creativity.",
        readmore: "Read More",
    },

    {
      id: 3,

      title: "Content Creators:",
      description:
        "Brainstorm New Ideas on Demand Generate fresh, out-of-the-box ideas for your next blog post, video script, or social media campaign. Use Slara to workshop titles, explore angles, or validate your concepts with AI personas that think like your audience. ",
        readmore: "Read More",
    },

    {
      id: 4,

      title: "Students and Researchers:",
      description:
        " Study and Research with Personalized AI Discussions Turn complex subjects into conversations. From analyzing historical events to exploring scientific theories, Slara helps you break down challenging topics into digestible, engaging discussions. You can even practice for interviews or group presentations by simulating realistic group dynamics.",
        readmore: "Read More",
    },

    {
      id: 5,

      title: "Marketing Firms:",
      description:
        "Understand Audience Behavior Run virtual focus groups with specific personas reflecting your target demographics. Whether launching a product or refining your messaging, Slara’s AI helps you analyze how different audience segments react to your campaign ideas.",
        readmore: "Read More",
    },

    {
      id: 6,

      title: "Mental Wellness Seekers:",
      description:
        " Find Supportive Conversations for Mental Well-Being Feeling overwhelmed? Engage in therapeutic conversations with empathetic AI personas to help work through stress, anxiety, or emotional challenges. Slara’s AI creates a safe, supportive space for personal well-being. ",
        readmore: "Read More",
    },
    {
      id: 7,

      title: "Parents:",
      description:
        " Navigate parenting challenges with support from Slara AI personas, designed to offer practical advice on family activities, homework assistance, and emotional well-being. Whether managing sibling dynamics, dealing with behavior concerns, or finding creative ways to engage your kids, Slara Ai provides thoughtful, judgment-free insights, helping you make informed decisions and cultivate a nurturing, balanced home environment for your family.",
    },
    {
      id: 8,

      title: "Fitness Enthusiasts:",
      description:
        "Reach your fitness goals with expert advice from Slara AI. Explore personalized workout routines, diet plans, and fitness challenges by engaging with AI personas that can guide you as your personal trainer and nutritionist. Whether preparing for a competition or improving overall well-being, Slara Ai provides tailored suggestions and motivational support, helping you stay committed to your fitness journey and achieve measurable progress.",
    },
    {
      id: 9,

      title: "Freelancers:",
      description:
        "Stand out in a competitive market with Slara AI. Use simulated conversations to refine client proposals, practice pitching, and negotiate deals. Slara AI personas mimic the mindset of potential clients, providing actionable feedback to enhance your freelancing skills. Whether you’re aiming to expand your client base or develop stronger communication strategies, Slara Ai equips you to succeed in your freelance career.",
    },
    {
      id: 10,

      title: "Educators:",
      description:
        "Enhance classroom engagement with Slara AI. Create simulated discussions for your students that break down complex concepts into digestible, conversational formats. Whether teaching STEM subjects, history, or literature, Slara helps you personalize learning experiences, making lessons more interactive. You can also simulate parent-teacher conferences or curriculum development meetings to refine your teaching strategies and improve student outcomes.",
    },
    {
      id: 11,

      title: "Healthcare Professionals:",
      description:
        "Enhance patient care with Slara AI’s simulations. Run through diagnostic conversations, patient interactions, and treatment planning scenarios to improve bedside manner, communication skills, and decision-making. Whether you’re refining telemedicine practices or improving in-person consultations, Slara’s AI personas mimic diverse patient profiles, helping you provide more personalized, empathetic, and efficient healthcare.",
    },
    {
      id: 12,

      title: "Fashion Designers:",
      description:
        "Stay ahead of trends and refine your collections with Slara AI. Engage with AI personas representing different consumer tastes, allowing you to simulate feedback on designs, colors, and styles. Slara helps you test how new fashion concepts will be received by your target market, giving you insights into emerging trends and customer preferences to stay competitive in the fashion industry.",
    },
    
    
  ];
  return (
    <>
      {slaraContent.map((item) => (
        //   <div className="slaraCards d-flex">
        //   <div className="cardBack">
        //   <img className="cardAnimDiv" src={cardAnimDiv} alt="" />
        //   </div>

        //   <div className="leftArea">
        //     <img src={index.cardImg} alt="" />
        //   </div>
        //   <div className="rightArea">
        //     <p className="text-para text-white fw-semibold slaraTitle">{index.title}</p>
        //     <p className="text-small  text-white fw-medium slarades ">
        //    {index.description}
        //     </p>
        //   </div>
        // </div>
        <div  className="slaraCards">
          <div className="cardBack"></div>
          <h2>{item.title}</h2>
          <p>{item.description}</p>
          {item.readmore && (
            <div className="btnDiv d-flex gap-3">
            <button onClick={()=>navigate(`/blog/${item.id}`)} className="joinList">
            {item.readmore}
            </button>
            {/* <button className="watchDemo">
             Read More
            </button> */}
          </div>
          )}
          
        </div>
      ))}
    </>
  );
};

export default SlaraCards;
