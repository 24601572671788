import React, { useState, useRef } from "react";
import Contact from "../components/home/contact";
import WaitList from "../components/home/waitList";
import Navbar from "../shared/navbar";
import Footer from "../shared/footer";
import { toast,  } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
import useScrollToTop from "../hooks/useScrollToTop";
const ContactUs = (props) => {
  const form = useRef();
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (values.email == "") {
      errors.email = "Email is required";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid Email Format";
    }
    if (values.fname == "") {
      errors.fname = "First name is required";
    }
    if (values.message == "") {
      errors.message = "Message is required";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate(formData);
    setFormErrors(errors);

    if (Object.keys(errors).length !== 0) {
      toast.error("Please fill al fields", { toastId: "lengtherror" });
      return;
    }

    emailjs
      .send("service_921vsiv", "template_ibc1z5m", formData, {
        publicKey: "Hdjdxeb8Z-UY5pBSU",
      })
      .then(
        (result) => {
          toast.success("Form submitted successfully");
          setFormData({
            fname: "",
            lname: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          toast.error("Failed to submit form");
        }
      );
  };

  useScrollToTop();
  return (
    <>
      <Navbar />
      <div className="contactUsPage">
        <div className={`joinWaitlist ${props.contactbg}`}>
          <h2 className="text-white fw-semibold h-center">Contact Us</h2>
          <div className={`mainDiv ${props.mainDivBg}`}>
            <div className="formData">
              <form
                className="text-main text-large"
                onSubmit={handleSubmit}
                action=""
              >
                <div
                  className={`nameDivContainer d-flex w-100 gap-5 justify-content-between ${props.flname}`}
                >
                  <input
                    type="text"
                    className="formInput"
                    placeholder="First Name"
                    name="fname"
                    value={formData.fname}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    className="formInput"
                    placeholder="Last Name"
                    name="lname"
                    value={formData.lname}
                    onChange={handleChange}
                  />
                </div>
                <input
                  type="email"
                  className="formInput"
                  placeholder="Email*"
                  value={formData.email}
                  name="email"
                  onChange={handleChange}
                />
                {/* <input
                type="number"
                className={`formInput ${props.dnone}`}
                placeholder="Phone Number*"
                value={formData.phone}
                onChange={handleChange}
                inputMode="numeric"
                name="phone"
              /> */}

                <textarea
                  className="textArea"
                  placeholder="Your message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
                <button className="text-extraLarge text-white fw-semibold">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
