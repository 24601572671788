import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import UploaddocModal from "../../modals/uploaddocModal";
import ChoosetopicModal from "../../modals/choosetopicModal";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { AppContext } from "../../Context/AppContext";
import { Upload } from "antd";
import { HiOutlineDocument } from "react-icons/hi2";
import secureLocalStorage from "react-secure-storage";

const Dashboardmain = () => {
  const location = useLocation();
  const backPersona = location?.state?.backPersona || false;
  const fromConversations = location.state?.fromConversations || false;
  const customClass = location.state?.customClass || "";
  const [IsuploadModalOpen, setIsuploadModalOpen] = useState(false);
  const [IschooseModalOpen, setIschooseModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { userHasPlan } = useContext(AppContext);
  const userType = secureLocalStorage.getItem("usertype")
 useEffect(() => {
   if(userType === "guest"){
    setIschooseModalOpen(true)
   }
 }, [userType])
 
  const cardscontent = [
    {
      id: 1,
      heading: "How to Engage",
      para: " Users can input a topic, upload a book, or select a book for discussion",
    },
    {
      id: 2,
      heading: "Tailored Virtual Discussion Groups",
      para: " The AI platform analyzes the input to form virtual discussion groups tailored to user preferences.",
    },
    {
      id: 3,
      heading: "Diverse Perspectives",
      para: " Each group comprises AI-generated members with diverse viewpoints and expertise.",
    },
  ];

  useEffect(() => {
    if (backPersona === true) {
      setIschooseModalOpen(true);
    }
  }, [backPersona]);

  const showUploadModal = () => {
    if (userHasPlan && userHasPlan.no_of_document_allowed > 0) {
      setIsuploadModalOpen(true);
    } else {
      toast.error("Upgrade your plan to access document upload!");
    }
  };

  const showchooseModal = () => {
    setIschooseModalOpen(true);
  };

  // Upload document button with Ant Design Upload component
  

  return (
    <>
      <div className={`dashboard-main ${customClass}`}>
        {fromConversations && (
          <button
            className="trasnparent-btn p-3 mx-5"
            onClick={() => {
              navigate("/conversations");
            }}
          >
            <Icon
              icon="material-symbols:arrow-back"
              width={25}
              height={25}
              color="#FFFFFF"
              className="cursor-pointer back-icon"
            />{" "}
            Go Back
          </button>
        )}
        {!fromConversations && (
          <div>
            <h3 className="fw-bold">Welcome to Slara</h3>
            <div className="welcome-cards-wrapper">
              {cardscontent.map((card) => (
                <div className="welcome-card d-flex flex-column" key={card.id}>
                  <h6 className="fw-semibold">{card.heading}</h6>
                  <p className="text-small fw-normal">{card.para}</p>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="dashboard-bottom">
          <h3 className="fw-bold text-center">Start a New Discussion</h3>
          {/* <div className="buttons-div h-center v-center">
            <div>
              <button
                style={{ cursor: "not-allowed" }}
                className="dash-pink-btn v-center h-center"
                onClick={showUploadModal}
                // disabled={true}
              >
                <Icon
                  icon="tabler:upload"
                  width={24}
                  height={24}
                  color="#FFFFFF"
                />
                Upload a Document
              </button>
            </div>
            {showModal && (
              <div
                style={{
                  position: "absolute",
                  top: "85%",
                  left: "40%",
                  transform: "translateX(-50%)",
                  background: "#121212",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  boxShadow: "0 3px 9px rgba(0, 0, 0, 0.5)",
                  zIndex: 1050,
                  width: "230px",
                  padding: "10px",
                  marginTop: "15px",
                  borderRadius: "8px",
                }}
              >
                <p
                  className="text-small fw-medium text-lightgray"
                  style={{ margin: 0 }}
                >
                  Coming Soon!
                </p>
              </div>
            )}
            <p className="text-large">OR</p>
            <button className="dash-pink-btn" onClick={showchooseModal}>
              Choose a Topic
            </button>
          </div> */}

          {/* upload and chose topic another style add */}
          <div className=" buttons-div h-center v-center  ">
            {/* <Upload> */}
              <button onClick={showUploadModal} className="d-flex flex-column  align-items-center p-3  dash-pink-btn upload-button">
              <Icon
                  icon="tabler:upload"
                  width={25}
                  height={25}
                  color="#FFFFFF"
                />
                <span className="fw-bold mt-2">Upload & discuss your File </span>
                <span className="text-white" style={{fontSize : "10px"}}>(PDF,Doc,Text)</span>
              </button>
            {/* </Upload> */}
            <p className="text-large">OR</p>

            <button
              className="d-flex flex-column align-items-center p-3  dash-pink-btn upload-button"
              onClick={showchooseModal}
            >
              <HiOutlineDocument
                   size={24}/>
              <span className="fw-bold mt-2">Choose your conversation Topic </span>
            </button>
          </div>

          {/* upload and chose topic another style add end */}
        </div>
      </div>
      <Outlet />
      <UploaddocModal
        IsuploadModalOpen={IsuploadModalOpen}
        setIsuploadModalOpen={setIsuploadModalOpen}
      />
      <ChoosetopicModal
        IschooseModalOpen={IschooseModalOpen}
        setIschooseModalOpen={setIschooseModalOpen}
      />
    </>
  );
};

export default Dashboardmain;
