import React from "react";
import Hero from "../components/home/hero";
import Interact from "../components/home/interact";
import WithSlara from "../components/home/withSlara";
import HowItWork from "../components/home/howItWork";
import Contact from "../components/home/contact";
import Testimonial from "../components/home/testimonial";
import WaitList from "../components/home/waitList";
import Navbar from "../shared/navbar";
import Footer from "../shared/footer";
import MarqueSec from "../components/home/marqueSec";
import Features from "../components/home/features";
import Guide from "../components/home/guide";
import PricingPlan from "../components/home/pricingPlan";

const LandingPage = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <MarqueSec />
      <Interact />
      <WithSlara />
      <Features />
      {/* <HowItWork /> */}
      {/* <Contact /> */}
      <Guide />
      <Testimonial />
      <PricingPlan />
      {/* <WaitList heading="Join Waitlist" dnone="d-none" flname="flname" /> */}
      <Footer />
    </>
  );
};

export default LandingPage;
