import React from 'react'
import Image from "../../assets/images/home/imageblog.png";
import blogdata from './blogdata';
import { useParams } from 'react-router-dom';
const BlogDetail = () => {
    const { id } = useParams(); // Get the id from the URL
  const blog = blogdata.find((post) => post.id === parseInt(id)); // Find the blog post by id

  if (!blog) {
    return <p style={{textAlign:"center", margin:"50px"}}>Blog post not found</p>;
  }
  return (
    <div className="blog-page ">
    <div className="container ">
      <div>
        <div className="image-box">
          <img src={Image} alt="" />
        </div>
        <div className="small-bar"></div>
        <div className="content">
          <h3>{blog.title}</h3>
          
         <div style={{marginTop:"50px"}} className=''>{blog.fullcontent}</div>
         
          
        </div>
      </div>
    </div>
  </div>
  )
}

export default BlogDetail