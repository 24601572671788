import React, { useState } from "react";
import logo from "../assets/images/signIn/logoS.png";
import { Icon } from "@iconify/react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {  toast } from "react-toastify";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const VerifyCode = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [code, setCode] = useState();
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState();

  const validate = (e) => {
    e.preventDefault();
    if (!Boolean(code)) {
      setError("Validation code is required");
      return;
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    if (location?.state?.fromRoute1) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/auth/verify_user_email`, {
          email: location?.state?.formData?.email,
          username : location?.state?.formData?.name,
          code: code,
          guest_id : secureLocalStorage.getItem("guestId"),
          usertype : secureLocalStorage.getItem("usertype"),
          userid : secureLocalStorage.getItem("userId"),
          time : secureLocalStorage.getItem("time")
        })
        .then((res) => {
          // console.log("!!!!!!!" , "verify_user_email ");
          toast.success(res?.data?.message);
          setCode("");
          navigate("/signin");
        })
        .catch((err) => {
          setError("Verification Code is wrong");
          toast.error("Verification Code is wrong");
        }).finally(() => {
          setLoading(false); 
        });
    } else if (location?.state?.fromRoute2) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/auth/verify_recovery_code`, {
          code: code,
        })
        .then((res) => {
          // console.log("!!!!!!!" , "Recovery Code Endpoint");
          toast.success(res?.data?.message);
          setCode("");
          const email = location?.state?.formData?.email;
          // console.log(email);
          navigate("/resetpassword", { state: { email, code } });
        })
        .catch((err) => {
          setError("Verification Code is wrong");
          toast.error("Verification Code is wrong");
        }).finally(() => {
          setLoading(false); 
        });
    } else {
      setError("Invalid navigation state");
      toast.error("Invalid navigation state");
    }
  };
  return (
    <>
      <div className="signInwrapper">
        <div className="nav">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
        </div>
        <div className="centerDiv">
          <NavLink to="/forgetpassword">
            <Icon
              icon="ic:round-keyboard-arrow-left"
              width="40"
              height="40"
              className="iconBack"
              style={{ color: "black" }}
            />
          </NavLink>
          <h5>Verify Code</h5>
          <div className="userData pass-recover-data">
            <form
              onSubmit={(e) => validate(e)}
              className="h-center flex-column"
              action=""
            >
              <div className="emailInput">
                <label for="" className="mb-2">
                  Enter Verification Code
                </label>
                <input
                  type="number"
                  placeholder="Enter The Code"
                  name="code"
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value);
                    setError("");
                  }}
                />
              </div>
              {error && (
                <>
                  <p style={{ color: "Red", marginBottom: "4px" }}>{error}</p>
                </>
              )}
              {/* <NavLink to="/resetpassword"> */}
              <button type="submit" className="signInn" disabled={loading}>
                {loading ? "Verifying..." : "Continue"}
              </button>
              {/* </NavLink> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyCode;
