import React, { useEffect, useContext, useState } from "react";
import "../style/shared/navbar.scss";
import logo from "../../src/assets/images/navLogo.png";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import menuu from "../../src/assets/images/home/menu.webp";
import cross2 from "../../src/assets/images/home/cancel.png";
import { Link as ScrollLink } from "react-scroll";
import { toast } from "react-toastify";
import resourceDown from "../../src/assets/images/home/arrow-down-24.png";
import { AppContext } from "../Context/AppContext";
import signInArrow from "../../src/assets/images/home/signInArrow.png";
import arrowUp from "../../src/assets/images/home/icons8-arrow-up-24.png";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";

const Navbar = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const howItWorksRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const userId = secureLocalStorage.getItem("userId");
  const profileData = secureLocalStorage.getItem("profileData");
  const { setUserHasPlan, userHasPlan, ProfileData, logout } =
    useContext(AppContext);
  const handleGetStarted = async (name, price) => {
    try {
      const payload = profileData?.email
        ? {
            userid: userId || "",
            amount: price,
            plan_name: name,
            email: profileData.email,
            base_url: "https://www.slara.ai",
          }
        : {
            userid: "",
            amount: 0.0,
            plan_name: name,
            email: "abc@gmail.com",
            base_url: "",
          };

      // Send request to activate the free trial
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/create-checkout-session`,
        payload
      );

      // Handle the response based on different cases
      if (response.data.save === "save plan") {
        setUserHasPlan("Free_Trial");
        toast.success("Free trial activated!");
        await ProfileData();
        navigate("/dashboard");
      } else if (response.data["Already subscribed"]) {
        toast.error(
          response.data["Already subscribed"] ||
            "You have already used your free trial!"
        );
      } else if (response.data.Response) {
        const { userid, token, plan, guest_id } = response.data.Response;

        // Save necessary data in secure storage
        secureLocalStorage.setItem("authToken", token);
        secureLocalStorage.setItem("userId", userid);
        secureLocalStorage.setItem("guestId", guest_id);
        secureLocalStorage.setItem("usertype", "guest");

        setUserHasPlan(plan);
        toast.success("Free Trial Activated!");
        await ProfileData();
        navigate("/dashboard");
      } else {
        toast.error("You already have a plan");
      }
    } catch (error) {
      console.error("Error activating free trial:", error);
      toast.error("Something went wrong. Please try again later.");
    }
  };
  const handleNavigation = (link) => {
    const [pathname, hash] = link.split("#");

    if (pathname === "/") {
      // If already on the homepage

      if (hash === "features") {
        const featureSection = document.getElementById("features");
        if (featureSection) {
          featureSection.scrollIntoView({ behavior: "smooth" });
        }
      } else if (hash === "testimonial") {
        const testiSection = document.getElementById("testimonial");
        if (testiSection) {
          testiSection.scrollIntoView({ behavior: "smooth" });
        }
      }
      // else if (hash === "blogs") {
      //   const testiSection = document.getElementById("blogs");
      //   if (testiSection) {
      //     testiSection.scrollIntoView({ behavior: "smooth" });
      //   }

      // }
      else if (hash === "howitwork") {
        const howWorkSection = document.getElementById("howitwork");
        if (howWorkSection) {
          howWorkSection.scrollIntoView({ behavior: "smooth" });
        }
      }
    } else {
      // Navigate to the homepage with hash
      navigate(link);

      // Use the `useEffect` in the homepage to scroll once navigated
    }

    setShow(false);
  };
  useEffect(() => {
    // This will trigger when the hash changes after navigation
    if (location.hash) {
      const targetSection = document.getElementById(location.hash.substring(1)); // Remove the "#" and scroll to the section
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]);
  // const [selectedOption, setSelectedOption] = useState(null);

  // const options = [
  //   { value: "1", label: "Option 1" },
  //   { value: "2", label: "Option 2" },
  //   { value: "3", label: "Option 3" },
  // ];

  // const handleToggle = () => {
  //   setIsOpen(!isOpen);
  // };

  // const handleOptionClick = (option) => {
  //   setSelectedOption(option);
  //   setIsOpen(false);
  // };
  return (
    <div style={{ position: "relative" }}>
      <div className="desktop-nav">
        <div className="itemsWrapper">
          <div className="navLogo">
            <a className="active" href="/">
              <img src={logo} alt="" />
            </a>
          </div>
          <div className="nav-items">
            <ul className="text-medium">
              {location.pathname === "/" ? (
                <>
                  <li>
                    <ScrollLink
                      to="hero"
                      spy={true}
                      smooth={true}
                      duration={500}
                    >
                      HOME
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      to="howitwork"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-150}
                    >
                      HOW SLARA WORKS
                    </ScrollLink>
                  </li>

                  {/* <li className="resources">
                    <ScrollLink
                      to="howitwork"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-150}
                    >
                    Resources
                    </ScrollLink>
                  </li> */}

                  <li className="d-flex align-items-center justify-content-center gap-1 resourceList">
                    RESOURCES
                    <img
                      className="resourceDropDown"
                      src={resourceDown}
                      alt=""
                    />
                    <ul className="drop-downss">
                      {/* <NavLink className="nav-links"> */}
                      <li onClick={() => navigate("/blog")}>
                        {" "}
                        Blog
                        {/* <ScrollLink
                      to="blogs"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={50}
                    >
                      Blog
                    </ScrollLink> */}
                      </li>
                      {/* </NavLink> */}

                      {/* <NavLink
                        className="nav-links"
                        onClick={() => toast("Coming Soon")}
                      > */}
                      <li onClick={() => toast("Coming Soon")}>Guides</li>
                      {/* </NavLink> */}

                      {/* <NavLink
                        className="nav-links"
                       
                      > */}
                      <li onClick={() => toast("Coming Soon")}>Use Cases</li>
                      {/* </NavLink> */}

                      {/* <NavLink
                        className="nav-links"
                      
                      > */}
                      <li onClick={() => toast("Coming Soon")}>Videos</li>
                      {/* </NavLink> */}

                      {/* <NavLink
                        className="nav-links"
                       
                      > */}
                      <li onClick={() => toast("Coming Soon")}>
                        Free Resources
                      </li>
                      {/* </NavLink> */}
                    </ul>
                  </li>

                  {/* <div className="dropdown">
        <div className="dropdown-header" onClick={handleToggle}>
          {selectedOption ? selectedOption.label : 'Select an option'}
          <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}>▼</span>
        </div>
        {isOpen && (
          <div className="dropdown-list">
            {options.map((option) => (
              <div
                key={option.value}
                className="dropdown-item"
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div> */}

                  {/* <ul className="sidebar-menu">
        <li className="sidebar-item" onClick={toggleDropdown}>
          RESOURCES
          <span className="dropdown-icon">&#9660;</span>
          {isDropdownOpen && (
            <ul className="dropdown-menu">
              <li onClick={() => toast("Coming Soon")}>Blog</li>
              <li onClick={() => toast("Coming Soon")}>Guides</li>
              <li onClick={() => toast("Coming Soon")}>Use Cases</li>
              <li onClick={() => toast("Coming Soon")}>Videos</li>
              <li onClick={() => toast("Coming Soon")}>Free Resources</li>
            </ul>
          )}
        </li>
       
      </ul> */}

                  <li>
                    <ScrollLink
                      to="features"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-150}
                    >
                      FEATURES
                    </ScrollLink>
                  </li>

                  <li>
                    <ScrollLink
                      to="testimonial"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-100}
                    >
                      TESTIMONIALS
                    </ScrollLink>
                  </li>
                </>
              ) : (
                <div className="d-flex justisy-content-center gap-4 align-items-center">
                  <li>
                    <NavLink to="/">HOME</NavLink>
                  </li>
                  <li>
                    <Link to="/#howitwork">HOW SLARA WORKS</Link>
                  </li>

                  <li className="d-flex align-items-center justify-content-center gap-1 resourceList">
                    RESOURCES
                    <img
                      className="resourceDropDown"
                      src={resourceDown}
                      alt=""
                    />
                    <ul className="drop-downss">
                      {/* <NavLink className="nav-links"> */}
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      {/* </NavLink> */}

                      {/* <NavLink
                        className="nav-links"
                        onClick={() => toast("Coming Soon")}
                      > */}
                      <li onClick={() => toast("Coming Soon")}>Guides</li>
                      {/* </NavLink> */}

                      {/* <NavLink
                        className="nav-links"
                       
                      > */}
                      <li onClick={() => toast("Coming Soon")}>Use Cases</li>
                      {/* </NavLink> */}

                      {/* <NavLink
                        className="nav-links"
                      
                      > */}
                      <li onClick={() => toast("Coming Soon")}>Videos</li>
                      {/* </NavLink> */}

                      {/* <NavLink
                        className="nav-links"
                       
                      > */}
                      <li onClick={() => toast("Coming Soon")}>
                        Free Resources
                      </li>
                      {/* </NavLink> */}
                    </ul>
                  </li>

                  <li>
                    <NavLink to="/contact">CONTACT</NavLink>
                  </li>

                  <li>
                    <Link
                      onClick={() => handleNavigation("/#features")}
                      to="/#features"
                    >
                      FEATURE
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => handleNavigation("/#testimonial")}
                      to="/#testimonial"
                    >
                      TESTIMONIALS
                    </Link>
                  </li>
                </div>
              )}
            </ul>
          </div>
          {/* <NavLink className="navvSignIn " to="/signin"> */}
          <NavLink className="navvSignIn ">
            <div className="nav-btns d-flex gap-3">
              <button
                onClick={() =>
                  userHasPlan ? navigate("/dashboard") : navigate("/signin")
                }
                className=" navSignIn text-medium fw-medium text-white d-flex "
              >
                {userHasPlan ? "Dasboard" : "SIGN IN"}
                <img className="btnIcon" src={signInArrow} alt="" />
              </button>
              <button className=" waitBtn text-medium fw-medium text-white">
                {userHasPlan?.plan_name === "Free_Trial" ? (
                  <ScrollLink
                    to="pricing"
                    spy={true}
                    smooth={true}
                    duration={400}
                    offset={-150}
                  >
                    Upgrade Plan!
                  </ScrollLink>
                ) : userHasPlan?.plan_name ? (
                  <ScrollLink
                    to="pricing"
                    spy={true}
                    smooth={true}
                    duration={400}
                    offset={-150}
                  >
                    Manage Plan{" "}
                  </ScrollLink>
                ) : (
                  <span onClick={() => handleGetStarted("Free_Trial", "00.00")}>
                    Start Free Trial
                  </span>
                )}
              </button>
              {userId && (
                <button
                  onClick={logout}
                  className=" waitBtn text-medium fw-medium text-white"
                >
                  Log out
                </button>
              )}
            </div>
          </NavLink>
        </div>
      </div>

      <div className="mobile-navbar">
        <NavLink to="/">
          <img className="mobileLogo" src={logo} alt="" />
        </NavLink>
        <Button onClick={handleShow} className="menu-btn">
          <img src={menuu} alt="" />
        </Button>
      </div>
      <div
        className=""
        style={{ position: "absolute", top: "0px", left: "0px" }}
      >
        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <NavLink to="/">
                <img src={logo} alt="..." className="logostyle" />
              </NavLink>
            </Offcanvas.Title>
            <img onClick={handleClose} src={cross2} alt="" />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="mobileScreenUl">
              <li>
                <ScrollLink
                  to="hero"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={handleClose}
                >
                  HOME
                </ScrollLink>
              </li>

              <li>
                <ScrollLink
                  to="howitwork"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-100}
                  onClick={handleClose}
                >
                  HOW SLARA WORKS
                </ScrollLink>
              </li>

              <li
                className="pb-0 mb-0 d-flex align-items-center justify-content-start gap-3  w-50"
                onClick={() => setIsOpen(!isOpen)}
              >
                RESOURCES
                {isOpen ? (
                  <img style={{}} className="arrow-upp" src={arrowUp} alt="" />
                ) : (
                  <img
                    style={{}}
                    className="arrow-upp"
                    // className="arrow-down-icon"
                    src={resourceDown}
                    alt=""
                  />
                )}
              </li>
              {isOpen && (
                <ul className="pt-0 ms-2 ps-2 w-50  ">
                  <div className="d-flex w-100 justify-content-start align-items-center  ">
                    {/* <img  style={{width:"20px", height:"20px"}} className="resourceContentArrow" src={resourceDown} alt="" /> */}
                    <li className="pb-0 mb-0 ps-3">
                      <ScrollLink
                        to="blogs"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-100}
                        onClick={handleClose}
                      >
                        Blog
                      </ScrollLink>
                    </li>
                  </div>
                  <div className="d-flex w-100 justify-content-start align-items-center  ">
                    {/* <img  style={{width:"20px", height:"20px"}} className="resourceContentArrow" src={resourceDown} alt="" /> */}
                    <li
                      onClick={() => toast("Coming Soon")}
                      className="pb-0 mb-0 ps-3"
                    >
                      Guides
                    </li>
                  </div>
                  <div className="d-flex w-100 justify-content-start align-items-center  ">
                    {/* <img  style={{width:"20px", height:"20px"}} className="resourceContentArrow" src={resourceDown} alt="" /> */}
                    <li
                      onClick={() => toast("Coming Soon")}
                      className="pb-0 mb-0 ps-3"
                    >
                      Use Cases
                    </li>
                  </div>
                  <div className="d-flex w-100 justify-content-start align-items-center  ">
                    {/* <img  style={{width:"20px", height:"20px"}} className="resourceContentArrow" src={resourceDown} alt="" /> */}
                    <li
                      onClick={() => toast("Coming Soon")}
                      className="pb-0 mb-0 ps-3"
                    >
                      Videos
                    </li>
                  </div>
                  <div className="d-flex w-100 justify-content-start align-items-center  ">
                    {/* <img  style={{width:"20px", height:"20px"}} className="resourceContentArrow" src={resourceDown} alt="" /> */}
                    <li
                      onClick={() => toast("Coming Soon")}
                      className="pb-0 mb-0 ps-3"
                    >
                      Free Resources
                    </li>
                  </div>
                </ul>
              )}

              <li>
                <ScrollLink
                  to="features"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-50}
                  onClick={handleClose}
                >
                  FEATURES
                </ScrollLink>
              </li>
              <li className="mb-2">
                <ScrollLink
                  to="testimonial"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-50}
                  onClick={handleClose}
                >
                  TESTIMONIALS
                </ScrollLink>
              </li>
            </ul>
            <NavLink>
              <button
                onClick={() =>
                  userHasPlan ? navigate("/dashboard") : navigate("/signin")
                }
                className="loginBtn"
              >
                {userHasPlan ? "Dasboard" : "SIGN IN"}
              </button>
            </NavLink>{" "}
            <br />
            <button className="loginBtn">
              {" "}
              <ScrollLink to="waitlist" spy={true} smooth={true} duration={400}>
                {userHasPlan?.plan_name === "Free_Trial" ? (
                  <ScrollLink
                    to="pricing"
                    spy={true}
                    smooth={true}
                    duration={400}
                    offset={-150}
                  >
                    Upgrade Plan!
                  </ScrollLink>
                ) : userHasPlan?.plan_name ? (
                  <ScrollLink
                    to="pricing"
                    spy={true}
                    smooth={true}
                    duration={400}
                    offset={-150}
                  >
                    Manage Plan{" "}
                  </ScrollLink>
                ) : (
                  <span onClick={() => handleGetStarted("Free_Trial", "00.00")}>
                    Start Free Trial
                  </span>
                )}
              </ScrollLink>
            </button>
            {userHasPlan && (
              <button onClick={logout} className="loginBtn">
                Logout{" "}
              </button>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
};

export default Navbar;
