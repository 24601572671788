import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ThankYou = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Parse query parameters
  const queryParams = new URLSearchParams(location.search);
  const planName = queryParams.get("planName");
  const transactionValue = queryParams.get("transactionValue");

  useEffect(() => {
    if (!planName || !transactionValue) {
      navigate("/");
    }
  }, [planName, transactionValue, navigate]);

  return (
    <div
      className="container d-flex justify-content-center align-items-center h-100"
      style={{ minHeight: "100vh" }} 
    >

      <div
        className="card shadow-lg p-4 mb-4"
        style={{ backgroundColor: "#1c1c1e", color: "#f1f1f1" }}
      >
        <header>
          <h2 className="mb-4" style={{ color: "#8a2be2" }}>
            Thank You for Your Purchase!
          </h2>
        </header>

        <div className="thank-you-message text-center">
          <p>
            We appreciate your business. You have successfully purchased the{" "}
            <strong style={{ color: "#f1f1f1" }}>{planName}</strong> plan for{" "}
            <strong style={{ color: "#f1f1f1" }}>${transactionValue}</strong>.
          </p>
          <p>We hope you enjoy our services!</p>
        </div>

        <div className="mt-4  d-flex justify-content-center">
          <button
            className="btn"
            style={{
              background:
                "linear-gradient(133deg, #9208f3 2.91%, #9b28a8 100%)",
              color: "#f1f1f1",
              border: "none",
              padding: "10px 20px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/dashboard")}
          >
            Go to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
