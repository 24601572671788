import React, { useContext, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import DeleteModal from "../../modals/deleteModal";
import CustomvalueModal from "../../modals/customvalueModal";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";

const ModelHistorycard = ({ topic, index , getAllTopics }) => {
  const navigate = useNavigate();
  const [isdelModal, setisdelModal] = useState(false);
  const [iscustomModalOpen, setiscustomModalOpen] = useState(false);
  const [topicName, setTopicName] = useState();
  const [isLoading, setIsLoading] = useState(false)
  const userId = secureLocalStorage.getItem("userId");
  const {setChatType , setPersonas} = useContext(AppContext)
  // console.log(topicName);

  const handleDeleteTopic = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/topic/delete_specific_topic`, {
        userid: userId,
        topic_name: topicName,
      })
      .then(() => {
        toast.success("Topic deleted successfully");
        getAllTopics();
      })
      .catch((err) => {
        // console.log(err);
        toast.error("Failed to delete topic");
      });
  };

  const handleSpecificTopic = (topicName) => {
    setIsLoading(true)
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/topic/get_specific_topic`, {
        userid: userId,
        topic_name: topicName,
      })
      .then((res) => {
        const response = res.data.Response;
        // console.log(response);
        
        const topicDetails = response.find((item) => item.topic)?.topic || {};
        setChatType(topicDetails.chat_type)
        localStorage.setItem("topic", topicDetails.topic_details);
        const personaData =
          response.find((item) => item.personas)?.personas || [];

        if (personaData.length > 0) {
          setPersonas(personaData)
          navigate("/dashboard/persona-created", {
            state: { continuedPersonas: personaData },
          });
        } else {
           setIsLoading(false)
           setiscustomModalOpen(true);
        }
      })
      .catch((err) => {
        setIsLoading(false)
        toast.error("Issue starting discussion , Try again!");
      })
  };

  return (
    <>
      <div className="model-history-card d-flex flex-column space-between">
        <div className="card-top v-center space-between">
          <span className="text-large fw-bold d-flex gap-2 flex-column">
            Discussion Title:{" "}
            <span className="fw-normal text-lightgray">
              {topic.topic_details}
            </span>
          </span>
          <Icon
            icon="mdi:delete"
            width={25}
            height={25}
            color="#FF2727"
            className="cursor-pointer del-icon delIcon"
            onClick={() => {
              setTopicName(topic.topic_details);
              setisdelModal(true);
            }}
          />
        </div>
        <button
          className="dash-pink-btn"
          disabled={isLoading}
          onClick={() => {
            handleSpecificTopic(topic.topic_details);
          }}
        >
          {isLoading ?  "wait a while!" : "Start Discussion" }
        </button>
      </div>
      <DeleteModal
        isdelModal={isdelModal}
        setisdelModal={setisdelModal}
        handleDeleteTopic={handleDeleteTopic}
      ></DeleteModal>
      <CustomvalueModal
        iscustomModalOpen={iscustomModalOpen}
        setiscustomModalOpen={setiscustomModalOpen}
      />
    </>
  );
};

export default ModelHistorycard;
