import React, { useContext, useEffect, useState } from "react";
import Personacard from "../../../components/cards/personacard";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { AppContext } from "../../../Context/AppContext";
import { toast } from "react-toastify";

const PersonaCreated = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { continuedPersonas } = location.state || [];
  const topic = localStorage.getItem("topic");
  const userId = secureLocalStorage.getItem("userId");
  const { personas, check, setCheck, chatType } = useContext(AppContext);
  const profileData = secureLocalStorage.getItem("profileData")
  const [isLoading , setIsLoading] = useState(false)
  // console.log(profileData);
  useEffect(() => {
    setCheck(false);
  }, []);

  const handleDisccussion = () => {
    setIsLoading(true)
    // console.log(personas);
    const personaData = continuedPersonas.map((persona) => persona.persona_name);
    // console.log(personaData);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/conversation/start_discussion`, {
        username: profileData.username ,
        userid: userId,
        personas: personaData,
        topic_details: topic,
        chat_type:chatType
      })
      .then((res) => {
        // console.log(res.data);
        toast.success("Please wait while we start your discussion...");
        navigate(`/dashboard/discussion/${res.data.session_id}`, {
          state: { continuedPersonas },
        });
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err || "Error starting discussion , Please try again!");
      }).finally(() => {
      setIsLoading(false);
      });
  };

  return (
    <>
      <div className="h-center">
        <div className="persona-created-main d-flex flex-column space-between">
          <div className="persona-created-top  d-flex flex-column gap-40">
            <h5 className="fw-medium">
              Persona’s has been Created Successfully
            </h5>
            <div className="persona-card-wrapper">
              <Personacard
                name="Chat Lead"
                img="../../assets/dashboardimages/images/userImg2.png"
                isActive={false}
               
              />
              {continuedPersonas?.map((persona, index) => (
                <Personacard
                  key={index}
                  name={persona.persona_name}
                  description={persona.description}
                  img={
                    persona.image ||
                    "../../assets/dashboardimages/images/defaultImg.png"
                  }
                  isActive={false}
                  iconcheck={check}
                />
              ))}
            </div>
            <button
              className="dash-pink-btn"
              disabled={isLoading}
              onClick={() => {
                handleDisccussion();
              }}
            >
             {isLoading ? "Wait a while..." : "Start Discussion" } 
            </button>
          </div>
          <div className="persona-created-bottom d-flex">
            <button
              className="trasnparent-btn"
              onClick={() => {
                navigate("/dashboard/edit-persona");
              }}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonaCreated;
