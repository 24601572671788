import React from "react";

const GuideCard = (data) => {
  return (
    <>
      <div className="guideCard">
        <div className="mainContainer">
          <h2 className="personaTitle">{data.mainTitle}</h2>
          <p>{data.details}</p>
          <button>GUIDE</button>
        </div>
      </div>
    </>
  );
};

export default GuideCard;
